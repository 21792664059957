import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { appSelectors, appOperations } from "../../../../duck";
import WriteDream from '../../../../Onboarding/Screens/Dream/WriteDream';

function EditDream({history, authUser, activeProfileEditFields, updateDream}){
    return (
        <WriteDream
            authUser={authUser}
            saving={activeProfileEditFields.includes('Dream')}
            submit={(dream) => updateDream(dream, history)} 
        />
    );
}

const mapStateToProps = state => ({
    authUser: appSelectors.getAuthUser(state),
    activeProfileEditFields: state.activeProfileEditFields,
});

const mapDispatchToProps = {
    updateDream: appOperations.updateUserDream
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditDream));