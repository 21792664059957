import { connect } from 'react-redux';
import Login from './Login';
import { appOperations } from '../duck';

const mapDispatchToProps = {
    signin: appOperations.signin,
};

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    authenticating: state.pageLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);