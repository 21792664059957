import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import { checkUsername } from '../../../../../../API';
import { AuthenticatedUserToolBar } from '../../../../../components/ToolBar';
import CustomButton from '../../../../../components/Button';
import CustomInput from '../../../../../components/Input';
import RaisedSurface from '../../../../../components/RaisedSurface';
import InlineMessage from '../../../../../components/InlineMessage';

import "./styles.css";

function EditBasicDetails({authUser, history, activeProfileEditFields, updateDetails}){
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [userNameValidationState, setUserNameValidationState] = useState("idle");
    const [userNameValidationError, setUserNameValidationError] = useState(null);
    const allFieldsUnchanged = firstName === authUser.firstName && lastName === authUser.lastName && userName === authUser.userName;
    let userNameValidityStatus = userNameValidationState !== "error" && userNameValidationError === null; 
    if(userName === authUser.userName || userNameValidationState === "validating")
        userNameValidityStatus = null;

    const handleSubmit = () => {
        updateDetails({ firstName, lastName, userName, editField: 'Basic details' }, history);
    };

    useEffect(() => {
        if(authUser){
            setFirstName(authUser.firstName);
            setLastName(authUser.lastName);
            setUserName(authUser.userName);
        }
    }, [authUser])
    
    useEffect(() => {
        if(userName && authUser && userName !== authUser.userName)
            handleUserNameChanged();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName]);

    const checkUsernameExistence = useRef(throttle(async (userName) => {
        setUserNameValidationState("validating");
        try {
            const res = await checkUsername(userName);
            setUserNameValidationState("idle");
            if (res.statusCode === 6504)
                setUserNameValidationError("Username exists");
        } catch (error) {
            setUserNameValidationState("error");
        }
    }, 500)).current;
    
    const handleUserNameChanged = async () => {
        setUserNameValidationState("idle");
        setUserNameValidationError(null);

        if(userName === authUser.userName){
            setUserNameValidationError(null);
            return;
        }
        else if(!userName.length){
            setUserNameValidationError("Username can't be empty");
            return;
        }
        else if (/\s/.test(userName)) {
            setUserNameValidationError("Username can't have spaces");
            return;
        }
        
        checkUsernameExistence(userName);
    }

    return (
        <div id="EditBasicDetails" className="position-fixed pin-all z10">
            <AuthenticatedUserToolBar
                theme="#e65f58"
                title="Edit Basic Details"
                isAuthenticated={true}
                onBack={() => history.goBack()} 
                noActions
            />

            <div id="EditBasicDetailsContent">
                <div className="contents">
                    <div className="interests-form">
                        <CustomInput
                            label="First Name"
                            value={firstName}
                            onCustomInputChange={setFirstName}
                        />

                        <CustomInput
                            label="Last Name"
                            value={lastName}
                            onCustomInputChange={setLastName}
                        />

                        <CustomInput
                            label="Username"
                            value={userName}
                            success={userNameValidityStatus}
                            validationMessage={userNameValidationState !== "validating" && userNameValidationError !== null ? userNameValidationError : ""}
                            onCustomInputChange={setUserName}
                        />
                        
                        { userNameValidationState !== "idle" && (
                            <InlineMessage
                                message={userNameValidationState === "validating" ? "Validating username..." : "Failed to validate username"}
                                action={ userNameValidationState === "error" ? "Retry" : null}
                                onActionClick={ handleUserNameChanged }
                            />
                        )}

                        <RaisedSurface>
                            <CustomButton
                                width="100%"
                                color="#e0493f"
                                loading={activeProfileEditFields.includes('Basic details')}
                                disabled={allFieldsUnchanged || userNameValidationError !== null || userNameValidationState !== "idle"}
                                handleOnClick={handleSubmit}
                            >
                                Save Details
                            </CustomButton>
                        </RaisedSurface>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditBasicDetails;

EditBasicDetails.propTypes = {
    history: PropTypes.object,
    authUser: PropTypes.object,
    activeProfileEditFields: PropTypes.array,
    updateDetails: PropTypes.func
};