import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ForumDetail from './ForumDetail';
import {forumOperations} from '../../duck';
import { appSelectors, appOperations } from '../../../../../duck';

const mapStateToProps = state => ({
    authUser: appSelectors.getAuthUser(state),
});

const mapDispatchToProps = {
    setBottomNavVisibility: appOperations.setBottomNavVisibility,
    leaveForum: forumOperations.leaveForum,
    fetchForumMessages: forumOperations.fetchForumMessages,
    sendForumMessage: forumOperations.sendForumMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForumDetail));