import React from 'react';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';

import ActionButton from '../../../../../components/ActionButton';
import RiseupIcon from '../../../../../components/RiseupIcon';

import "./styles.css";

const NotificationItem = ({ notification, onClick, markRead }) => {
    const { type, title, updatedAt, readStatus } = notification;
    const humanDiffDate = formatDate(new Date(updatedAt), 'YYY MMM, dd');
    return (
        <div className="NotificationItem d-flex">
            <span className="notification-icon d-flex center-center">
                <RiseupIcon icon={type.toLowerCase()} />
            </span>

            <div className="flex" onClick={() => onClick(notification)}>
                <h3>{title}</h3>
                <small>{humanDiffDate}</small>
            </div>

            {
                !readStatus &&
                <div className='w-12 h-12' onClick={() => markRead(notification._id)}>
                    <ActionButton>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </ActionButton>
                </div>
            }
        </div>
    );
}

export default NotificationItem;

NotificationItem.propTypes = {
    notification: PropTypes.object.isRequired,
    onClick: PropTypes.func
};

NotificationItem.defaultProps = {
    notification: {},
    onClick: () => { }
};