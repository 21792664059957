import { connect } from 'react-redux';
import LearningLab from './LearningLab';
import {appSelectors} from '../../../duck';
import {learningLabOperations, learningLabSelectors} from './duck';

const mapStateToProps = state => ({
    activeTab: learningLabSelectors.getActiveTab(state),
    authUser: appSelectors.getAuthUser(state),
    authToken: appSelectors.getToken(state),
    savingInterests: state.pageLoading,
});

const mapDispatchToProps = {
    setActiveTab: learningLabOperations.setActiveTab,
    saveInterests: learningLabOperations.saveLearningLabs
};

export default connect(mapStateToProps, mapDispatchToProps)(LearningLab);