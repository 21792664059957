import React, { useEffect } from 'react';
import {Route} from 'react-router-dom';
import { AuthenticatedUserToolBar } from "../../../../../components/ToolBar";
import AlertDialog from '../../../../../components/FlNotifications/AlertDialog';
import ActionButton from "../../../../../components/ActionButton";

import ForumConversation from "./components/ForumConversation";
import ForumMessageComposer from "./components/ForumMessageComposer";
import ForumImageMessageUpload from './components/ForumImageMessageUpload';

import "./styles.css";

const forumDetailTitle = (forum) => {
    return (
        <div id="forumDetailTitle" className="d-flex items-center">
            <div>
                <img src={forum.image} alt=""/>
            </div>
            <span className="title">
                { forum.title }
            </span>
        </div>
    );
}

const forumDetailActions = (forum, leaveForum) => {
    return (
        <ActionButton disabled={forum.leaving} 
            onClick={leaveForum}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
        </ActionButton>
    );
}

function ConfirmUnsubscribe({onCancel, onConfirm}){
    return (
        <AlertDialog 
            cancelText={"No, Cancel"} okText={"Yes, Leave"}
            onOkay={onConfirm} onCancel={onCancel}>
            <h2>Sure you wanna leave</h2>
            <p>Leaving a forum means you'll lose access to it's disucussions.</p>
        </AlertDialog>
    );
}

const ForumDetail = ({match, fullScreen = true, forum, authUser, history, leaveForum, sendForumMessage, fetchForumMessages, setBottomNavVisibility}) => {
    const { url } = match;
    forum = forum || {title: "", image: "", messages: []};
    function sendMessage(message, mediaType, media){
        const messagePayload = { 
            forum: forum._id,
            userId: authUser._id,
            nickName: !authUser.anonymity ? authUser.userName || 'Mrembo Flani' : authUser.privateName  || 'Mrembo Flani',
            message,
            mediaType,
            media,
            messageKey: forum._id + '/#/' + Math.random().toString(36).slice(2), //random key
            sentAt: new Date().getTime()
        };

        sendForumMessage(messagePayload);
    }

    useEffect(() => {
        function doStuff(){
            if(forum._id){
                if(!forum.messagesFetched && !forum.fetchingMessages){
                    fetchForumMessages(forum._id);
                }
            }
        }

        doStuff();
    }, [forum, fetchForumMessages]);

    function handleLeaveForum(){
        leaveForum(forum._id, history);
        history.goBack();
    }
    
    function confirmLeaveForum(){
        history.push(`${url}/confirmUnsubscribe`);
    }

    function handleImageSelected(files){
        if(files.length){
            const file = files[0];
            if(file.type.indexOf("image") === -1){
                const alert = {title: "Unsupported file type", message: "The file you chose was not an image, please choose an image."};
                history.push("/alertDialog", {alert, modal: true});
            }
            else if(file.size/1000000 >= 2){
                const ext = ['Bytes', 'KB', 'MB', 'GB'];
                let _size = file.size, i = 0;
                
                while(_size>900){ _size /= 1024; i++; }

                const exactSize = (Math.round(_size*100)/100)+' '+ext[i];
                const alert = {title: "Image too large", message: `The image you chose is too large ${exactSize}, please choose a smaller image.`};
                history.push("/alertDialog", {alert, modal: true,});
            }
            else
                history.push(`${url}/imageMessage`, {image: file});
        }
    }

    return (
        <React.Fragment>
            <div id="ForumDetail" className={fullScreen ? 'full-screen' : ''}>
                <div className="content d-flex flex-col ">
                    { fullScreen && 
                        <AuthenticatedUserToolBar 
                            theme="#e65d57"
                            isLogin={true}
                            onBack={() => window.history.back()}
                            renderTitle={() => forumDetailTitle(forum)}
                            renderActions={() => forumDetailActions(forum, confirmLeaveForum)}
                        />
                    }

                    <ForumConversation 
                        forum={forum}
                        onFetchMessages={page => fetchForumMessages(forum._id, page)} />

                    <ForumMessageComposer 
                        onImageSelected={handleImageSelected}
                        onSendMessage={sendMessage}
                        onInputFocusChanged={setBottomNavVisibility} />
                </div>
            </div>

            <Route exact path={`${url}/imageMessage`} 
                component={(routerProps) =>
                    <ForumImageMessageUpload {...routerProps} onSendMessage={sendMessage} />
                }
            />
            
            <Route exact path={`${url}/confirmUnsubscribe`} 
                component={_ => (
                    <ConfirmUnsubscribe 
                        onConfirm={handleLeaveForum}
                        onCancel={history.goBack} 
                    /> 
                )}
            />
        </React.Fragment>
    );
}
 
export default ForumDetail;