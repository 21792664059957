import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LeaveForum from './LeaveForum';
import {forumOperations, forumSelectors} from '../../../../duck';

const mapStateToProps = state => ({
    forums: forumSelectors.getUserForums(state)
});

const mapDispatchToProps = {
    leaveForum: forumOperations.leaveForum
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeaveForum));