import React from 'react';
import formatDate from 'date-fns/format';

import { isValidUrl } from '../../../../../../../../../../Util';

import MediaImage from './MediaImage';
import MediaYoutubeVideo from './MediaYoutubeVideo';
import MediaLearningLab from './MediaLearningLab';

import './styles.css';

const ConversationMessage = ({sentByAuthUser, message, onHold}) => {

    const renderFormattedMessage = () => {
        let content = message.message;
        if(content && content.length){
            let tokens = content.split(/\s/);

            content = tokens.map((token, i) => {
                let hasSpace = i !== (tokens.length - 1);
                let maybeSpace = hasSpace ? ' ' : '';

                if (isValidUrl(token))
                    return (
                        <a key={i} href={token}>
                            {token}{maybeSpace}
                        </a>
                    );
                
                return token + maybeSpace;
            });
        }

        return <p>{ content }</p>;
    }

    message.sentByAuthUser = sentByAuthUser;
    message.time = message.sentAt ? formatDate(new Date(message.sentAt), 'hh:mm a') : "";

    let className = "ConversationMessage";
    className += message.sentByAuthUser ? " owned" : "";
    className += !message.message || !message.message.length ? " no-caption" : "";
    className += message.mediaType === 'youtube' ? " type-youtube" : "";
    className += message.mediaType === 'image' ? " type-image" : "";
    className += message.deleting ? " deleting" : "";

    let nickName = message.nickName || 'Anonymous';
    if(message.userProfile){
        if(!message.userProfile.anonymity)
            nickName = message.userProfile.userName;
        else
            nickName = message.userProfile.privateName || 'Anonymous';
    }


    function handleMessageHold(){
        if(sentByAuthUser && !message.pending && !message.deleting)
            onHold(message);
    }

    return (
        <div className={className} onClick={handleMessageHold}>
            <div className="meta d-flex justify-between items-center">
                <strong>{nickName.toLowerCase()}</strong>
                <small>{message.pending ? '...' : message.time}</small>
            </div>

            <div className="message">
                { message.mediaType === 'youtube' && 
                    <MediaYoutubeVideo 
                        pending={message.pending} 
                        url={message.media} />
                }

                { message.mediaType === 'image' &&
                    <MediaImage 
                        pending={message.pending} 
                        image={message.media} />
                }

                { message.mediaType && message.mediaType.indexOf('learning-lab') !== -1 &&
                    <MediaLearningLab 
                        type={message.mediaType.replace('learning-lab-')} 
                        data={message.media} />
                }

                {renderFormattedMessage()}
            </div>
        </div>
    );
}
 
export default ConversationMessage;