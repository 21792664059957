import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appOperations } from "../../../../duck";

import ProfileMenu from './ProfileMenu';

const mapDispatchToProps = {
    logout: appOperations.logout,
};

export default connect(null, mapDispatchToProps)(withRouter(ProfileMenu));