import { connect } from 'react-redux';
import PickNickName from './PickNickName';
import { appOperations } from '../../../duck';
import {pickYourNicknameOperations} from "./duck";

const mapDispatchToProps = {
    checkNickNameAvailability: pickYourNicknameOperations.checkNickNameAvailability,
    checkEmailAvailability: pickYourNicknameOperations.checkEmailAvailability,
    register: appOperations.registerUser,
    signup: appOperations.signup
};

const mapStateToProps = state => ({
    email: state.email.data,
    emailAvailable: state.email.isAvailable,
    nickName: state.nickName.data,
    nickNameAvailable: state.nickName.isAvailable,
    nickNameValidationMessage: state.nickName.error.message,
    isAuthenticated: state.isAuthenticated,
    authenticatingUser: state.authenticatingUser,
    signingUp: state.pageLoading,
    authToken: state.authToken
});

export default connect(mapStateToProps, mapDispatchToProps)(PickNickName);
