import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { appOperations, appSelectors } from '../../../../../duck';
import { opportunitiesSelectors } from '../../duck';
import OpportunityDetail from './OpportunityDetail';

class OpportunityDetailContainer extends React.Component {
    state = {};
    componentDidMount(){
        const {match, setSelectedOpportunity} = this.props;
        const {listName, opportunityId } = match.params;
        setSelectedOpportunity(listName, opportunityId);
    }

    componentWillUnmount(){
        this.props.unsetSelectedOpportunity()
    }

    componentWillReceiveProps(newProps){
        const { currentOpportunity, match, activeInterest, shouldFetchNextOpportunities, getAllOpportunities, getDueTodayOpportunities } = newProps;
        if (shouldFetchNextOpportunities) {
            if (match.params.listName === 'all') 
                getAllOpportunities(activeInterest._id);
            else 
                getDueTodayOpportunities(activeInterest._id);
        }

        this.setState({derivedCurrentOpportunity: currentOpportunity})
    }

    /**
     * @function
     * @name openOpportunity
     * @description Opens an opportunity given it's id
     * @param {string} newOpportunityId - id of opportunity to open
     * */
    openOpportunity = newOpportunityId => {
        const {match, history, setSelectedOpportunity} = this.props;
        const { listName, opportunityId } = match.params;
        const newUrl = `${match.url.replace(opportunityId, newOpportunityId)}`;
        history.replace(newUrl);
        setSelectedOpportunity(listName, newOpportunityId);
    };

    render() { 
        const { match, history, activeInterest,
            previousOpportunityId,
            currentOpportunity,
            
            nextOpportunityId,
        } = this.props;
        const { opportunityId } = match.params;
        const currentInterest = currentOpportunity && activeInterest._id === 'other'? currentOpportunity.interests[0]
            : activeInterest;
        
        if (opportunityId === 'all' || this.state.derivedCurrentOpportunity === undefined)
            return null;

        return (
            <OpportunityDetail
                {...this.props}
                opportunity={currentOpportunity}
                activeInterest={currentInterest}
                canPrev={previousOpportunityId !== null}
                canNext={nextOpportunityId !== null}
                onPrev={() => this.openOpportunity(previousOpportunityId)}
                onNext={() => this.openOpportunity(nextOpportunityId)}
                onGoBack={history.goBack}
            />
        )
    }
}

const mapDispatchToProps = {
    setSelectedOpportunity: appOperations.setSelectedOpportunity,
    unsetSelectedOpportunity: appOperations.unsetSelectedOpportunity,
    getAllOpportunities: appOperations.getAllOpportunities,
    getDueTodayOpportunities: appOperations.getDueTodayOpportunities
}

const mapStateToProps = state => ({
    previousOpportunityId: opportunitiesSelectors.previousOpportunityId(state),
    currentOpportunity: opportunitiesSelectors.currentOpportunity(state),
    nextOpportunityId: opportunitiesSelectors.nextOpportunityId(state),
    shouldFetchNextOpportunities: opportunitiesSelectors.shouldFetchNextOpportunities(state),
    activeInterest: opportunitiesSelectors.getActiveInterest(state),
    userToken: appSelectors.getToken(state)
})

export default connect(mapStateToProps,mapDispatchToProps)(OpportunityDetailContainer);

OpportunityDetailContainer.propTypes = {
    activeInterest: PropTypes.object,
    previousOpportunityId: PropTypes.string,
    currentOpportunity: PropTypes.object,
    nextOpportunityId: PropTypes.string,
    shouldFetchNextOpportunities: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    isLogin: PropTypes.bool,
    theme: PropTypes.string
}
