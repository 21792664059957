import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import ToolBar from "../../../../../components/ToolBar";
import CustomTabs from "../../../../../components/Tabs";
import {getObjectByIdFromArray} from "../../../../../../Util";

import AllInterestOpportunities from "./components/AllInterestOpportunities";
import SummaryOfInterestOpportunities from "./components/SummaryOfInterestOpportunities";
import DueTodayOpportunities from './components/DueTodayOpportunities';
import ViewOtherOpportunity from "../ViewOtherOpportunity";
import ViewDueTodayOpportunity from "../ViewDueTodayOpportunity";

import './styles.css';

/* An object representing other interest
this hardcoded to be used in displaying other interests
tab
* */
const otherInterest = {name: 'More', _id: 'other', color: '#e65d57'};

/**
 * Base component for viewing opportunities
 * @class
 * @name ViewOpportunities
 *
 * @version 0.1.0
 * @since 0.1.0
 */
class ViewOpportunities extends Component {

    /**
     *  Updates the active interest in the redux store
     * this happens whenever tab changes
     *
     * @function
     * @name onChangeActiveTab
     *
     * @version 0.1.0
     * @since 0.1.0
     */
    onChangeActiveTab = (interestId, tabOptions) => {
        const activeInterest = getObjectByIdFromArray(tabOptions, interestId);
        this.props.setActiveInterest(activeInterest);
    };

    render() {
        const { match: { url }, activeInterest, selectedInterests } = this.props;
        const { color } = activeInterest;
        const tabOptions = [...selectedInterests, otherInterest]; //merge fetched interest  with other interest
        
        return (
            <div className="Opportunities">
                <div style={{height: '100%'}} className="position-relative overflow-hidden">
                    <div className="flower-deco"/>
                    <ToolBar isAuthenticated={this.props.isAuthenticated} isLogin={this.props.isLogin} theme={color}/>
                    <CustomTabs
                        activeTab={activeInterest}
                        tabOptions={tabOptions}
                        handleOnClick={interestId => this.onChangeActiveTab(interestId, tabOptions)}
                        theme={color}
                        url={url}
                    >
                        <Switch>
                            <Route exact path={`${url}`} render={(props) => <SummaryOfInterestOpportunities {...props} theme={color}/>} />
                            <Route path={`${url}/:interestId?/all`} render={(props) => <AllInterestOpportunities {...props} theme={color}/>} />
                            <Route exact path={`${url}/:interestId/dueToday/all`} render={(props) => <DueTodayOpportunities {...props} theme={color}/>} />
                            <Route exact path={`${url}/other/:interestId/:opportunityId`} render={(props) => <ViewOtherOpportunity {...props}/>} />
                            <Route exact path={`${url}/dueToday/:interestId/:opportunityId`} render={(props) => <ViewDueTodayOpportunity {...props}/>} />
                        </Switch>
                    </CustomTabs>
                </div>
            </div>
        );
    }
}

export default ViewOpportunities;

ViewOpportunities.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isLogin: PropTypes.bool,
    activeInterest: PropTypes.object,
    selectedInterests: PropTypes.array,
    setActiveInterest: PropTypes.func,
    userToken: PropTypes.string
};
