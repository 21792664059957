import * as types from './types';
import filter from 'lodash/filter';

/*Initial schema state */
const initialSchema = {
    interests: []
};

/**
 * Schema reducer
 * Is the field in the store which holds data for the App schema .
 *
 * @function
 * @name schema
 *
 * @param {Object} state - Initial state
 * @param {Object} action - Redux action object*
 * @version 0.1.0
 * @since 0.1.0
 */
export const schema = (state = initialSchema, action) => {
    switch (action.type) {
        case types.INIT_INTERESTS:
            return { ...state, ...action.payload};

        default:
            return state;

    }
};

export const pageLoading = (state = false, action) => {
    switch (action.type) {
        case types.PAGE_LOADING:
            return action.payload;
        default:
            return state;
    }
};

export const hideBottomNav = (state = false, action) => {
    switch (action.type) {
        case types.SET_BOTTOM_NAV_VISIBILITY:
            return action.payload;
        default:
            return state;
    }
};

export const isAuthenticated = (state = false, action) => {
    switch (action.type) {
        case types.AUTH_SUCCESS:
            return true;
        case types.LOGOUT_USER:
            return false;
        default:
            return state;
    }
};

export const isLogin = (state = false, action) => {
    switch (action.type) {
        case types.LOGIN_USER:
            return true;
        case types.LOGOUT_USER:
            return false;
        default:
            return state;
    }
};

export const authenticatingUser = (state = false, action) => {
    switch (action.type) {
        case types.AUTH_START:
            return true;
        case types.AUTH_SUCCESS:
            return false;
        default:
            return state;
    }
};

export const authToken = (state = {token: '', provider: ''}, action) => {
    switch (action.type) {
        case types.SET_AUTH_TOKEN:
            return action.payload;
        default:
            return state;
    }
};

export const userDetails = (state = {}, action) => {
    switch (action.type) {
        case types.SET_USER_DETAILS:
            return action.payload;
        default:
            return state;
    }
};

export const activeProfileEditFields = (state = [], action) => {
    switch (action.type) {
        case types.ADD_PROFILE_EDIT_FIELD:
            return [...state, action.payload];
        case types.REMOVE_PROFILE_EDIT_FIELD:
            return state.filter(field => field !== action.payload)
        default:
            return state;
    }
};

const initialInterestsState = {
    selected: [],
    active: {}
};

export const interests = (state = initialInterestsState, action) => {
    switch (action.type) {
        case types.SET_SELECTED_INTERESTS:
            return {...state, selected: action.payload};
        case types.SET_ACTIVE_INTEREST:
            return {...state, active: action.payload};
        default:
            return state;
    }
};

const initialOpportunitiesState = {
    all: {
        "opportunities": [],
        "totalCount": null,
        "page": 0,
        "loading": false
    },
    dueToday: {
        "opportunities": [],
        "totalCount": null,
        "page": 0,
        "loading": false
    },
    other: [],
    details: {
        prev: null,
        current: null,
        next: null,
        navigationCount: 0
    },
    current: {
        listName: null,
        id: null,
        navigationCount: 0
    }
};

export const opportunities = (state = initialOpportunitiesState, action) => {
    switch (action.type) {
        case types.SET_OTHER_OPPORTUNITIES:
            return {...state, other: action.payload};
        case types.SET_ALL_OPPORTUNITIES:
            return {...state, all: action.payload};
        case types.EMPTY_ALL_OPPORTUNITIES:
            return {...state, all: initialOpportunitiesState};
        case types.SET_ALL_OPPORTUNITIES_LOADING:{
            const all = {...state.all, loading: action.payload};
            return {...state, all};
        }

        case types.SET_DUE_TODAY_OPPORTUNITIES:
            return {...state, dueToday: action.payload};
        case types.EMPTY_DUE_TODAY_OPPORTUNITIES:
            return {...state, dueToday: initialOpportunitiesState.dueToday};
        case types.SET_DUE_TODAY_OPPORTUNITIES_LOADING:{
            const dueToday = {...state.dueToday, loading: action.payload};
            return {...state, dueToday};
        }

        case types.SET_OPPORTUNITY_DETAIL: {
            const {opportunityId, listName} = action.payload;
            const current = {id: opportunityId, listName, navigationCount: state.current.navigationCount + 1};
            return {...state, current};
        }
        case types.UNSET_OPPORTUNITY_DETAIL:
            const current = {...initialOpportunitiesState.current, navigationCount: 0}
            return {...state, current};
        default:
            return state;
    }
};

const initialOpportunityState = {
    data: {},
    loading: false
};

export const opportunity = (state = initialOpportunityState, action) => {
    switch (action.type) {
        case types.CREATE_OPPORTUNITY_START:
            return {...state, loading: true};
        case types.CREATE_OPPORTUNITY_SUCCESS:
            return {...state, loading: false};
        case types.CREATE_OPPORTUNITY_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};

export const dream = (state = '', action) => {
    switch (action.type) {
        case types.SET_DREAM:
            return action.payload;
        default:
            return state;
    }
};

export const anonymity = (state = 'Private', action) => {
    switch (action.type) {
        case types.SET_ANONYMITY:
            return action.payload;
        default:
            return state;
    }
};

export const user = (state = {}, action) => {
    switch (action.type) {
        case types.SET_AUTH_USER:
            return action.payload;
        default:
            return state;
    }
};

const initialNotificationsState = {
    notifications: null,
    totalCount: null,
    page: 0,
    filter: "All",
    loading: false
};

export const notifications = (state = initialNotificationsState, action) => {
    switch (action.type) {
        case types.SET_NOTIFICATIONS_LOADING:
            return {...state, loading: action.payload};
        case types.SET_NOTIFICATIONS_FILTER:
            return {...state, filter: action.payload};
        case types.SET_NOTIFICATIONS:
            return {...state, ...action.payload, loading: false};
        case types.EMPTY_NOTIFICATIONS:
            return initialNotificationsState;
        case types.SET_NOTIFICATION_READ_STATUS:{
            const {notificationId, status} = action.payload;
            const notifications = state.notifications.map(notification => {
                if(notification._id === notificationId)
                    notification.readStatus = status;
                    
                return notification;
            });
            return {...state, notifications};
        }
        case types.REMOVE_NOTIFICATION:
            const notifications = filter(state.notifications, ({_id}) => _id !== action.payload)
            return {...state, notifications};
        default:
            return state;
    }
};