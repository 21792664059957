export const SET_BOTTOM_NAV_VISIBILITY = 'SET_BOTTOM_NAV_VISIBILITY';
export const PAGE_LOADING = 'PAGE_LOADING';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const ADD_PROFILE_EDIT_FIELD = 'ADD_PROFILE_EDIT_FIELD';
export const REMOVE_PROFILE_EDIT_FIELD = 'REMOVE_PROFILE_EDIT_FIELD';
export const SET_DREAM = 'SET_DREAM';
export const SET_ANONYMITY = 'SET_ANONYMITY';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_DISTRICTS = 'SET_DISTRICTS';
export const INIT_INTERESTS = 'INIT_INTERESTS';
export const SET_OPPORTUNITIES = 'SET_OPPORTUNITIES';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_ACTIVE_INTEREST = 'SET_ACTIVE_INTEREST';
export const SET_SELECTED_INTERESTS = 'SET_SELECTED_INTERESTS';
export const EMPTY_ALL_OPPORTUNITIES = 'EMPTY_ALL_OPPORTUNITIES';
export const SET_ALL_OPPORTUNITIES = 'SET_ALL_OPPORTUNITIES';
export const SET_ALL_OPPORTUNITIES_LOADING = 'SET_ALL_OPPORTUNITIES_LOADING';
export const EMPTY_DUE_TODAY_OPPORTUNITIES = 'EMPTY_DUE_TODAY_OPPORTUNITIES';
export const SET_DUE_TODAY_OPPORTUNITIES = 'SET_DUE_TODAY_OPPORTUNITIES';
export const SET_DUE_TODAY_OPPORTUNITIES_LOADING = 'SET_DUE_TODAY_OPPORTUNITIES_LOADING';
export const SET_OTHER_OPPORTUNITIES = 'SET_OTHER_OPPORTUNITIES';
export const SET_OPPORTUNITY_DETAIL = 'SET_OPPORTUNITY_DETAIL';
export const UNSET_OPPORTUNITY_DETAIL = 'UNSET_OPPORTUNITY_DETAIL';
export const CREATE_OPPORTUNITY_START = 'CREATE_OPPORTUNITY_START';
export const CREATE_OPPORTUNITY_SUCCESS = 'CREATE_OPPORTUNITY_SUCCESS';
export const CREATE_OPPORTUNITY_ERROR = 'CREATE_OPPORTUNITY_ERROR';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const EMPTY_NOTIFICATIONS = 'EMPTY_NOTIFICATIONS';
export const SET_NOTIFICATION_READ_STATUS = 'SET_NOTIFICATION_READ_STATUS';
export const SET_ALL_NOTIFICATION_READ_STATUS = 'SET_ALL_NOTIFICATION_READ_STATUS';
export const SET_NOTIFICATIONS_FILTER = 'SET_NOTIFICATIONS_FILTER';
export const SET_NOTIFICATIONS_LOADING = 'SET_NOTIFICATIONS_LOADING';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';