import React from 'react';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';

import RiseupIcon from '../../../../../../components/RiseupIcon';

import "./styles.css";

const NotificationItem = ({notification, onClick}) => {
    const {type, title, updatedAt} = notification;
    const humanDiffDate = formatDate(new Date(updatedAt), 'YYY MMM, dd');
    return ( 
        <div className="NotificationItem d-flex"
            onClick={() => onClick(notification)}>
            <span className="notification-icon d-flex center-center">
                <RiseupIcon icon={type.toLowerCase()} />
            </span>

            <div className="flex">
                <h3>{title}</h3>
                <small>{humanDiffDate}</small>
            </div>
        </div>
    );
}
 
export default NotificationItem;

NotificationItem.propTypes = {
    notification: PropTypes.object.isRequired
};

NotificationItem.defaultProps = {
    notification: {}
};