import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import NavigationBar from "../components/NavigationBar";
import MainAppRouter from "./MainAppRouter";
import { differenceInCalendarYears } from "date-fns";

import OfflineBanner from "./OfflineBanner";

import "./styles.css";

function calculateAge(dob) {
    var now = new Date();
    var dob = new Date(dob);
    
    var milliseconds = now.getTime() - dob.getTime();
    var years = Math.floor(milliseconds / 31536000000);
    
    return years;
}


const MainApp = ({
  notifications,
  fetchingNotifications,
  fetchNotifications,
  currentUser,
  history,
}) => {
  useEffect(() => {
    fetchNotifications();

    if (calculateAge(currentUser.dob) >= 36) {
      history.push("/goodbye");
    }
    
  }, []);

  return (
    <div id="MainApp">
      <div id="MainAppContent" className="flex">
        <MainAppRouter />
      </div>
      <NavigationBar />

      <OfflineBanner />
    </div>
  );
};

export default withRouter(MainApp);
