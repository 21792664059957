import React from 'react';

import Loader from '../../../components/Loader';
import ToolBar from '../../../components/ToolBar';

import './styles.css';

const PlayYoutubeVideo = ({match, history}) => {
    const videoUrl = `https://www.youtube.com/embed/${match.params.videoId}?autoplay=1`;
    const width = window.innerWidth;
    const height = width*9/16;
    return ( 
        <div id="playYoutubeVideo">
            <div className="position-absolute pin-all d-flex center-center">
                <Loader color="#fff"/>
            </div>
            
            <iframe title="Opportunity video" width={width} height={height} src={videoUrl} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullscreen />

            <div className="position-absolute pin-top pin-left pin-right">
                <ToolBar
                    isAuthenticated={true} isLogin={true}
                    title=" "
                    noActions
                    theme="#000"
                    onClose={() => history.goBack()}
                />
            </div>
        </div>
    );
}

export default PlayYoutubeVideo;