import React, { useEffect } from 'react';
import tawkTo from 'tawkto-react';

const tawkToPropertyId = '5d6e6d3b77aa790be3322e91';

function OpenTawk({history}){
    useEffect(() => {
        tawkTo(tawkToPropertyId);

        const tawk = document.getElementById('tawkId');
        if (tawk && window.tawkChatUI) {
            window.Tawk_API.maximize();
            if(window.tawkChatUI)
                window.tawkChatUI.style.zIndex = 3000;
        }
        else
            handleTawQ();
    }, []);

    function handleTawQ(){
        function hideTAWQ(){
            if(window.tawkChatUI)
                window.tawkChatUI.style.zIndex = -1;
        }

        window.Tawk_API.onLoad = function(){
            window.Tawk_API.maximize();
            console.log("TAWQ loaded!");
            var tawqWrapper = document.querySelector("body > div:last-child");
            if(!["offline, root"].includes(tawqWrapper.id)){
                window.tawkChatUI = tawqWrapper;
                // hideTAWQ();
            }
        };
        
        window.Tawk_API.onChatMinimized = function(e){
            hideTAWQ();
            if(window.initialTawkMinimize)
                history.goBack();
            else
                window.initialTawkMinimize = true;
        };
    }

    return null;
}

export default OpenTawk;