import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OtherForums from "./OtherForums";
import { forumOperations, forumSelectors } from "../../duck";

const mapStateToProps = (state) => ({
  fetchingForums: forumSelectors.getOtherForumsLoading(state),
  fetchForumsError: forumSelectors.getOtherForumsError(state),
  forums: forumSelectors.getOtherForums(state),
  previewedForum: forumSelectors.getPreviewedForum(state),
});

const mapDispatchToProps = {
  fetchForums: forumOperations.getOtherForums,
  setPreviewedForumId: forumOperations.setPreviewedForumId,
  joinForum: forumOperations.joinForum,
  fetchOtherForums: forumOperations.getOtherForums,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OtherForums));
