import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import CustomButton from "../../../components/Button";
import ToolBar from "../../../components/ToolBar";
import Loader from '../../../components/Loader';

import InterestItem from "../../../components/InterestItem";
import RaisedSurface from '../../../components/RaisedSurface';

import './styles.css';


const isChecked = (id, checkedInterests) => {
    return checkedInterests.includes(id);
};

const addDisabledProperty = (allInterests, checkedInterests) => {
    return allInterests.map( interest => {
        const disabled = !isChecked(interest._id, checkedInterests);
        const checked = isChecked(interest._id, checkedInterests);
        return { ...interest, disabled, checked};
    });
};

const checkSelectedInterests =  (allInterests, checkedInterests, enabled=false) => {
    return allInterests.map( interest => {
        const checked = isChecked(interest._id, checkedInterests);

        return enabled ? { ...interest, checked, disabled: !enabled } : { ...interest, checked } ;
    });
};

class Interests extends Component {
    static propTypes = {
        history: PropTypes.object,
        savingInterests: PropTypes.bool,
        isAuthenticated: PropTypes.bool,
        addUserInterests: PropTypes.func,
        getAllInterests: PropTypes.func,
        interests: PropTypes.array
    };

    constructor(props) {
        super(props);
        this.state = {
            interests: [],
            modifiedInterests: [],
            isContinueDisabled: true
        }
    }

    componentDidMount() {
        if(this.props.token)
            this.props.getAllInterests();

    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({modifiedInterests: nextProps.interests});
    }

    handleSubmit = () => {
        const { interests } = this.state;
        this.props.addUserInterests(interests, this.props.history);
    };

    updateCheckedInterestIds = ({_id}) => {
        const { interests } = this.state;
        if (isChecked(_id, interests))
            this.removeSelectedInterest(_id);
        else
            this.addSelectedInterest(_id);
    };

    addSelectedInterest = (interestId) => {
        const { interests: checkedInterestIds , modifiedInterests } = this.state;
        const updatedInterestIds = [...checkedInterestIds, interestId];
        if (updatedInterestIds.length <= 3) {
            const withSelectedInterests = checkSelectedInterests(modifiedInterests, updatedInterestIds);
            this.setState({interests: updatedInterestIds, modifiedInterests: withSelectedInterests });
        }
        if(updatedInterestIds.length === 3) {
            const withDisabled = addDisabledProperty(modifiedInterests, updatedInterestIds);
            this.setState({modifiedInterests: withDisabled, isContinueDisabled: false });
        }

    };

    removeSelectedInterest = (interestId) => {
        const {interests, modifiedInterests } = this.state;
        const updatedInterestIds = interests.filter(i => i !== interestId);
        const enableAll = updatedInterestIds.length === 2;
        const withSelectedInterests = checkSelectedInterests(modifiedInterests, updatedInterestIds, enableAll);
        this.setState({interests: updatedInterestIds, modifiedInterests: withSelectedInterests, isContinueDisabled: true });

    };

    renderInterests = interests => interests.map( interest => {
        return (
            <InterestItem
                key={interest._id}
                interest={interest}
                checked={interest.checked}
                disabled={interest.disabled}
                onClick={this.updateCheckedInterestIds}
            />
        )
    });

    render() {
        const allInterests = this.state.modifiedInterests;

        return (
            <div className="Interests d-flex flex-col">
                <ToolBar isAuthenticated={true} />
                
                { allInterests.length < 1 && <Loader fill size={100} /> }

                { allInterests.length > 0 && (
                    <div className="contents flex overflow-y-auto">
                        <div className="welcome-text">
                            <div>
                                What do you need
                                to accomplish
                                your dreams?
                            </div>
                        </div>
                        <div className="instructions-text">Pick Three(3)</div>
                        <div className="interests-form">
                            { this.renderInterests(allInterests)}
                            <RaisedSurface>
                                <CustomButton
                                    width="100%"
                                    color="#e0493f"
                                    loading={this.props.savingInterests}
                                    disabled={this.state.isContinueDisabled}
                                    handleOnClick={this.handleSubmit}
                                >
                                    Continue
                                </CustomButton>
                            </RaisedSurface>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}


export default withRouter(Interests);