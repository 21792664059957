import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

function CustomRadioButton(props) {

    return (
        <div className="CustomRadioButton">
            <label className="radio-button">
                <div className="title-text">{props.label}</div>
                <input type="radio" name="radio" value={props.value} onChange={props.handleOnChange} checked={props.checked} defaultChecked={props.defaultChecked} />
                <span className="checkmark"></span>
            </label>
            <div className="button-message">{props.message}</div>
        </div>
    );
}

export default CustomRadioButton;

CustomRadioButton.propTypes = {
    label: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    value: PropTypes.number,
    checked: PropTypes.bool,
    handleOnChange: PropTypes.func
};