import React from "react";
import {Icon} from 'antd';
import PropTypes from 'prop-types';
import Opportunity from "../../../Opportunity";

/**
 * Component that renders other opportunities section
 * @function
 * @name OtherOpportunitiesSection
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Null}
 */
function OtherOpportunitiesSection (props) {
    const { sectionTitle, url, opportunities, interestId, interestColor } = props;


    const viewAllUrl = `${url}/${interestId}/all`;
    /**
     * Helper function for rendering array of opportunities
     * @function
     * @name renderOpportunities
     * @params {Array} opportunities - array of opportunities
     * @returns {Array} - opportunities to be renders
     * @version 0.1.0
     * @since 0.1.0
     * */
    const renderOpportunities = opportunities =>
        opportunities.map(({resourceImg, title, deadlineDate, _id}) =>
            <Opportunity
            key={_id}
            imageUrl={resourceImg}
            url={`${url}/other/${interestId}/${_id}`}
            opportunityTitle={title}
            date={deadlineDate}
            id={_id}
        />);

    const viewOtherOpportunitiesList = () => {
        const { history, sectionTitle, setActiveInterest } = props;
        const activeInterest = {name: 'More', _id: 'other', color: '#e65d57', currentOtherChildInterest: {title: sectionTitle, color: interestColor}};
        setActiveInterest(activeInterest);
        history.push(viewAllUrl);
    }

    return opportunities.length > 0 ? (
        <section>
        <div className="section-title" style={{color: interestColor}}>{sectionTitle}</div>
        <div className="section-contents">
            {renderOpportunities(opportunities)}
        </div>
        <div onClick={viewOtherOpportunitiesList}>
            <div className="view-all" style={{ color: interestColor, cursor: "pointer" }}>
                <div>
                    View All &nbsp; <Icon type="arrow-right" />
                </div>
            </div>
        </div>
    </section>
    ): null;
}

export default OtherOpportunitiesSection;

OtherOpportunitiesSection.propTypes = {
    sectionTitle: PropTypes.string,
    url: PropTypes.string,
    opportunities: PropTypes.array,
    interestColor: PropTypes.string,
    interestId: PropTypes.string,
};