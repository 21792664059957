import React from "react";
import PropTypes from 'prop-types';
import './styles.css';
import OpportunitiesBasedOnOtherInterest from "./components/OpportunitiesBasedOnOtherInterest";
import OpportunitiesBasedOnInterest from "./components/OpportunitiesBasedOnInterest";



function SummaryOfInterestOpportunities(props) {
    const { _id: activeInterestId } = props.activeInterest;
    return activeInterestId === 'other' ?
    <OpportunitiesBasedOnOtherInterest {...props}/>
     : <OpportunitiesBasedOnInterest {...props} />;
    

}

export default SummaryOfInterestOpportunities;

SummaryOfInterestOpportunities.propTypes = {
    match: PropTypes.object,
    theme: PropTypes.string,
    dueTodayOpportunities: PropTypes.array,
    recentOpportunities: PropTypes.array,
    otherOpportunities: PropTypes.array,
    dueTodayOpportunitiesLoading: PropTypes.bool,
    allOpportunitiesLoading: PropTypes.bool,
};