import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
function SocialLoginButton (props){

    const buttonStyles = {
        width: `${props.width}`,
        border: `1px solid ${props.color}`,
        backgroundColor: `${props.color}`,
        ...props.style
    };

    let buttonClasses = "SocialLoginButton";
    buttonClasses += props.small ? " small" : "";

    return (
        <div className={buttonClasses}>
            <button
                type="button"
                style={buttonStyles}
                onClick={props.handleOnClick}
                disabled={props.disabled}
            >
                {props.children}
            </button>
        </div>
    );
}

export default SocialLoginButton;

SocialLoginButton.propTypes = {
    small: PropTypes.bool,
    value: PropTypes.string.isRequired,
    handleOnClick: PropTypes.func,
    style: PropTypes.object,
    disabled: PropTypes.bool
};

SocialLoginButton.defaultProps = {
    handleClick: () => {},
    style: {},
    disabled: false
};