import successTick from "../../../../images/success-tick.png";
import clear from "../../../../images/clear.png";
import PropTypes from "prop-types";
import React from "react";

function InputIcon (props) {
    return (
        props.success ? <img
            alt="success"
            src={successTick}
            className="icon-success"
            height="30px"
        />: <img
            alt="clear"
            src={clear}
            className="icon-clear"
            height="30px"
            onClick={props.clear}
        />
    );
}

export default InputIcon;

InputIcon.propTypes = {
    clear: PropTypes.func,
    success: PropTypes.bool
}