import React from 'react';
import FlatButton from "../FlatButton";
import "./styles.css";

const InlineMessage = ({message, action, onActionClick, className}) => {
    return (
        <div id="InlineMessage" className={className}>
            { message }

            { action && action.length &&
                <FlatButton className="brand-color-pinkish-text"
                    handleOnClick={onActionClick}> 
                    { action }
                </FlatButton>
            }
        </div>
    );
}
 
export default InlineMessage;

InlineMessage.defaultProps = {
    onActionClick: () => {}
}