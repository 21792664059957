
import { get } from 'lodash';

/**
 * gets countries from redux state object
 *@function
 * @name getCountriesSelector
 * @param state {Object} - redux state object
 * @returns {Array} - list of countries
 * @version 0.1.0
 * @since 0.1.0
 */
export const getCountriesSelector = state => get(state, "administrativeBoundaries.countries", null);

/**
 * gets fetching state of countries from redux state object
 *@function
 * @name getCountriesFetchState
 * @param state {Object} - redux state object
 * @returns {String} - countries fetch state
 * @version 0.1.0
 * @since 0.1.0
 */
export const getCountriesFetchState = state => get(state, "administrativeBoundaries.countriesFetchState", "idle");


/**
 * gets regions from redux state object
 *@function
 * @name getRegionsSelector
 * @param state {Object} - redux state object
 * @returns {Array} - list of regions
 * @version 0.1.0
 * @since 0.1.0
 */
export const getRegionsSelector = state => get(state, "administrativeBoundaries.regions", null);

/**
 * gets fetching state of regions from redux state object
 *@function
 * @name getRegionsFetchState
 * @param state {Object} - redux state object
 * @returns {String} - regions fetch state
 * @version 0.1.0
 * @since 0.1.0
 */
export const getRegionsFetchState = state => get(state, "administrativeBoundaries.regionsFetchState", "idle");


/**
 * gets districts from redux state object
 *@function
 * @name getDistrictsSelector
 * @param state {Object} - redux state object
 * @returns {Array} - list of districts
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDistrictsSelector = state => get(state, "administrativeBoundaries.districts", null);


/**
 * gets fetching state of districts from redux state object
 *@function
 * @name getDistrictsFetchState
 * @param state {Object} - redux state object
 * @returns {String} - districts fetch state
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDistrictsFetchState = state => get(state, "administrativeBoundaries.districtsFetchState", "idle");