import React from 'react';
import PropTypes from 'prop-types';

import CustomButton from "../../../../../components/Button";
import CustomInput from "../../../../../components/Input";

import './styles.css';

function PickYourUsernameForm({
    nickName,
    nickNameAvailable, 
    comingFromEmail, 
    validationMessage, 
    signingUp, 
    onSubmit, 
    checkNickNameAvailability
}) {
    return (
        <div className="contents">
            <div className="welcome-text">
                { !comingFromEmail &&
                    <>
                        <div className="large-page-title brand-color-pinkish-text">
                            Hello <span>Mrembo,</span>
                        </div>
                        <div className="large-page-subtitle">
                            Create your username to get started.
                        </div>
                    </>
                }
                
                { comingFromEmail &&
                    <>
                        <div className="large-page-title brand-color-pinkish-text">
                            Nice! email set,
                        </div>
                        <div className="large-page-subtitle">
                            Now create your Riseup username to proceed.
                        </div>
                    </>
                }
            </div>
            <form className="PickYourUsernameForm"
                    onSubmit={onSubmit}>
                <CustomInput
                    placeholder="E.g. Taby or Bella"
                    success={nickNameAvailable}
                    validationMessage={nickName && nickName.length > 0 ? validationMessage : ""}
                    onCustomInputChange={checkNickNameAvailability}
                />
                <CustomButton
                    type="submit"
                    width="100%"
                    loading={signingUp}
                    disabled={!nickName || nickName.length < 0 || !nickNameAvailable}
                    color="#e0493f">
                    Continue
                </CustomButton>
            </form>
        </div>
    );
}

export default PickYourUsernameForm;

PickYourUsernameForm.propTypes = {
    nickNameAvailable: PropTypes.bool,
    validationMessage: PropTypes.string,
    signingUp: PropTypes.bool,
    checkNickNameAvailability: PropTypes.func.isRequired,
    onSubmit: PropTypes.func
};