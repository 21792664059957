

export const SET_NICKNAME_START = 'SET_NICKNAME_START';
export const SET_NICKNAME_SUCCESS = 'SET_NICKNAME_SUCCESS';
export const SET_NICKNAME_ERROR = 'SET_NICKNAME_ERROR';
export const SET_NICKNAME_EMPTY = 'SET_NICKNAME_EMPTY';

export const SET_EMAIL_START = 'SET_EMAIL_START';
export const SET_EMAIL_SUCCESS = 'SET_EMAIL_SUCCESS';
export const SET_EMAIL_ERROR = 'SET_EMAIL_ERROR';
export const SET_EMAIL_EMPTY = 'SET_EMAIL_EMPTY';

