import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({className, light = true, fill, ...props}) => {
    let defaultColor = light ? "#c78c71" : "#e4f4f7";
    const color = props.color || defaultColor;
    const thickness = props.thickness || '5';
    let size = props.size || '60';
    size += 'px';

    let styles = {
        backgroundColor: light ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.2)"
    };

    if(fill){
        return (
            <span className={`Loader position-absolute pin-all d-flex center-center ${className}`}
                style={styles}>
                <svg role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" width={size} height={size}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={ { background: 'none'} }><circle cx="50" cy="50" fill="none" stroke={color} strokeWidth={thickness} r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(269.874 50 50)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></circle></svg>
            </span>
        )
    }

    return ( 
        <svg role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" className={className} width={size} height={size}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={ { background: 'none'} }><circle cx="50" cy="50" fill="none" stroke={color} strokeWidth={thickness} r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(269.874 50 50)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></circle></svg>
    );
}

export default Loader;

Loader.propTypes = {
    color: PropTypes.string,
    thickness: PropTypes.number,
    size: PropTypes.number,
    fill: PropTypes.bool
};