import { connect } from 'react-redux';
import Opportunities from './Opportunities';
import {appSelectors} from '../../../duck'

const mapStateToProps = state => ({
    isAuthenticated: appSelectors.getIsAuthenticatedValue(state),
    activeInterest: appSelectors.getActiveInterest(state),
    isLogin: appSelectors.getIsLoginValue(state)
});

export default connect(mapStateToProps)(Opportunities);