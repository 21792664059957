import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appSelectors, appOperations } from "../../../../../duck";
import { learningLabOperations } from "../../../LearningLab/duck";

import EditLearningLabs from './EditLearningLabs';

const mapStateToProps = state => ({
    authUser: appSelectors.getAuthUser(state),
    savingLearningLabs: state.pageLoading,
    authToken: appSelectors.getToken(state),
});

const mapDispatchToProps = {
    updateDetails: appOperations.updateUser,
    saveLearningLabs: learningLabOperations.saveLearningLabs
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditLearningLabs));