import React from 'react';
import usePortal from 'react-useportal'

import Loader from '../Loader';

import './styles.css';

function FullScreenLoader ({className = "", message="Please wait..."}){
    const { Portal } = usePortal();
    className += " FullScreenLoader d-flex center-center";

    return (
        <Portal>
            <div className={className}>
                <div className="FullScreenLoaderContent d-inline-flex flex-wrap center-center">
                    <Loader size={40} />
                    <span>{message}</span>
                </div>
            </div>
        </Portal>
    )
}

export default FullScreenLoader;