import React from 'react';

import "./styles.css";

const RaisedSurface = ({children}) => {
    return (
        <div id="RaisedSurface">
            { children }
        </div>
    );
}
 
export default RaisedSurface;