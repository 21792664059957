import React from "react";
import { Icon } from "antd";
import { connect } from "react-redux";
import { getYouTubeVideoIdFromUrl } from "../../../../../../../../../Util";

import { learningLabSelectors } from "../../../../../duck";
import LearningLabItem from "../../../components/LearningLabItem";

import "./styles.css";

const LearningLabSummarySection = (props) => {
  const {
    title = "Title goes here",
    items,
    onTrendingPage,
    activeTab,
    history,
    wrapItems,
    moreItemsAvailable,
    showSearchButton,
    onViewAllClick,
  } = props;

  const activeTabColor = activeTab ? activeTab.color : "";
  const themeColor = props.themeColor || activeTabColor;

  function handleViewAllClicked() {
    if (typeof onViewAllClick === "function") onViewAllClick();
    else
      history.push(
        `/main/learningLab/all?contentType=${encodeURIComponent(title)}`
      );
  }

  function viewLearningLabItem(item) {
    const {
      _id,
      type,
      resource,
      title,
      videoUrl,
      coverUrl,
      imageUrl,
      source,
      createdAt,
      description,
    } = item;

    if (type == "document" && resource && resource.length) {
      window.open(resource);
      return;
    }

    let pageUrl = "/readArticle";
    let pageSearch = `?articleId=${_id}`;
    Object.entries(item).map(
      ([key, value]) => (pageSearch += `&${key}=${value}`)
    );

    history.push({
      pathname: pageUrl,
      search: pageSearch,
      state: { modal: true },
    });
  }

  return (
    <div
      className={`LearningLabSummarySection ${wrapItems ? "wrap-items" : ""}`}
    >
      {title && (
        <div className="d-flex items-center justify-between">
          <h1 className="flex" style={{ color: themeColor }}>
            {title}
          </h1>

          {showSearchButton && (
            <button
              id="searchButton"
              style={{ color: themeColor }}
              onClick={() => history.push("/main/learningLab/search")}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </button>
          )}
        </div>
      )}

      <div className="d-flex">
        {items.map((item, index) => {
          const { type, tag, trending } = item;
          return (
            <div key={index}>
              <LearningLabItem
                item={item}
                isTrending={!onTrendingPage && trending}
                onClick={() => viewLearningLabItem(item)} //() => history.push(`/main/learningLab/detail?contentType=${encodeURIComponent(title)}&itemId=${item._id}`)}
                onVideoClick={() =>
                  history.push(
                    `/playYoutubeVideo/${getYouTubeVideoIdFromUrl(
                      item.resource
                    )}`,
                    {
                      modal: true,
                    }
                  )
                }
              />
            </div>
          );
        })}
      </div>

      {moreItemsAvailable && !onTrendingPage && (
        <button
          onClick={handleViewAllClicked}
          className="view-all-learning-lab"
          style={{ color: themeColor }}
        >
          View All &nbsp; <Icon type="arrow-right" />
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeTab: learningLabSelectors.getActiveTab(state),
});

export default connect(mapStateToProps)(LearningLabSummarySection);
