import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useS3Uploader from "../../hooks/useS3Uploader";
import { message } from "antd";

import './styles.css';

/**
 * React component for uploading of local files to S3 bucket
 * @function
 * @name CustomFileUpload
 *
 * @version 0.1.0
 * @since 0.1.0
 */
function CustomFileUpload(props) {
    const { className, type, onChange } = props;
    const propsDirName = props.dirName;
    const dirName = propsDirName && propsDirName.length ? propsDirName : 'images';
    const { fileName, uploadedFileUrl, uploadPercent, uploadStatus, upload } = useS3Uploader({ dirName });

    useEffect(() => {
        onChange({ fileName, url: uploadedFileUrl, uploading: uploadStatus === "uploading" });
    }, [fileName, uploadStatus, uploadPercent, uploadedFileUrl, onChange]);

    function handleFileChanged({ target }) {
        if (props.type == "pdf") {
            if (target.files[0].type == getFileFilterFromType(props.type)) {
                if (target.files && target.files.length) {
                    upload(target.files[0]);
                }
            } else {
                message.error("File is not a pdf!!");
            }
        } else if (props.type == "image") {
            if (target.files && target.files.length) {
                upload(target.files[0]);
            }
        }
    }

    const renderIcon = () => {
        const type = props.type || "";
        switch (type.toLowerCase()) {
            case "pdf":
                return <svg viewBox="0 0 512 512" fill="currentColor"><path d="M444.875 109.792L338.208 3.125c-2-2-4.708-3.125-7.542-3.125h-224C83.135 0 64 19.135 64 42.667v426.667C64 492.865 83.135 512 106.667 512h298.667C428.865 512 448 492.865 448 469.333v-352c0-2.833-1.125-5.541-3.125-7.541zM341.333 36.417l70.25 70.25h-48.917c-11.76 0-21.333-9.573-21.333-21.333V36.417zm85.334 432.916c0 11.76-9.573 21.333-21.333 21.333H106.667c-11.76 0-21.333-9.573-21.333-21.333V42.667c0-11.76 9.573-21.333 21.333-21.333H320v64C320 108.865 339.135 128 362.667 128h64v341.333z" /><path d="M310.385 313.135c-9.875-7.771-19.26-15.76-25.51-22.01-8.125-8.125-15.365-16-21.656-23.5 9.813-30.323 14.115-45.958 14.115-54.292 0-35.406-12.792-42.667-32-42.667-14.594 0-32 7.583-32 43.688 0 15.917 8.719 35.24 26 57.698-4.229 12.906-9.198 27.792-14.781 44.573-2.688 8.052-5.604 15.51-8.688 22.406a177.185 177.185 0 00-7.302 3.427c-8.479 4.24-16.531 8.052-24 11.594C150.5 370.177 128 380.844 128 401.906c0 15.292 16.615 24.76 32 24.76 19.833 0 49.781-26.49 71.656-71.115 22.708-8.958 50.938-15.594 73.219-19.75 17.854 13.729 37.573 26.865 47.125 26.865 26.448 0 32-15.292 32-28.115 0-25.219-28.813-25.219-42.667-25.219-4.302.001-15.843 1.272-30.948 3.803zM160 405.333c-6.094 0-10.219-2.875-10.667-3.427 0-7.563 22.552-18.25 44.365-28.583 1.385-.656 2.792-1.313 4.219-1.99-16.021 23.23-31.865 34-37.917 34zm74.667-190.979c0-22.354 6.938-22.354 10.667-22.354 7.542 0 10.667 0 10.667 21.333 0 4.5-3 15.75-8.49 33.313-8.376-12.896-12.844-23.948-12.844-32.292zM242.844 329a220.999 220.999 0 001.938-5.625c3.958-11.875 7.521-22.542 10.698-32.146 4.427 4.875 9.198 9.865 14.313 14.979 2 2 6.958 6.5 13.563 12.135-13.148 2.865-27.137 6.417-40.512 10.657zm119.823 5.552c0 4.792 0 6.781-9.896 6.844-2.906-.625-9.625-4.583-17.917-10.229 3.01-.333 5.229-.5 6.479-.5 15.761 0 20.23 1.541 21.334 3.885z" /></svg>;

            case "image":
                return <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>;

            default:
                return <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>;
        }
    }

    const renderLabel = () => {
        if (uploadPercent === null)
            return fileName ? fileName : props.placeholder;
        else
            return `Uploading(${uploadPercent.toFixed(1)}%)`;
    }


    return (
        <div className={'CustomFileUpload ' + className}>
            <div className="upload-btn-wrapper">
                {uploadPercent !== null &&
                    <div id="uploadProgress" style={{ width: `calc(${uploadPercent}% - 6px)` }} />
                }
                <button type="button" className="btn d-flex items-center">
                    <i id="fileIcon">{renderIcon()}</i>
                    <span>
                        {renderLabel()}
                    </span>
                </button>

                {uploadPercent === null &&
                    <input type="file" name="myfile" onChange={handleFileChanged}
                        accept={getFileFilterFromType(type)}
                    />
                }

                <svg id="chevron" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
            </div>
        </div>
    );
}

export default CustomFileUpload;

CustomFileUpload.TYPE_PDF = "pdf";
CustomFileUpload.TYPE_IMAGE = "image";

CustomFileUpload.propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.string,
    dirName: PropTypes.string,
    onChange: PropTypes.func
};
export const fileFilter = {
    image: "image/*",
    pdf: "application/pdf",
    default: "application/pdf"
}

export const getFileFilterFromType = (type) => fileFilter[type] || fileFilter.default;
