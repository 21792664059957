import React, {useEffect, useState} from 'react';

import Loader from "../Loader";

import "./styles.css";

const BottomSheetOptions = ({title, multiple, loading, options, onClose, onOptionSelected, onChoicesSelected}) => {
    const [isOpen, setOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        setOpen(true);

        return () => setOpen(false);
    }, []);

    function handleClose(){
        setOpen(false);

        if(typeof onClose === 'function'){
            setTimeout(() => {
                onClose();
            }, 200);
        }
    }

    function handleOptionClicked(option, index, selected){
        if(multiple){
            if(selected){
                const newOptions = selectedOptions.filter((i) => i !== index);
                setSelectedOptions(newOptions);
            }
            else
                setSelectedOptions([...selectedOptions, index]);
        }
        else if(typeof onOptionSelected === 'function')
            onOptionSelected(option)
    }

    function handleSubmitChoices(){
        if(typeof onChoicesSelected === 'function'){
            const choices = options.filter((_, index) => selectedOptions.indexOf(index) !== -1);
            onChoicesSelected(choices);
        }
    }

    return (
        <div className={`BottomSheetOptions ${isOpen ? 'open' : ''}`}>
            <div className="BottomSheetOptionsDialog" onClick={handleClose} />
            
            <div className="content">
                { title && title.length && <h3>{ title }</h3> }

                { loading &&
                    <div style={{padding: '2em 0'}} className="d-flex center-center">
                        <Loader size={80} thickness={4} color="#e0493f" />
                    </div> 
                }

                { !loading &&
                    options.map((option, index) => {
                        const {icon, image, label, description} = option;
                        const hasDescription = description && description.length;
                        const selected = selectedOptions.indexOf(index) !== -1;
                        const className = `bottomsheet-option ${hasDescription ? 'two-line' : ''} ${selected ? 'selected' : ''}`;

                        return (
                            <button key={index} type="button" className={className}
                                onClick={() => handleOptionClicked(option, index, selected)}>
                                { icon &&
                                    <div className="icon">{icon}</div>
                                }
                                
                                { image &&
                                    <div className="image">
                                        <img src={image} alt=""/>
                                    </div>
                                }

                                <div className="text">
                                    <h5>{label || option}</h5>
                                    { hasDescription && <p>{description}</p> }
                                </div>

                                {
                                    multiple && (
                                        <div className="choice-indicator">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                        </div>
                                    )
                                }
                            </button>
                        )
                    })
                }

                { !loading && multiple &&
                    <div className="action-buttons d-flex">
                        <button className="action-button close"
                            onClick={handleClose}>
                            Cancel
                        </button>

                        <button className="action-button" 
                            onClick={handleSubmitChoices}>
                            Okay
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}
 
export default BottomSheetOptions;