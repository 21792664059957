import * as types from './types';

export const setActiveTab = (activeTab) => ({
    type: types.SET_LAB_ACTIVE_TAB,
    payload: activeTab
});

export const setContentType = (contentType) => ({
    type: types.SET_LAB_CONTENT_TYPE,
    payload: contentType
});

export const setSelectedItem = (selectedItem) => ({
    type: types.SET_LAB_SELECTED_ITEM,
    payload: selectedItem
});

export const getArticlesStart = () => ({
    type: types.GET_ARTICLES_START
});

export const getArticlesError = (error) => ({
    type: types.GET_ARTICLES_ERROR,
    payload: error
});

export const getArticlesSuccess = (articles) => ({
    type: types.GET_ARTICLES_SUCCESS,
    payload: articles
});

export const getLabSummaryStart = () => ({
    type: types.GET_LAB_SUMMARY_START
});

export const getLabSummaryError = (error) => ({
    type: types.GET_LAB_SUMMARY_ERROR,
    payload: error
});

export const getLabSummarySuccess = (content) => ({
    type: types.GET_LAB_SUMMARY_SUCCESS,
    payload: content
});

export const getLabContentStart = () => ({
    type: types.GET_LAB_CONTENT_START
});

export const getLabContentError = (error) => ({
    type: types.GET_LAB_CONTENT_ERROR,
    payload: error
});

export const getLabContentSuccess = (content) => ({
    type: types.GET_LAB_CONTENT_SUCCESS,
    payload: content
});