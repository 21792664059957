import React from 'react';
import { connect } from 'react-redux';
import {appOperations, appSelectors} from "../../../../../../../../../duck";
import Button from '../../../../../../../../../components/Button';

import "./styles.css";

const NoOpportunities = ({selectedInterests, activeInterest, setActiveInterest}) => {
    const otherInterest = {name: 'More Opportunities', _id: 'other', color: '#e65d57'};
    let allInterests =  [...selectedInterests, otherInterest];
    allInterests = allInterests.filter(interest => interest._id !== activeInterest._id);

    return (
        <div className="NoOpportunities d-flex flex-col justify-between">
            <div className="text-center">
                <h2 style={{color: activeInterest.color}}>
                    Hello beautiful,
                </h2>
                <p>
                    We haven't posted any { activeInterest.name } opportunities yet.
                    Check back a bit later.
                </p>
            </div>


            <div>
                <span>Or Explore</span>
                
                { allInterests.map(interest => 
                    <Button color={interest.color} handleOnClick={() => setActiveInterest(interest)}>
                        {interest.name}
                    </Button>
                ) }
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    setActiveInterest: appOperations.setActiveInterest,
};

const mapStateToProps = state => ({
    isAuthenticated: appSelectors.getIsAuthenticatedValue(state),
    activeInterest:appSelectors.getActiveInterest(state),
    selectedInterests: appSelectors.getUserInterests(state),
    isLogin: appSelectors.getIsLoginValue(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(NoOpportunities);