import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useS3Uploader from '../../../../../../../hooks/useS3Uploader';
import ActionButton from '../../../../../../../components/ActionButton';
import Loader from '../../../../../../../components/Loader';

import "./styles.css";

const UserDp = ({profilePic, savingProfilePic, onChange}) => {
    const { uploadStatus, uploadedFileUrl, upload } = useS3Uploader({dirName: 'images'});
    const showLoader = uploadStatus === "uploading" || savingProfilePic;

    useEffect(() => {
        const uploadedFileUrlChanged = uploadedFileUrl && uploadedFileUrl.length && uploadedFileUrl !== profilePic;
        if(uploadStatus === "idle" && uploadedFileUrlChanged)
            onChange(uploadedFileUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profilePic, uploadStatus, uploadedFileUrl]);

    function handleDpChanged({target}){
        if(target.files && target.files.length){
            upload(target.files[0]);
        }
    }

    return (
        <div id="dp">
            <img src={profilePic} alt=""/>
            
            { showLoader && <Loader fill /> }

            <input id="dpPicker" type="file" 
                style={{display: 'none'}}
                onChange={handleDpChanged}
                accept="image/x-png,image/jpeg,image/jpg,image/webp,.jpeg,.jpg,.png,.webp"
            />

            <ActionButton className="position-absolute pin-right pin-bottom">
                <label htmlFor="dpPicker">
                    <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                </label>
            </ActionButton>
        </div>
    );
}
 
export default UserDp;

UserDp.propTypes = {
    profilePic: PropTypes.string, 
    savingProfilePic: PropTypes.bool,
    onChange: PropTypes.func
};

UserDp.defaultProps = {
    onChange: () => {}
};