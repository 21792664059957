import { connect } from 'react-redux';
import { appSelectors } from "../../duck";

import NavigationBar from './NavigationBar';

const mapStateToProps = state => ({
    isLogin: state.isLogin,
    hideBottomNav: appSelectors.getHideBottomNavStatus(state)
});

export default connect(mapStateToProps)(NavigationBar);