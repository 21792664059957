import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import ViewOpportunities from "./components/ViewOpportunities";
import NewOpportunities from "./components/NewOpportunity";
import OpportunityDetail from "./components/OpportunityDetail";
import HelpCenter from "./components/HelpCenter";

import IframePage from "../../Screens/IframePage";

import './styles.css';

class Opportunities extends Component {

    render() {
        const { match: { url }, activeInterest } = this.props;
        const { color } = activeInterest;
        
        return (
            <Switch>
                <Route path={`${url}/new`} component={NewOpportunities}/>
                <Route path={`${url}/view`} component={ViewOpportunities}/>
                <Route path={`${url}/help-center`} render={ () => <HelpCenter { ...this.props} />}/>
                <Route exact path={`${url}/:interestId/:listName/:opportunityId`} render={(props) => <OpportunityDetail {...props} theme={color} isAuthenticated={this.props.isAuthenticated} isLogin={this.props.isLogin} />} />
                <Route exact path={`${url}/:interestId/:listName/:opportunityId/:url`} render={(props) => <IframePage {...props} theme={color} isAuthenticated={this.props.isAuthenticated} isLogin={this.props.isLogin} />} />
                <Redirect to={`${url}/view`} />
            </Switch>
        );
    }
}

export default Opportunities;


Opportunities.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isLogin: PropTypes.bool,
    activeInterest: PropTypes.object,
};