import React, { Component } from "react";
import PropTypes from "prop-types";
import FacebookLoginWrapper from "../../../components/FacebookLoginWrapper";
import GoogleLoginWrapper from "../../../components/GoogleLoginWrapper";

import "./styles.css";

class Auth extends Component {
  static propTypes = {
    facebookResponse: PropTypes.func.isRequired,
    googleResponse: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="Auth">
        <div className="sign-in-buttons">
          <GoogleLoginWrapper
            value="Login with Google"
            iconStyles={{
              width: 15,
              marginRight: 18,
            }}
            googleResponse={this.props.googleResponse}
          />
          {/* <FacebookLoginWrapper
            value="Login with Facebook"
            iconStyles={{
              width: 15,
              marginRight: 18,
            }}
            facebookResponse={this.props.facebookResponse}
          /> */}
        </div>
        <div style={{ height: "12px" }}></div>
        <div className="sign-up-text">OR Register with:</div>
        <div className="sign-up-buttons">
          <GoogleLoginWrapper
            small
            value="Google"
            googleResponse={this.props.googleResponse}
          />
          {/* <FacebookLoginWrapper
            small
            value="Facebook"
            facebookResponse={this.props.facebookResponse}
          /> */}
        </div>
      </div>
    );
  }
}

export default Auth;
