import { connect } from 'react-redux';
import {learningLabSelectors, learningLabOperations} from '../../../../duck';
import ReadArticle from './ReadArticle';
import { appSelectors } from '../../../../../../../duck';

const mapStateToProps = state => ({
    activeTab: learningLabSelectors.getActiveTab(state),
    authUser: appSelectors.getAuthUser(state),
    authToken: appSelectors.getToken(state)
});

const mapDispatchToProps = {
    setSelectedItem: learningLabOperations.setSelectedItem
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadArticle);