import * as types from './types';

const message = 'Great! username is available';
const initialState = {
    data: '',
    isAvailable: null,
    isSetStart: false,
    error: {
        message: '',
        attempts: 0
    }
};

export const nickName = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_NICKNAME_START:
            return {
                ...state, 
                isSetStart: true, 
                data: action.payload
            };
        case types.SET_NICKNAME_EMPTY:
            return {
                ...state,
                isAvailable: null,
                error: {
                    message: '',
                    attempts: 0
                }
            };
        case types.SET_NICKNAME_SUCCESS:
            return {
                ...state,
                isAvailable: true,
                error: {
                    message,
                    attempts: 0
                },
                data: action.payload,
                isSetStart: false,
                data: action.payload
            };
        case types.SET_NICKNAME_ERROR:
            return {
                ...state,
                isAvailable: false,
                isSetStart: false,
                error: action.payload
            };
        default:
            return state;
    }
};

const emailMessage = 'Great! email is available';
const initialEmailState = {
    data: '',
    isAvailable: null,
    isSetStart: false,
    error: {
        message: '',
        attempts: 0
    }
};

export const email = (state = initialEmailState, action) => {
    switch (action.type) {
        case types.SET_EMAIL_START:
            return {...state, isSetStart: true};
        case types.SET_EMAIL_EMPTY:
            return {
                ...state,
                isAvailable: null,
                error: {
                    message: '',
                    attempts: 0
                }
            };
        case types.SET_EMAIL_SUCCESS:
            return {
                ...state,
                isAvailable: true,
                error: {
                    message: emailMessage,
                    attempts: 0
                },
                isSetStart: false,
                data: action.payload
            };
        case types.SET_EMAIL_ERROR:
            return {
                ...state,
                isAvailable: false,
                isSetStart: false,
                error: action.payload
            };
        default:
            return state;
    }
};
