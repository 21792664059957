import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { lightenDarkenColor } from "../../../utils";
import ToolBar from "../../../components/ToolBar";
import CustomButton from '../../../components/Button';
import Loader from '../../../components/Loader';

import NotificationsFilter from './components/NotificationsFilter';
import NotificationItem from './components/NotificationItem';
import QuickPreview from './components/QuickPreview';

import './styles.css';

const Notifications = (props) => {
    const [previewLocked, setPreviewLocked] = useState(false);
    const [fullNavHeight, setFullNavHeight] = useState(0);
    const fullNav = useRef();
    const { match: { url }, location, activeInterest, currentFilter, unreadCount, notifications, loading, notModal } = props;
    // const { color } = activeInterest;
    const color = "#e65d57";
    const subHeadingColor = activeInterest && activeInterest.color ? lightenDarkenColor(color) : "";
    const emptyMessage = `You have no ${currentFilter.toLowerCase()} notifications!`.replace('all ', '').replace("mentions notifications!", "new mentions!");
    const previewing = location && location.state && location.state.modal;

    useEffect(() => {
        if (fullNav.current) {
            const fullNavBox = fullNav.current.getBoundingClientRect();
            setFullNavHeight(fullNavBox.height);
        }
    }, []);

    useEffect(() => {
        props.getNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNotificationClicked = (notification) => {
        props.markNotififactionAsRead(notification._id);

        if (notification.extras.approved)
            props.history.replace('/opportunityDetail', { modal: true, opportunity: notification.resource });
        else
            props.history.replace('/opportunityRejected', { modal: true, ...notification.resource });
    }

    const handleMarkRead = (notificationId) => {
        props.markNotififactionAsRead(notificationId);
    }
    const handleMarkAllAsRead = () => {
        props.markAllNotificationsAsRead();
    }

    const showAsModal = previewing && !notModal;
    let classNames = "Notifications position-fixed pin-all d-flex flex-col";
    classNames += showAsModal ? ' previewing' : '';
    classNames += previewLocked ? ' preview-locked' : '';

    if (showAsModal && !previewLocked && currentFilter !== "All")
        props.setFilter("All");

    return (
        <div className={classNames}>
            <div className="fullNav" ref={fullNav}>
                <div className="position-relative overflow-hidden">
                    <div className="flower-deco" />
                    <ToolBar isAuthenticated={props.isAuthenticated} isLogin={props.isLogin} theme={color}
                        onBack={() => window.history.back()} />

                    <div className="Subheading d-flex justify-between items-center"
                        style={{ backgroundColor: subHeadingColor }}>
                        <h2>Notifications</h2>
                        {unreadCount !== 0 &&
                            <div onClick={() => handleMarkAllAsRead()}>
                                <CustomButton className="d-flex items-center" handleClick={() => null} >
                                    Clear all Unread
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </CustomButton>
                            </div>
                        }
                    </div>
                </div>

                <NotificationsFilter currentFilter={currentFilter} unreadCount={unreadCount} theme={color} url={url}
                    setFilter={props.setFilter} />
            </div>

            {showAsModal &&
                <div className="backdrop position-absolute pin-all d-flex center-center"
                    onClick={() => window.history.back()} />
            }

            <div className="contents flex position-relative">
                {loading &&
                    <div className="position-absolute pin-all d-flex center-center">
                        <Loader color="#333"
                            size={previewing ? 60 : 80}
                            thickness={3}
                        />
                    </div>
                }

                {!loading && notifications && notifications.length < 1 &&
                    <div className="emptyMessage position-absolute pin-all d-flex center-center text-center">
                        {emptyMessage}
                    </div>
                }

                {notifications && notifications.map((notification, index) =>
                    <NotificationItem key={index} notification={notification}
                        onClick={handleNotificationClicked} markRead={handleMarkRead} />
                )}
            </div>

            <QuickPreview
                loading={loading} notifications={props.latestNotifications} emptyMessage={emptyMessage}
                navHeight={fullNavHeight}
                locked={previewLocked}
                onViewOpportunity={handleNotificationClicked}
                onPreviewLocked={() => setPreviewLocked(true)} />

        </div>
    );
}

export default Notifications;

Notifications.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isLogin: PropTypes.bool,
    activeInterest: PropTypes.object,
    notifications: PropTypes.array,
    loading: PropTypes.bool,
    currentFilter: PropTypes.string,
    unreadCount: PropTypes.number,
    getNotifications: PropTypes.func,
    setFilter: PropTypes.func,
    markNotififactionAsRead: PropTypes.func
};

Notifications.defaultProps = {
    notifications: []
};