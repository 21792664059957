export const GET_ALL_OPPORTUNITIES_START = 'GET_ALL_OPPORTUNITIES_START';
export const GET_ALL_OPPORTUNITIES_SUCCESS = 'GET_ALL_OPPORTUNITIES_SUCCESS';
export const GET_ALL_OPPORTUNITIES_ERROR = 'GET_ALL_OPPORTUNITIES_ERROR';

export const GET_ALL_DUE_TODAY_OPPORTUNITIES_START = 'GET_ALL_DUE_TODAY_OPPORTUNITIES_START';
export const GET_ALL_DUE_TODAY_OPPORTUNITIES_SUCCESS = 'GET_ALL_DUE_TODAY_OPPORTUNITIES_SUCCESS';
export const GET_ALL_DUE_TODAY_OPPORTUNITIES_ERROR = 'GET_ALL_DUE_TODAY_OPPORTUNITIES_ERROR';

export const GET_OTHER_OPPORTUNITIES_START = 'GET_OTHER_OPPORTUNITIES_START';
export const GET_OTHER_OPPORTUNITIES_SUCCESS = 'GET_OTHER_OPPORTUNITIES_SUCCESS';
export const GET_OTHER_OPPORTUNITIES_ERROR = 'GET_OTHER_OPPORTUNITIES_ERROR';

export const SET_ALL_OPPORTUNITIES = 'SET_ALL_OPPORTUNITIES';
export const SET_DUE_TODAY_OPPORTUNITIES = 'SET_DUE_TODAY_OPPORTUNITIES';
export const SET_OPPORTUNITY_DETAIL = 'SET_OPPORTUNITY_DETAIL';
export const UNSET_OPPORTUNITY_DETAIL = 'UNSET_OPPORTUNITY_DETAIL';
