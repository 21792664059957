import * as types from './types';

const initialLearningLabState = {
    activeTab: {
        _id: 'trending',
        color: "#AC8CD6",
        name: 'Trending'
    },
    summary: null,
    summaryLoading: true,
    summaryError: null,
    content: null,
    contentLoading: true,
    contentError: null,
    byInterest: {
        forums: null,
        totalCount: null,
        page: 0,
        loading: false,
        error: null
    },
    trending: null
};

export const learningLab = (state = initialLearningLabState, action) => {
    switch (action.type) {
        case types.SET_LAB_ACTIVE_TAB:
            return {...state, activeTab: action.payload};
        
        case types.SET_LAB_SELECTED_ITEM:
            return {...state, selectedItemId: action.payload};
        
        case types.SET_LAB_CONTENT_TYPE:
            return {...state, contentType: action.payload};
            
        case types.GET_LAB_SUMMARY_START: {
            return {
                ...state, 
                summaryLoading: true,
                summaryError: null,
                summary: null
            };
        }
        case types.GET_LAB_SUMMARY_SUCCESS: {
            return {
                ...state, 
                summaryLoading: false,
                summary: action.payload
            };
        }
        case types.GET_LAB_SUMMARY_ERROR: {
            return {
                ...state,
                summaryLoading: false,
                summaryError: action.payload
            };
        }
        
        case types.GET_LAB_CONTENT_START: {
            return {
                ...state, 
                contentLoading: true,
                contentError: null,
                content: null
            };
        }
        case types.GET_LAB_CONTENT_SUCCESS: {
            return {
                ...state, 
                contentLoading: false,
                content: action.payload
            };
        }
        case types.GET_LAB_CONTENT_ERROR: {
            return {
                ...state,
                contentLoading: false,
                contentError: action.payload
            };
        }

        default:
            return state;
    }
};