import React, { useEffect } from 'react';
import queryString from 'query-string';
import ashura from "../../../../../images/ashura.png";
import Button from '../../../../components/Button';

import "./styles.css";

function WelcomeBack({history, location, loading, reactivateUser}){
    const searchParams = queryString.parse(location.search);
    const userName = searchParams.userName || "there";

    useEffect(() => {
        if(!location || !location.state){
            console.log("No location state: ", location.state);
            history.replace('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="WelcomeBack" className="position-fixed pin-all z10">
            <div id="WelcomeBackContent">
                <img src={ashura} alt="" />

                <h1>
                    Hey {userName}, we're really happy that you're back <span role="img" aria-label="party icon">🥳</span>
                </h1>

                <h2>
                    We've restored all your previous RiseUp account details. Enjoy!
                </h2>

                <Button flat loading={loading}
                    handleOnClick={() => reactivateUser(location.state.user, history)}>
                    Thanks, let's do this

                    <svg className="brand-color-pinkish-text" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </Button>
            </div>
        </div>
    );
}

export default WelcomeBack;