import React from "react";
import PropTypes from 'prop-types';
import OpportunitiesSection from "../OpportunitiesSection";
import Loader from "../../../../../../../../../components/Loader";
import NoOpportunities from "../NoOpportunities";

/**
 * Renders opportunities based on user interests
 * @class
 * @name OpportunitiesBasedOnInterest
 *
 * @version 0.1.0
 * @since 0.1.0
 */
class OpportunitiesBasedOnInterest extends React.Component {

    componentDidMount(){
        this.props.getAllOpportunities(this.props.activeInterest._id);
        this.props.getAllDueTodayOpportunities(this.props.activeInterest._id);

    }

    componentDidUpdate(prevProps) {
        if (this.props.activeInterest._id !== prevProps.activeInterest._id) {
            this.props.getAllOpportunities(this.props.activeInterest._id);
            this.props.getAllDueTodayOpportunities(this.props.activeInterest._id);
        }
    }

    render(){
        const {match: {url}, theme , recentOpportunities, dueTodayOpportunities, allOpportunitiesLoading } = this.props;
        const loading = allOpportunitiesLoading;
        const noOpportunities = (!dueTodayOpportunities || !dueTodayOpportunities.length) && (!recentOpportunities || !recentOpportunities.length);
        return (
            <div className="TabItem">
                { loading &&  <div style={{padding: '2em 0'}} className="d-flex center-center">
                        <Loader size={80} thickness={4} color={theme} />
                    </div> 
                }

                { !loading && noOpportunities &&
                    <NoOpportunities />
                }
    
                { !loading && !noOpportunities &&
                    <React.Fragment>
                        <OpportunitiesSection sectionTitle="DUE TODAY" url={url} opportunities={dueTodayOpportunities} theme={theme}/>
                        <OpportunitiesSection sectionTitle="RECENT" url={url} opportunities={recentOpportunities} theme={theme}/>
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default OpportunitiesBasedOnInterest;

OpportunitiesBasedOnInterest.propTypes = {
    match: PropTypes.object,
    activeInterest: PropTypes.object,
    dueTodayOpportunities: PropTypes.array,
    recentOpportunities: PropTypes.array,
    dueTodayOpportunitiesLoading: PropTypes.bool,
    allOpportunitiesLoading: PropTypes.bool,
    getAllOpportunities: PropTypes.func,
    getAllDueTodayOpportunities: PropTypes.func,
};
