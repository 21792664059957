import React from 'react';
import useOnlineStatus from '@rehooks/online-status';

import ashura from "../../../images/ashura.png";

import "./styles.css";

const OfflineBanner = () => {
    const onlineStatus = useOnlineStatus();
    let className = "position-fixed pin-all d-flex flex-col";
    className += !onlineStatus ? " visible" : "";

    return (
        <div id="OfflineBanner" className={className}>
            <div>
                <img src={ashura} alt=""/>
                <h1>Hey mrembo,</h1>
                <h2>
                    It looks like you lost your internet connection.
                    Make sure your WiFi or mobile data is in order.
                </h2>
                <p>
                    RiseUp will automatically reconnect once your connection is restored.
                </p>
            </div>
        </div>
    );
}
 
export default OfflineBanner;