import { connect } from 'react-redux';
import Toolbar from './ToolBar';
import { appSelectors } from '../../duck';

const mapStateToProps = state => ({
    unreadNoticiationCount: appSelectors.unreadNotificationsCount(state),
});

export {default as AuthenticatedUserToolBar} from './components/AuthenticatedUserToolBar';

export default connect(mapStateToProps)(Toolbar);