import React, { useState } from 'react';
import { getYouTubeVideoIdFromUrl } from '../../../../../../../../../../Util';

const MediaYoutubeVideo = ({pending, url}) => {
    const [mediaLoading, setMediaLoading] = useState(true);
    const videoId = getYouTubeVideoIdFromUrl(url);
    const youtubeCoverUrl = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;

    return ( 
        <a href={url} className="media d-block position-relative">
            <img src={youtubeCoverUrl} alt=""
                onLoad={() => setMediaLoading(false)} />

            { !pending && !mediaLoading &&
                <button className="play-icon"
                    onClick={() => {}}>
                    <svg width="24" height="24" viewBox="0 0 24 24"><path d="M8 5v14l11-7z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                </button>
            }

            { (pending || mediaLoading) &&
                <svg id="mediaUploadingIndicator" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><circle cx="50" cy="50" fill="none" stroke="#888888" strokeWidth="5" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(311.844 50 50)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></circle></svg>
            }
        </a>
    );
}
 
export default MediaYoutubeVideo;