import React, { useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { message } from 'antd';
import formatDate from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import mainForumDp from '../../../../../../../../images/riseup-icon.png';
import { appSelectors } from '../../../../../../../duck';
import { forumSelectors, forumOperations } from '../../../../duck';

import Loader from '../../../../../../../components/Loader';
import BottomSheetOptions from '../../../../../../../components/BottomSheetOptions';
import AlertDialog from '../../../../../../../components/FlNotifications/AlertDialog';
import { copyTextToClipboard } from '../../../../../../../../Util';

import ConversationMessage from './components/ConversationMessage';
import "./styles.css";

const renderDateSeparator = (messages, index, key) => {
    const message = messages[index];
    const lastMessage = messages[index - 1];
    let lastMessageIsOnSameDay = true;

    if (message && message.sentAt && lastMessage && lastMessage.sentAt) {
        let daysApartBetweenMessages = differenceInCalendarDays(new Date(message.sentAt), new Date(lastMessage.sentAt));
        if (daysApartBetweenMessages <= 1)
            daysApartBetweenMessages = new Date(message.sentAt).getDate() - new Date(lastMessage.sentAt).getDate();

        lastMessageIsOnSameDay = daysApartBetweenMessages === 0;
    }

    if (index === 0 || !lastMessageIsOnSameDay) {
        let formattedDate = formatDate(new Date(message.sentAt), 'dd MMM yyyy');
        let daysApart = differenceInCalendarDays(new Date(), new Date(message.sentAt));
        if (daysApart <= 1)
            daysApart = new Date().getDate() - new Date(message.sentAt).getDate();

        if (daysApart < 1)
            formattedDate = "Today";
        else if (daysApart === 1)
            formattedDate = "Yesterday";

        return <div key={key + "separator"} className="day-separator d-flex center-center">
            <span>{formattedDate}</span>
        </div>
    }

    return null;
}

function messageActions(hideForward) {
    const actions = [
        {
            icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>,
            label: "Delete",
        },
        {
            icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>,
            label: "Copy Text",
        },
        // {
        //     icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="15 14 20 9 15 4"></polyline><path d="M4 20v-7a4 4 0 0 1 4-4h12"></path></svg>,
        //     label: "Forward",
        //     description: "Send message to another forum"
        // }
    ];

    // return !hideForward ? actions : actions.filter((_, index) => index !== 2);
    return actions;
}

function forwardMessageOptions(userForums) {
    return userForums.map(({ _id, title, image, type }) => {
        return {
            _id, type, label: title,
            image: type === "main-forum" ? mainForumDp : image
        };
    })
}

const ForumConversation = ({ match, history, userForums, forum, messages, authUser, onFetchMessages, deleteForumMessage, forwardForumMessage }) => {
    const { url } = match;
    const conversationWrapper = useRef(null);
    const [scrolled, setScrolled] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const otherUserForums = !userForums ? [] : userForums.filter(f => f._id !== forum._id);

    useLayoutEffect(() => {
        const node = conversationWrapper.current;
        if (node !== null) {
            if (scrolled)
                node.scrollTo(0, node.scrollTop + 200);
            else
                node.scrollTo(0, node.scrollHeight + node.offsetTop);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    function handleOnscroll() {
        const node = conversationWrapper.current;
        if (node) {
            const scrollTop = node.scrollTop;
            const scrollHeight = node.scrollHeight;
            const offset = node.offsetHeight;
            const scrollDistance = scrollHeight - (scrollTop + offset);

            if (scrollDistance >= 30 && !scrolled)
                setScrolled(true);
            else
                setScrolled(false);

            if (scrollTop <= 100 && forum && forum.lastFetchedPage && !forum.fetchingMessages) {
                const forumTotalMessages = forum.totalMessageCount ? parseInt(forum.totalMessageCount) : 0;
                const lastFetchedPage = parseInt(forum.lastFetchedPage);
                const currentPage = lastFetchedPage + 1;

                if (currentPage <= forumTotalMessages / 30) {
                    onFetchMessages(currentPage);
                }
            }
        }
    }

    function handleShowMessageActions(message) {
        setSelectedMessage(message);
        history.push(`${url}/messageActions`);
    }

    function handleHideMessageActions() {
        setSelectedMessage(null);
        history.goBack();
    }

    function handleMessageActionSelected(option) {
        const action = option.label;

        if (action === "Delete")
            history.replace(`${url}/confirmDeleteMessage`);
        else if (action === "Copy Text") {
            copyTextToClipboard(selectedMessage.message);
            history.goBack();
            message.success("Message text copied");
        }
        else if (action === "Forward")
            history.replace(`${url}/forwardMessage`);
        else
            history.goBack();
    }

    function handleDeleteMessage() {
        history.goBack();
        const messagePayload = { forum: forum._id, messageId: selectedMessage._id, userId: authUser._id };
        deleteForumMessage(messagePayload);
        setSelectedMessage(null);
    }

    function handleForwardMessage(recepientForums) {
        const { message, mediaType, media } = selectedMessage;
        const messagePayload = {
            forum: forum._id,
            userId: authUser._id,
            nickName: authUser.anonymity === 1 ? authUser.userName || 'Mrembo Flani' : authUser.privateName || 'Mrembo Flani',
            message,
            mediaType,
            media,
            messageKey: forum._id + '/#/' + Math.random().toString(36).slice(2), //random key
            sentAt: new Date().getTime()
        };

        forwardForumMessage(messagePayload, recepientForums, history);
        setSelectedMessage(null);
    }

    return (
        <div ref={conversationWrapper} id="ForumConversation" className="d-flex flex-col items-start"
            onScroll={handleOnscroll}>
            {forum && forum.fetchingMessages &&
                <div style={{ padding: '1.5em', alignSelf: 'center' }}>
                    <Loader />
                </div>
            }

            {messages && messages.map((message, index) => {
                return <React.Fragment>
                    {renderDateSeparator(messages, index, message._id || message.messageKey)}

                    <ConversationMessage
                        key={message._id || message.messageKey}
                        sentByAuthUser={message.userId === authUser._id || message.guserId === authUser._id || (message.userProfile && message.userProfile._id === authUser._id)}
                        message={message}
                        onHold={handleShowMessageActions} />
                </React.Fragment>
            })
            }

            <Route exact path={`${url}/messageActions`}
                component={_ => (
                    <BottomSheetOptions
                        options={messageActions(userForums.length < 2)}
                        onOptionSelected={handleMessageActionSelected}
                        onClose={handleHideMessageActions}
                    />
                )}
            />

            <Route exact path={`${url}/forwardMessage`}
                component={_ => (
                    <BottomSheetOptions
                        title="Forward to..."
                        multiple
                        options={forwardMessageOptions(otherUserForums)}
                        onChoicesSelected={handleForwardMessage}
                        onClose={handleHideMessageActions}
                    />
                )}
            />

            <Route exact path={`${url}/confirmDeleteMessage`}
                component={_ => (
                    <AlertDialog
                        title="Sure you want to delete message?"
                        cancelText="No, Cancel"
                        okText="Yes, Delete"
                        onOkay={handleDeleteMessage}
                        onCancel={history.goBack} />
                )}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    authUser: appSelectors.getAuthUser(state),
    messages: forumSelectors.getCurrentForumMessages(state),
    userForums: forumSelectors.getAllUserForums(state),
});

const mapDispatchToProps = {
    deleteForumMessage: forumOperations.deleteForumMessage,
    forwardForumMessage: forumOperations.forwardForumMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForumConversation));