
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PhoneIcon from '../../../../../../../../images/admin-Icons-phone.svg';
import ChatIcon from '../../../../../../../../images/admin-Icons-chat.svg';
import './styles.css';

class ContactAdmin extends Component {

   onOpenChat = () => {
    //    window.CHAT_API.maximize();
    this.props.history.push("/openTawk", {modal: true});
   };

    render() {
        if(this.props.showTips){
            return (
                <div className="ContactAdmin">
                    <div className="instruction d-flex items-center">
                        {this.props.message || 'Want some help with submissions?' }
                    </div>
                    <div className="admin-buttons">
                        <div>
                            <a className="brand-color-pinkish-text" 
                                onClick={() => this.props.history.replace('/main/opportunities/help-center')}
                            >
                                See Tips
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
        
        return (
            <div className="ContactAdmin">
                <div className="instruction d-flex items-center">
                    {this.props.message || 'Stuck? Contact admin'}&nbsp;
                    <svg width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </div>
                <div className="admin-buttons">
                    <div>
                        <a target='_blank' href="tel:+255749858450">
                            <img src={PhoneIcon} alt=""/>
                        </a>
                    </div>
                    <div>
                        <img onClick={this.onOpenChat} src={ChatIcon} alt="" />
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(ContactAdmin);