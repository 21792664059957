import React from 'react';
import PropTypes from 'prop-types';
import AuthenticatedUserToolBar from "./components/AuthenticatedUserToolBar";
import NonAuthenticatedUserToolBar from "./components/NonAuthenticatedUserToolBar";
import './styles.css';

function ToolBar({isAuthenticated, ...props }) {
    
    return (
        <div className="ToolBar">
            {
                isAuthenticated ?
                    <AuthenticatedUserToolBar {...props} />
                    :
                    <NonAuthenticatedUserToolBar/>
            }
        </div>
    );
}

export default ToolBar;

ToolBar.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isLogin: PropTypes.bool,
    theme: PropTypes.string,
    logout: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    title: PropTypes.string,
    noActions: PropTypes.bool,
    unreadNoticiationCount: PropTypes.number
};

ToolBar.defaultProps = {
    isLogin: false,
    theme: '#e65d57'
};
