import PropTypes from "prop-types";
import React from "react";

/**
 * @return {null}
 */
function ValidationText (props) {
    const cssClass = props.success ? 'success' : 'error';
    const text = props.validationMessage;
    return props.success !== null ? (
        <div className="ValidationText">
            <div className={cssClass} >{text}</div>
        </div>
    ) : null;
}

export default ValidationText;

ValidationText.propTypes = {
    success: PropTypes.bool,
    validationMessage: PropTypes.string
};