import React from 'react';

import { Route } from 'react-router-dom';
import BottomSheetOptions from '../../../../../../../components/BottomSheetOptions';
import LearningLabSummarySection from '../Summary/LearningLabSummarySection';

import sampleSearchResults from "./sample_search_results";

function typeOptions(){
    const types = ["All", "Boss Up", "Read to Lead", "Cool Images & Memes"];
    return types.map(label => ({ label }));
}

const SearchResults = ({match, data, history, type, categoryChoices, setType, setCategory, pushQueryParam}) => {
    const bossUp = data.videos;
    const readToLead = data.articles;
    const coolImagesMeme = data.imageMemes;

    function handleCategorySelected(choice){
        history.goBack();
        setCategory(choice.label);
    }
    
    function handleTypeSelected(choice){
        history.goBack();
        setType(choice.label);
    }

    return (
        <div id="searchResults">
            {/* <button id="mainTag" onClick={() => pushQueryParam({q: "#Health"})}>
                #Health
            </button> */}
            
            <div className="d-flex flex-wrap">
                { (type === "Boss Up" || type === "All") && bossUp && bossUp.length > 0 && (
                    <LearningLabSummarySection
                        history={history}
                        title={type === "Boss Up" ? null : "Boss Up"}
                        themeColor="#e65d57"
                        wrapItems
                        items={bossUp}
                        onViewAllClick={() => setType("Boss Up")}
                    />
                )}

                { (type === "Read to Lead" || type === "All") && readToLead && readToLead.length > 0 && (
                    <LearningLabSummarySection
                        history={history} 
                        title={type === "Read to Lead" ? null : "Read to Lead"}
                        themeColor="#e65d57"
                        wrapItems
                        items={readToLead}
                        onViewAllClick={() => setType("Read to Lead")}
                    />
                )}
            
                { (type === "Cool Images & Memes" || type === "All") && coolImagesMeme && coolImagesMeme.length > 0 && (
                    <LearningLabSummarySection
                        history={history} 
                        title={type === "Cool Images & Memes" ? null : "Cool Images & Memes"}
                        themeColor="#e65d57"
                        wrapItems
                        items={coolImagesMeme}
                        onViewAllClick={() => setType("Cool Images & Memes")}
                    />
                )}
            </div>
            
            <React.Fragment>
                <Route exact path={`${match.url}/pickCategory`} 
                    component={_ => (
                        <BottomSheetOptions
                            title="Pick category"
                            options={categoryChoices}
                            onOptionSelected={handleCategorySelected}
                            onClose={() => history.goBack()} 
                        />
                    )}
                />
                
                <Route exact path={`${match.url}/pickType`} 
                    component={_ => (
                        <BottomSheetOptions
                            title="Pick type"
                            options={typeOptions()}
                            onOptionSelected={handleTypeSelected}
                            onClose={() => history.goBack()} 
                        />
                    )}
                />
            </React.Fragment>
        </div>
    );
}
 
export default SearchResults;