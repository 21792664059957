import io from 'socket.io-client';
import * as reducer from './reducers';

import * as forumTypes from './types';
import * as forumOperations from './operations';
import * as forumSelectors from './selectors';
import { store } from '../../../../../App';
import { message } from 'antd';

export const socket = io.connect(process.env.REACT_APP_BASE_API_URL);

socket.on('connect', () => {
    console.log("Socket connected");
    store.dispatch(forumOperations.setSocketConnectionStatus(true));
});

socket.on('disconnect', (data) => {
    console.log("Socket disconnected");
    store.dispatch(forumOperations.setSocketConnectionStatus(false));
});

socket.on("new_message", (data) => {
    console.log("New message received: ", data);
    data.forum = data.forum || data.messageKey.split('/#/')[0];
    data.userId = data.userId || data.guserId;
    store.dispatch(forumOperations.addMessageToForum(data.forum, data));
});

socket.on("delete-message", data => {
    console.log("A message was deleted", data);
    store.dispatch(forumOperations.deleteMessageFromForum(data.forum, data.messageId));
});

export { forumTypes, forumOperations, forumSelectors };

export default reducer;
