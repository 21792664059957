
import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import {getObjectByIdFromArray} from '../../../../../../Util';
import PropTypes from 'prop-types';
import ViewOpportunity from "../ViewOpportunity";

/**
 * Wrapper component for displaying details for other opportunity
 * This wrapper component uses ViewOpportunity component
 * @class
 * @name ViewDueTodayOpportunity
 * @version 0.1.0
 * @since 0.1.0
 * */
class ViewDueTodayOpportunity extends Component {
    state = { currentIndex: -1,
        opportunitiesArray: [],
        activeOpportunity: {},
        interestGroup: {},
        canGoNext: false,
        canGoPrevious: false
    };

    static propTypes = {
        dueTodayOpportunities: PropTypes.array,
        isAuthenticated: PropTypes.bool,
        isLogin: PropTypes.bool,
        activeInterest: PropTypes.object,
        match: PropTypes.object
    };

    /**
     * @function
     * @name setInitialState
     * @description initialize state for viewOpportunity component by setting the initial selected opportunity, array
     * of opportunities belonging to the same interest and the index of the active opportunity
     * @version 0.1.0
     * @since 0.1.0
     * */
    setInitialState = () => {
        const {match: {params}, dueTodayOpportunities, activeInterest } = this.props;
        const { opportunityId} = params;
        const opportunitiesArray = dueTodayOpportunities;
        const interestGroup = activeInterest;
        const  clickedOpportunity = getObjectByIdFromArray(dueTodayOpportunities, opportunityId);
        const currentIndex = opportunitiesArray.indexOf(clickedOpportunity);
        const activeOpportunity = opportunitiesArray[currentIndex];
        const canGoNext = this.canNext(currentIndex, opportunitiesArray);
        const canGoPrevious = this.canPrev(currentIndex);
        this.setState({
                currentIndex,
                opportunitiesArray,
                activeOpportunity,
                interestGroup,
                canGoNext,
                canGoPrevious
            }
        );
    };

    /**
     * @function
     * @name onNext
     * @description increments the index of current opportunity
     * @version 0.1.0
     * @since 0.1.0
     * */
    onNext = () => {
        const { currentIndex, opportunitiesArray } = this.state;
        const newIndex = currentIndex + 1;
        const nextOpportunity = opportunitiesArray[newIndex];
        const canGoNext = this.canNext(newIndex, opportunitiesArray);
        const canGoPrevious = this.canPrev(newIndex);
        if (newIndex < opportunitiesArray.length) {
            this.setState({
                    currentIndex: newIndex,
                    activeOpportunity: nextOpportunity,
                    canGoNext,
                    canGoPrevious
                }
            );
        }
    };

    /**
     * @function
     * @name canNext
     * @description checks if we have reached the end of the array
     * @version 0.1.0
     * @since 0.1.0
     * */
    canNext = (currentIndex, opportunitiesArray) => {
        return (currentIndex + 1) < opportunitiesArray.length;
    };



    /**
     * @function
     * @name onPrev
     * @description decrements the index of current opportunity
     * @version 0.1.0
     * @since 0.1.0
     * */
    onPrev = () => {
        const { currentIndex, opportunitiesArray } = this.state;
        const newIndex = currentIndex - 1;
        const nextOpportunity = opportunitiesArray[newIndex];
        const canGoNext = this.canNext(newIndex, opportunitiesArray);
        const canGoPrevious = this.canPrev(newIndex);
        if (newIndex > -1) this.setState({
                currentIndex: newIndex,
                activeOpportunity: nextOpportunity,
                canGoNext,
                canGoPrevious
            }
        );
    };


    /**
     * @function
     * @name canPrev
     * @description checks if we have reached the the beginning of the array
     * @param {number} currentIndex - index of the current opportunity
     * @version 0.1.0
     * @since 0.1.0
     * */
    canPrev = (currentIndex) => {
        return (currentIndex -1) > -1;
    };


    /**
     * @function
     * @name onGoBack
     * @description decrements the index of current opportunity
     * @version 0.1.0
     * @since 0.1.0
     * */
    onGoBack = () => {
        const { currentIndex } = this.state;
        const { history } = this.props;
        if (currentIndex === 0) {
            history.go(-1);
        }
        else {
            history.go(-currentIndex);
        }

    };

    componentDidMount() {
        this.setInitialState();
    }

    render() {
        const { isAuthenticated, isLogin} = this.props;
        const {
            activeOpportunity,
            opportunitiesArray,
            interestGroup,
            canGoNext,
            canGoPrevious

        } = this.state;
        return opportunitiesArray.length > 0 ? <ViewOpportunity
            opportunity={activeOpportunity}
            activeInterest={interestGroup}
            isAuthenticated={isAuthenticated}
            isLogin={isLogin}
            onGoBack={this.onGoBack}
            onPrev={this.onPrev}
            onNext={this.onNext}
            canPrev={this.canPrev}
            canNext={this.canNext}
            canGoNext={canGoNext}
            canGoPrevious={canGoPrevious}
        />: null
    }
}

export default withRouter(ViewDueTodayOpportunity);
