import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import ToolBar from "../../../components/ToolBar";
import CustomTabs from "../../../components/Tabs";

import MainForum from "./components/MainForum";
import ForumsForUser from "./components/ForumsForUser";
import OtherForums from "./components/OtherForums";

import ForumDetailScreen from './components/ForumDetailScreen';

import './styles.css';

/**
 * Base component for viewing forums
 * @function
 * @name Forums
 *
 * @version 0.1.0
 * @since 0.1.0
 */
function Forums(props){
    const { match: { url }, activeTab } = props;
    const color = "#e65d57";

    function renderCurrentForumScreen(){
        switch (activeTab) {
            case "Main Forum":
                return <MainForum />
            case "Your Forums":
                return <ForumsForUser />
            case "Other Forums":
                return <OtherForums />
            default:
                return <MainForum />
        }
    }
    
    return (
        <div className="Forums">
            <div className="flower-deco"/>

            <ToolBar 
                isAuthenticated={props.isAuthenticated} 
                isLogin={props.isLogin} 
                theme={color} />

            <CustomTabs
                activeTab={activeTab}
                tabOptions={['Main Forum', 'Your Forums', 'Other Forums']}
                handleOnClick={activeTab => props.setActiveTab(activeTab)}
                theme={color}
                url={url}
            >
                { renderCurrentForumScreen() }
            </CustomTabs>

            <Route path={`${url}/detail/:forumId`} 
                component={ForumDetailScreen}
            />
        </div>
    );
}

export default Forums;

Forums.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isLogin: PropTypes.bool,
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
};