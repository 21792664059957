import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ForumsForUser from './ForumsForUser';
import {forumOperations, forumSelectors} from '../../duck';

const mapStateToProps = state => ({
    fetchingForums: forumSelectors.getUserForumsLoading(state),
    fetchForumsError: forumSelectors.getUserForumsError(state),
    forums: forumSelectors.getUserForums(state),
});

const mapDispatchToProps = {
    fetchForums: forumOperations.getUserForums,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForumsForUser));