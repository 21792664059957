import React, {useState, useEffect} from 'react';
import workerize from 'workerize';
import useS3Uploader from "../../../../../../../hooks/useS3Uploader";

import ForumMessageComposer from '../ForumMessageComposer';
import { AuthenticatedUserToolBar } from '../../../../../../../components/ToolBar';
import ActionButton from '../../../../../../../components/ActionButton';

import './styles.css';

/**
 * Upload forum chat image files to S3 bucket
 * @function
 * @name ForumImageMessageUpload
 *
 * @version 0.1.0
 * @since 0.1.0
 */
function ForumImageMessageUpload(props){
    const { location, history, onSendMessage } = props;
    const [ previewLoaded, setPreviewLoaded ] = useState(null);
    
    const { uploadedFileUrl, uploadPercent, upload } = useS3Uploader({dirName: 'images'});

    const [ imagePreview, setImagePreview ] = useState(null);
    const [ imageDimensions, setImageDimensions ] = useState(null);

    useEffect(() => {
        const handleIncomingImage = () => {
            const { state: { image } } = location;

            if(image){
                processImage(image);
                uploadImage(image);
            }
        }

        const processImage = async ( image ) => {
            let worker = workerize(`
                export function load(file) {
                    return new Promise((resolve, reject) => {
                        var reader = new FileReader();
                        reader.onload = function(e) {
                            resolve(e.target.result);
                        }
                        reader.readAsDataURL(file);
                    });
                }
            `);
            const src = await worker.load(image);
            setImagePreview(src);
        }
    
        const uploadImage = async (image) => {
            if(!image) return setImagePreview(null);
            upload(image);
        };

        handleIncomingImage();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const closeUploader = () => {
        return (
            <ActionButton
                onClick={() => history.goBack()}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </ActionButton>
        );
    }

    function handleSendMessage(message){
        onSendMessage(message, 'image', JSON.stringify({...imageDimensions, url: uploadedFileUrl}));
        history.goBack();
    }

    return (
        <div id="ForumImageMessageUpload" className='position-fixed pin-all z10'>
            <div className="content position-relative d-flex flex-col ">
                <AuthenticatedUserToolBar 
                    theme="#e65d57"
                    isLogin={true}
                    title="Upload Image"
                    renderActions={closeUploader}
                />

                <div id="uploadedImagePreview" className="flex position-relative d-flex flex-col center-center">
                    { uploadPercent !== null &&
                        <div id="uploadProgressIndicator" className="position-absolute pin-all d-flex center-center flex-col z10">
                            <span>
                                uploading...{parseInt(uploadPercent)}%
                            </span>

                            <div style={{width: `${uploadPercent}%`}}></div>
                        </div>
                    }

                    { !previewLoaded && <div id="previewPlaceHolder" className="position-absolute" style={{width: '90%', height: '50%', backgroundColor: "#ccc", margin: "auto"}} /> }

                    <img id="imagePreview" className="position-absolute z-10"
                        src={imagePreview} alt=""
                        onLoad={(e) => {setImageDimensions({width: e.target.naturalWidth, height: e.target.naturalHeight}); setPreviewLoaded(true)}}
                        style={{opacity: previewLoaded ? 1 : 0}}/>
                </div>

                <ForumMessageComposer
                    placeholder="Add caption for image"
                    canSend={previewLoaded && uploadedFileUrl !== null}
                    onSendMessage={!previewLoaded || uploadPercent ? null : handleSendMessage} />
            </div>
        </div>
    );
}

export default ForumImageMessageUpload;