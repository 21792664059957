import { connect } from 'react-redux';
import Forums from './Forums';
import {appSelectors} from '../../../duck';
import {forumOperations, forumSelectors} from './duck';

const mapStateToProps = state => ({
    isAuthenticated: appSelectors.getIsAuthenticatedValue(state),
    isLogin: appSelectors.getIsLoginValue(state),
    activeTab: forumSelectors.getActiveTab(state),
});

const mapDispatchToProps = {
    setActiveTab: forumOperations.setActiveTab
};

export default connect(mapStateToProps, mapDispatchToProps)(Forums);