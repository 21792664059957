import { appSelectors } from "../../../../duck";
import * as API from "../../../../../API";
import * as actions from "./actions";

/**
 * an operation that gets all countries
 * @function
 * @name getAllCountries
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAllCountries = () => (dispatch, getState) => {
    const state = getState();
    const token = appSelectors.getToken(state);

    dispatch(actions.setCountriesFetchingState("fetching"));
    dispatch(actions.setRegions(null));
    dispatch(actions.setDistricts(null));

    return  API.getCountries(token)
        .then( ({ data }) => {
            dispatch(actions.setCountriesFetchingState("idle"));
            dispatch(actions.setCountries(data.countries));
        })
        .catch( err => {
            dispatch(actions.setCountriesFetchingState("error"));
            console.log(err);
        });
};


/**
 * an operation that gets all regions by country
 * @function
 * @name getRegions
 * @param countryId {String} - Id of the country
 * @version 0.1.0
 * @since 0.1.0
 */
export const getRegions = (countryId) => (dispatch, getState) => {
    const state = getState();
    const token = appSelectors.getToken(state);
    
    dispatch(actions.setRegions(null));
    dispatch(actions.setDistricts(null));

    if(!countryId || !countryId.length)
        return;

    dispatch(actions.setRegionsFetchingState("fetching"));

    return API.getRegionsByCountry(countryId, token)
        .then( ({ data }) => {
            dispatch(actions.setRegionsFetchingState("idle"));
            dispatch(actions.setRegions(data.regions));
        })
        .catch( err => {
            dispatch(actions.setRegionsFetchingState("error"));
            console.log(err);
        });
};


/**
 * an operation that gets all districts by region
 * @function
 * @name getDistricts
 * @param regionId {String} - Id of the region
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDistricts = (regionId) => (dispatch, getState) => {
    const state = getState();
    const token = appSelectors.getToken(state);
    
    dispatch(actions.setDistricts(null));

    if(!regionId || !regionId.length)
        return;

    dispatch(actions.setDistrictsFetchingState("fetching"));
    return API.getDistrictsByRegion(regionId, token)
        .then(({ data }) => {
            dispatch(actions.setDistrictsFetchingState("idle"));
            dispatch(actions.setDistricts(data.districts));
        })
        .catch( err => {
            dispatch(actions.setDistrictsFetchingState("error"));
            console.log(err);
        });
};