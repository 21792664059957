import { connect } from "react-redux";
import MainApp from "./MainApp";
import { appSelectors, appOperations } from "../duck";

const mapStateToProps = (state) => ({
  notifications: appSelectors.notifications(state),
  fetchingNotifications: appSelectors.notificationsLoading(state),
  currentUser: appSelectors.getAuthUser(state),
});

const mapDispatchToProps = {
  fetchNotifications: appOperations.getNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
