import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../../../../components/Loader';

import ForumDetail from '../ForumDetail';

import './styles.css';

/**
 * Component for viewing chat in the main forum
 * @function
 * @name MainForum
 *
 * @version 0.1.0
 * @since 0.1.0
 */
function MainForum({forums, fetchingForums, fetchForumsError, forum, setCurrentForumId, fetchForums}){
    useEffect(() => {
        if(!forums && !fetchingForums && !fetchForumsError)
            fetchForums();
    }, [forums, fetchingForums, fetchForumsError, fetchForums]);

    useEffect(() => {
        if(forum && forum._id)
            setCurrentForumId(forum._id);
    }, [forum, setCurrentForumId]);

    return (
        <div id="MainForum">
            { !forum && 
                <div className="d-flex center-center" style={{padding: '1.5em'}}>
                    <Loader />
                </div> 
            }
            { forum && <ForumDetail forum={forum} fullScreen={false} /> }
        </div>
    );
}

export default MainForum;

MainForum.propTypes = {
    forum: PropTypes.object
};