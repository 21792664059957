import { combineReducers } from 'redux';
import { default as initReducers } from './duck';
import { default as pickYourNicknameReducers } from './Onboarding/Screens/PickNickName/duck';
import { default as userDetailsReducers } from './Onboarding/Screens/UserDetails/duck';
import { default as opportunitiesReducers } from './MainApp/Screens/Opportunities/duck';
import { default as forumReducers } from './MainApp/Screens/Forums/duck';
import { default as learningLabReducers } from './MainApp/Screens/LearningLab/duck';

export default combineReducers({
    ...initReducers, 
    ...pickYourNicknameReducers, 
    ...userDetailsReducers,
    ...opportunitiesReducers,
    ...forumReducers,
    ...learningLabReducers
});