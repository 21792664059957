import { connect } from 'react-redux';
import LearningLabSummary from './LearningLabSummary';
import {learningLabSelectors, learningLabOperations} from '../../../../duck';
import { appSelectors } from '../../../../../../../duck';

const mapStateToProps = state => ({
    authUser: appSelectors.getAuthUser(state),
    learningLabSummary: learningLabSelectors.getSummary(state),
    learningLabSummaryLoading: learningLabSelectors.getSummaryLoading(state),
    activeTab: learningLabSelectors.getActiveTab(state),
});

const mapDispatchToProps = {
    fetchLearningLabSummary: learningLabOperations.fetchLearningLabSummary
};

export default connect(mapStateToProps, mapDispatchToProps)(LearningLabSummary);