import React from "react";
import PropTypes from 'prop-types';
import CustomButton from '../../../../../components/Button';

import "./styles.css";

/**
 * Component that renders a forum card
 * @function
 * @name Forum
 * @param {Object} props - react props
 * @returns {null}
 * @version 0.1.0
 * @since 0.1.0
 * */
function Forum({forum, buttonText, onButtonClick, onClick}) {
    const { image, title, leaving} = forum;
    return (
        <div className="ForumCard layout vertical">
            <div className="flex" onClick={onClick}>
                <div className="image">
                    <img src={image} alt="Forum cover" />
                </div>
                <h3>{title}</h3>
            </div>
            <CustomButton
                loading={leaving}
                size="small"
                color="#e0493f" 
                handleOnClick={onButtonClick}>
                { buttonText }
            </CustomButton>
        </div>
    );
}

export default Forum;

Forum.propTypes = {
    forum: PropTypes.object,
    onButtonClick: PropTypes.func, 
    onClick: PropTypes.func
};

Forum.defaultProps = {
    onButtonClick: () => {}, 
    onClick: () => {}
};