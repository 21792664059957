import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import ValidationText from "./components/ValidationText";
import InputIcon from "./components/InputIcon";

const getBorderCss = (success) => {
    if (success === null) {
        return {border: '1px solid rgb(149, 149, 149)'};
    } else {
        if (success) {
            return {border: '1px solid #22b573'};
        } else {
            return {border: '1px solid #c1272d'};
        }
    }
};

class CustomInput extends Component {

    constructor(props) {
        super(props);
        this.customInput = React.createRef();
        this.state = {
            showClearIcon: false
        };
    }

    handleOnChange = ({target}) => {
        const {value} = target;
        const showClearIcon = value.length > 0;
        this.setState({ showClearIcon })
        this.props.onCustomInputChange(value);

    };

    clearCustomInput = () => {
        this.customInput.current.value = "";
        this.setState({ showClearIcon: false })
    };

    render() {
        const { type="text", value, placeholder, label, centerAligned, success, clearable = false } = this.props;
        const border = getBorderCss(success);
        const centerAlignment = centerAligned ? {textAlign: 'center'} : {};
        const { showClearIcon } = this.state;
        return (
            <div className={`CustomInput ${type}`}>
                { label && label.length && 
                    <label>{label}</label>
                }
                <div className="input-and-clear-icon">
                    <input
                        type={type}
                        style={{...border, ...centerAlignment}}
                        placeholder={placeholder}
                        value={value}
                        onChange={this.handleOnChange}
                        ref={this.customInput}
                    />
                    
                    { type === 'search' && 
                        <span className="icon-search">
                            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        </span>
                    }

                    {
                        clearable && showClearIcon ?
                            <InputIcon
                                success={this.props.success}
                                clear={this.clearCustomInput}
                            />
                            : null
                    }
                </div>
                <ValidationText
                    validationMessage={this.props.validationMessage}
                    success={this.props.success}
                />
            </div>
        );
    }
}


export default CustomInput;

CustomInput.propTypes = {
    success: PropTypes.bool,
    centerAligned: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onCustomInputChange: PropTypes.func,
    validationMessage: PropTypes.string
};

CustomInput.defaultProps ={
    success: null,
    onCustomInputChange: () => {}
};