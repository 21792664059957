import React, { useState } from 'react';

const MediaImage = ({pending, image}) => {
    let url = image, width = "auto", height = "auto";
    try {
        image = JSON.parse(image);
        url = image.url;
        width = image.width;
        height = image.height;
    } catch (_) {}

    const [mediaLoading, setMediaLoading] = useState(true);

    return ( 
        <div className="media position-relative">
            <img src={url} alt=""
                onLoad={() => setMediaLoading(false)} />
            { (pending || mediaLoading) &&
                <svg id="mediaUploadingIndicator" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><circle cx="50" cy="50" fill="none" stroke="#888888" strokeWidth="5" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(311.844 50 50)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></circle></svg>
            }
        </div>
    );
}
 
export default MediaImage;