import React from "react";
import PropTypes from "prop-types";
import './styles.css';

function NavItem({ isActive, icon, label, onClick, ...otherProps }) {
    let className = "nav-item ";
    className += otherProps.className;
    className += isActive ? ' active' : '';

    return (
        <div className={className} onClick={onClick}>
            { icon }
        </div>
    )
}

export default NavItem;

NavItem.propTypes = {
    isActive: PropTypes.bool,
    url: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func
};