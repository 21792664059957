import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TabButton from "./components/Button";

import './style.css';

const lightenDarkenColor = (col, amt=-20) =>  {
    var usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col,16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
};

function CustomTabs (props) {
    const { activeTab, tabOptions, theme } = props;
    const darkColor = lightenDarkenColor(theme);

    const handleOnClick = (_e, activeTab) => {
        const {handleOnClick, history} = props;
        handleOnClick(activeTab);
        history.push(`${props.url}`);
    };

    const showTabsNavBar = (options, activeTab) => {
        return options.map((option) =>
            <TabButton
                key={option._id || option}
                id={option._id || option}
                theme={props.theme}
                activeTab={activeTab._id || activeTab}
                value={option.name || option}
                handleOnClick={handleOnClick}
            />
        );
    };

    return (
        <div className="CustomTabs" style={{backgroundColor: `${darkColor}`}}>
            <div>
                <div className="tabs-button-group">
                    {showTabsNavBar(tabOptions, activeTab)}
                </div>
            </div>
            <div className="contents">
                {props.children}
            </div>
        </div>
    );
}

export default withRouter(CustomTabs);

CustomTabs.propTypes = {
    activeTab: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    tabOptions: PropTypes.array,
    handleOnClick: PropTypes.func,
    theme: PropTypes.string,
    url: PropTypes.string,
};