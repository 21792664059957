import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import Loader from "../../../../../components/Loader";
import AlertDialog from "../../../../../components/FlNotifications/AlertDialog";

import Forum from "../Forum";

import JoinForumPreview from "./components/JoinForumPreview";
import LeaveForum from "./components/LeaveForum";

import "./styles.css";

/**
 * Component for viewing forums a user hasn't joined
 * @function
 * @name OtherForums
 *
 * @version 0.1.0
 * @since 0.1.0
 */
function OtherForums({
  match,
  fetchingForums,
  fetchForumsError,
  forums,
  history,
  fetchForums,
  fetchOtherForums,
}) {
  useEffect(() => {
    if (!forums && !fetchingForums && !fetchForumsError) fetchForums();

    fetchOtherForums();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { url } = match;
  console.log("Launched");
  return (
    <div className="OtherForums">
      {fetchingForums && (
        <div style={{ padding: "2em 0" }} className="d-flex center-center">
          <Loader size={80} thickness={4} color="#e0493f" />
        </div>
      )}

      {!fetchingForums && forums && forums.length > 0 && (
        <div className="d-flex justify-between flex-wrap">
          {forums.map((forum) => (
            <Forum
              key={forum._id}
              forum={forum}
              buttonText="Join Forum"
              onClick={() => history.push(`${url}/previewForum/${forum._id}`)}
              onButtonClick={() =>
                history.push(`${url}/previewForum/${forum._id}`)
              }
            />
          ))}
        </div>
      )}

      {!fetchingForums && (!forums || !forums.length) && !fetchForumsError && (
        <span
          style={{ padding: "1em", lineHeight: "1.5em", fontSize: "1.5em" }}
          className="d-flex center-center text-center"
        >
          There are no forums that you can join.
        </span>
      )}

      {!fetchingForums && (!forums || !forums.length) && fetchForumsError && (
        <span
          style={{ padding: "1em", lineHeight: "1.5em", fontSize: "1.5em" }}
          className="d-flex center-center text-center"
        >
          Failed to fetch forums, please check your internet and try again.
        </span>
      )}

      <Route
        exact
        path={`${url}/previewForum/:forumId`}
        component={(routeProps) => <JoinForumPreview {...routeProps} />}
      />

      <Route
        exact
        path={`${url}/maxLimitReached`}
        component={() => (
          <AlertDialog
            okText={"Leave a forum"}
            cancelText={"Cancel"}
            onOkay={() => history.replace(`${url}/leaveForum`)}
            onCancel={() => history.goBack()}
          >
            <h2>Maximum forums is five</h2>
            <p>
              If you really want to join this forum, you can leave another
              forum.
            </p>
          </AlertDialog>
        )}
      />

      <Route path={`${url}/leaveForum`} component={() => <LeaveForum />} />
    </div>
  );
}

export default OtherForums;

OtherForums.propTypes = {
  fetchingForums: PropTypes.bool,
  forums: PropTypes.array,
  fetchForums: PropTypes.func,
  setPreviewedForumId: PropTypes.func,
};
