import React from 'react';

import ashuraSearchEmpty from "../../../../../../../../images/ashura-search-empty.png";

import LearningLabSummarySection from '../Summary/LearningLabSummarySection';

const NoSearchResults = ({history, data, type, setType}) => {
    const bossUp = data.videos;
    const readToLead = data.articles;
    const coolImagesMeme = data.imageMemes;

    return (
        <div id="noSearchResults">
            <div id="noResultsMessage" className="d-flex">
                <div>
                    <img src={ashuraSearchEmpty} alt=""/>
                </div>
                <div className="flex items-center">
                    <h2 className="brand-color-pinkish-text">
                        Ooops!
                    </h2>
                    <p>
                        No results found, here are some things you might be interested in.
                    </p>
                </div>
            </div>

            <h2 className="brand-color-pinkish-text">
                Check these out!
            </h2>

            <div className="d-flex flex-wrap">
                { (type === "Boss Up" || type === "All") && (
                    <LearningLabSummarySection
                        history={history}
                        title={type === "Boss Up" ? null : "Boss Up"}
                        themeColor="#e65d57"
                        wrapItems
                        items={bossUp}
                        onViewAllClick={() => setType("Boss Up")}
                    />
                )}

                { (type === "Read to Lead" || type === "All") && (
                    <LearningLabSummarySection
                        history={history} 
                        title={type === "Read to Lead" ? null : "Read to Lead"}
                        themeColor="#e65d57"
                        wrapItems
                        items={readToLead}
                        onViewAllClick={() => setType("Read to Lead")}
                    />
                )}
            
                { (type === "Cool Images & Memes" || type === "All") && (
                    <LearningLabSummarySection
                        history={history} 
                        title={type === "Cool Images & Memes" ? null : "Cool Images & Memes"}
                        themeColor="#e65d57"
                        wrapItems
                        items={coolImagesMeme}
                        onViewAllClick={() => setType("Cool Images & Memes")}
                    />
                )}
            </div>
        </div>
    );
}
 
export default NoSearchResults;