import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Loader from "../../../../../components/Loader";

import Forum from '../Forum';

import './styles.css';

/**
 * Component for viewing user's joined forums
 * @function
 * @name ForumsForUser
 *
 * @version 0.1.0
 * @since 0.1.0
 */
function ForumsForUser({fetchForums, fetchingForums, fetchForumsError, forums, ...props}){
    const { match: { url }, history } = props;

    useEffect(() => {
        if(!forums && !fetchingForums && !fetchForumsError)
            fetchForums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ForumsForUser">
            { fetchingForums && 
                <div style={{padding: '2em 0'}} className="d-flex center-center">
                    <Loader size={80} thickness={4} color="#e0493f" />
                </div> 
            }
            
            { !fetchingForums && forums && forums.length > 0 &&
                <div className="d-flex justify-between flex-wrap">
                    {
                        forums.map(forum => (
                            <Forum key={forum._id} 
                                forum={forum} 
                                buttonText="Go To Forum"
                                onClick={() => history.push(`${url}/detail/${forum._id}`)}
                                onButtonClick={() => history.push(`${url}/detail/${forum._id}`)} />
                        ))
                    }
                </div>
            }
            { !fetchingForums && (!forums || !forums.length) && !fetchForumsError &&
                <span style={{padding: '1em', lineHeight: '1.5em', fontSize: '1.5em'}} className="d-flex center-center text-center">
                    You have not joined any forums yet.
                </span>
            }
            
            { !fetchingForums && (!forums || !forums.length) && fetchForumsError &&
                <span style={{padding: '1em', lineHeight: '1.5em', fontSize: '1.5em'}} className="d-flex center-center text-center">
                    Failed to fetch forums, please check your internet and try again.
                </span>
            }
        </div>
    );
}

export default ForumsForUser;

ForumsForUser.propTypes = {
    fetchForums: PropTypes.func, 
    fetchingForums: PropTypes.bool, 
    forums: PropTypes.array
};