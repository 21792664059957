import * as types from './types';

const initial = {
    all: {
        "loading": false,
        "opportunities": [],
        "totalCount": null,
        "page": 0,
        "error": null
    },
    dueToday: {
        "opportunities": [],
        "totalCount": null,
        "page": 0,
        "loading": false
    },
    other: {
        "loading": false,
        "opportunities": [],
        "error": null
    },
    current: {
        listName: null,
        id: null
    }
};

/**
 *
 * @function
 * @name opp
 * @param {Object} state - Initial state
 * @param {Object} action - Redux action object
 * @version 0.1.0
 * @since 0.1.0
 */

export const opp = (state = initial, action) => {
    switch (action.type) {
        case types.GET_ALL_OPPORTUNITIES_START:{
            const all = {...state.all, loading: true};
            return {...state, all};
        }
        case types.GET_ALL_OPPORTUNITIES_SUCCESS:{
            const all = {...action.payload, loading: false, error: null};
            return {...state, all};
        }
        case types.GET_ALL_OPPORTUNITIES_ERROR:{
            const all = {...state.all, error: action.payload, loading: false};
            return {...state, all};
        }

        case types.GET_ALL_DUE_TODAY_OPPORTUNITIES_START:{
            const dueToday = {...state.dueToday, loading: true};
            return {...state, dueToday};
        }
        case types.GET_ALL_DUE_TODAY_OPPORTUNITIES_SUCCESS:
            return {...state, dueToday: action.payload, loading: false, error: null};
        case types.GET_ALL_DUE_TODAY_OPPORTUNITIES_ERROR:{
            const dueToday = {...state.dueToday, error: action.payload, loading: true};
            return {...state, dueToday};
        }
        
        case types.GET_OTHER_OPPORTUNITIES_START:{
            const other = {...state.other, loading: true};
            return {...state, other};
        }
        case types.GET_OTHER_OPPORTUNITIES_SUCCESS:{
            const other = {...state.other, opportunities: action.payload, loading: false, error: null};
            return {...state, other};
        }
        case types.GET_OTHER_OPPORTUNITIES_ERROR:{
            const other = {...state.other, error: action.payload, loading: false};
            return {...state, other};
        }

        case types.SET_OPPORTUNITY_DETAIL: {
            const {opportunityId, listName} = action.payload;
            const current = {id: opportunityId, listName};
            return {...state, current};
        }
        case types.UNSET_OPPORTUNITY_DETAIL:
            return {...state, current: initial.current};
        default:
            return state;
    }
};
