import { connect } from 'react-redux';
import UserDetails from './UserDetails';
import { appOperations } from '../../../duck';
import { userDetailsSelectors, userDetailsOperations } from './duck';

const mapDispatchToProps = {
    addUserDemographics: appOperations.addUserDemographics,
    getAllCountries: userDetailsOperations.getAllCountries,
    getRegions: userDetailsOperations.getRegions,
    getDistricts: userDetailsOperations.getDistricts
};

const mapStateToProps = state => ({
    savingDetails: state.pageLoading,
    isAuthenticated: state.isAuthenticated,
    countries: userDetailsSelectors.getCountriesSelector(state),
    countriesFetchState: userDetailsSelectors.getCountriesFetchState(state),
    regions: userDetailsSelectors.getRegionsSelector(state),
    regionsFetchState: userDetailsSelectors.getRegionsFetchState(state),
    districts: userDetailsSelectors.getDistrictsSelector(state),
    districtsFetchState: userDetailsSelectors.getDistrictsFetchState(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);