import { connect } from 'react-redux';
import {opportunitiesOperations, opportunitiesSelectors} from "../../../../duck";
import DueTodayOpportunities from "./DueTodayOpportunities";


const mapDispatchToProps = {
    getOpportunities: opportunitiesOperations.getAllOpportunities,
};

const mapStateToProps = state => ({
    allOpportunities: opportunitiesSelectors.getAllDueTodayOpportunities(state),
    totalOpportunities: opportunitiesSelectors.getTotalDueTodayOpportunitiesCount(state),
    loading: opportunitiesSelectors.getAllDueTodayOpportunitiesLoading(state),
    activeInterest: opportunitiesSelectors.getActiveInterest(state),
    currentPage: opportunitiesSelectors.getDueTodayOpportunitiesCurrentPage(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(DueTodayOpportunities);
