import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types'

function RedirectToLogin(props){
    localStorage.setItem("REDIRECT_FROM", JSON.stringify(props.location));

    return <Redirect
        to={{ pathname: '/login', state: { from: props.location } }} // eslint-disable-line
    />
}

const SecureRoute = ({ component: Component, isAuthenticated, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated 
                    ?  <Component {...props} />
                    : (
                        <RedirectToLogin { ...props } />
                    )
            }
        />
    );
};


export default SecureRoute;

SecureRoute.propTypes = {
    isAuthenticated: PropTypes.bool
};