import React from 'react';
import  { Link } from 'react-router-dom';

const ModalLink = ({to, children, ...props}) => {
    return ( 
        <Link {...props}
            to={{
                pathname: to,
                state: { modal: true }
            }}>
            { children }
        </Link>
    );
}
 
export default ModalLink;