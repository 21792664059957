import { connect } from 'react-redux';
import Anonymity from './Anonymity';
import { appOperations } from '../../../duck';

const mapDispatchToProps = {
    addUserAnonymity: appOperations.addUserAnonymity,
};

const mapStateToProps = state => ({
    savingAnonymity: state.pageLoading,
    isAuthenticated: state.isAuthenticated
});

export default connect(mapStateToProps, mapDispatchToProps)(Anonymity);