
import * as types from './types';


export const setNickNameStart = (nickName) => ({ 
    type: types.SET_NICKNAME_START ,
    payload: nickName
});
export const setNickNameEmpty = () => ({ type: types.SET_NICKNAME_EMPTY });

export const setNickNameSuccess = (nickName) => ({
    type: types.SET_NICKNAME_SUCCESS,
    payload: nickName
});

export const setNickNameFailed = error => ({
    type: types.SET_NICKNAME_ERROR,
    payload: error
});



export const setEmailStart = () => ({ type: types.SET_EMAIL_START });
export const setEmailEmpty = () => ({ type: types.SET_EMAIL_EMPTY });

export const setEmailSuccess = (nickName) => ({
    type: types.SET_EMAIL_SUCCESS,
    payload: nickName
});

export const setEmailFailed = error => ({
    type: types.SET_EMAIL_ERROR,
    payload: error
});
