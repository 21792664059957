import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import JoinForumPreview from './JoinForumPreview';
import {forumOperations, forumSelectors} from '../../../../duck';

const mapStateToProps = state => ({
    forum: forumSelectors.getPreviewedForum(state)
});

const mapDispatchToProps = {
    setPreviewedForumId: forumOperations.setPreviewedForumId,
    joinForum: forumOperations.joinForum
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JoinForumPreview));