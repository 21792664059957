
import React from 'react';
import OffLineIcon from '../../../images/offline-icon.svg';
import './index.css';

function ComingSoon() {
    return (
        <div className="ComingSoon">
        <img src={OffLineIcon} alt="Coming soon"/>
            <h1>Coming soon</h1>
    </div>
);
}

export default ComingSoon;