import axios from "axios";

/**
 * Initialize axios library
 *
 * Add headers to HTTP requests that sent to the server
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    authKey:
      "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMDt0ZXN0MDEiLCJpYXQiOjE1NjMzNzUwMjUsImV4cCI6MTU2MzM4MTAyNX0.q95P_ILyD7ya2bS_qDAeiFftg1QxjLZ8Pb6wdIFWNkBU15GT_9CXo05tZHcwjYXdBJBH7zNQ2YgWeGitEgNh5Q",
  },
});

const headers = {
  "Content-Type": "application/json",
  "Afcs-Code": "TOM310",
  authKey:
    "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMDt0ZXN0MDEiLCJpYXQiOjE1NjMzNzUwMjUsImV4cCI6MTU2MzM4MTAyNX0.q95P_ILyD7ya2bS_qDAeiFftg1QxjLZ8Pb6wdIFWNkBU15GT_9CXo05tZHcwjYXdBJBH7zNQ2YgWeGitEgNh5Q",
};

/**
 *sends access token to the server
 *
 * @function
 * @name sendToken
 * @param token {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const sendToken = (token) => {
  return Axios.post(`/app/token`, token).then((res) => res.data);
};

/**
 *checks username availability
 *
 * @function
 * @name checkUsername
 * @param userName {String} - username
 * @version 0.1.0
 * @since 0.1.0
 */
export const checkUsername = (userName) => {
  return Axios.get(`/app/userName`, { params: { userName: userName } }).then(
    (res) => res.data
  );
};

/**
 * @function
 * @name checkUsername
 * @description checks username availability
 * @param email {String} - email
 * @version 0.1.0
 * @since 0.1.0
 */
export const checkEmail = (email) => {
  return Axios.get(`/app/email-check`, { params: { email: email } }).then(
    (res) => res.data
  );
};

/**
 * deactivates a user
 *
 * @function
 * @name deactivateUser
 * @param user {Object} - user object
 * @version 0.1.0
 * @since 0.1.0
 */
export const deactivateUser = (payload, token) => {
  return Axios.post("/app/user/deactivate-account", payload, {
    headers: { Authorization: `Bearer ${token}` },
  }).then((res) => res.data);
};

/**
 * reactivates a user
 *
 * @function
 * @name reactivateUser
 * @param user {Object} - user object
 * @version 0.1.0
 * @since 0.1.0
 */
export const reactivateUser = (token, id) => {
  return Axios.patch("/app/user/reactivate-account/" + id, null, {
    headers: { Authorization: `Bearer ${token}` },
  }).then((res) => res.data);
};

/**
 * creates a new user
 *
 * @function
 * @name createUser
 * @param user {Object} - user object
 * @version 0.1.0
 * @since 0.1.0
 */

export const createUser = (user) => {
  return Axios.post(`/app/user/create`, user).then((res) => res.data);
};

/**
 * updates a user
 *
 * @function
 * @name updateUser
 * @param user {Object} - user object
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const updateUser = (user, token) => {
  return Axios.post(`/app/user/update`, user, {
    headers: { Authorization: `Bearer ${token}` },
  }).then((res) => res.data);
};

/**
 * adds demographic information to a an existing user
 *
 * @function
 * @name createDemographics
 * @param demographics {Object} - user demographics
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const createDemographics = (demographics, accessToken) => {
  return Axios.post(`/app/demographic`, demographics, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);
};

/**
 * updates user demographics details
 *
 * @function
 * @name updateDemographics
 * @param data {Object} - Demographics data object
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const updateDemographics = (data, token) => {
  return Axios.put("/app/users/demographic/update", data, {
    headers: { Authorization: `Bearer ${token}` },
  }).then((res) => res.data);
};

/**
 * adds interests to existing user
 *
 * @function
 * @name createUserInterests
 * @param interests {Object} - user interests
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const createUserInterests = (interests, accessToken) => {
  return Axios.post(`/app/interests`, interests, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);
};

/**
 * sends a value that sets user profile private
 *  or public
 *
 * @function
 * @name createUserDream
 * @param anonymity {Object} - user interests
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const createUserAnonymity = (anonymity, accessToken) => {
  return Axios.post(`/app/users/anonymity`, anonymity, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);
};

/**
 * creates user dream
 *
 * @function
 * @name createUserDream
 * @param dream {Object} - user dream
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const createUserDream = (dream, accessToken) => {
  return Axios.post(`/app/users/dreams`, dream, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);
};

/**
 * updates user dream
 *
 * @function
 * @name updateUserDream
 * @param dream {Object} - user dream
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const updateUserDream = (dream, accessToken) => {
  return Axios.put(`/app/users/dreams`, dream, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);
};

/**
 * get a list of countries
 *
 * @function
 * @name getCountries
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getCountries = (accessToken) =>
  Axios.get("/app/countries", {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * get a list of regions by country
 *
 * @function
 * @name getRegionsByCountry
 * @param countryId {String} - id of the country
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getRegionsByCountry = (countryId, accessToken) =>
  Axios.get(`/app/${countryId}/country/regions`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * get a list of districts by region
 *
 * @function
 * @name getDistrictsByRegion
 * @param regionId {String} - id of the region
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDistrictsByRegion = (regionId, accessToken) =>
  Axios.get(`/app/${regionId}/regions/districts`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * get a list of interests
 *
 * @function
 * @name getInterests
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getInterests = (accessToken) =>
  Axios.get(`/app/interests`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * @function
 * @name getAllOpportunitiesByInterest
 * @description get a list of opportunities based on interest
 * @param interestId {String} - id of the interest
 * @param page {Number} - page number
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAllOpportunitiesByInterest = (
  { interestId, page = 1 },
  accessToken
) =>
  Axios.get(`/opportunities/${interestId}/interest-recently?p=${page}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * @function
 * @name getOpportunityDetails
 * @description get detail of a single opportunity given it's id
 * @param opportunityId {String} - id of the interest
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getOpportunityById = (
  opportunityId, accessToken
) =>
  Axios.get(`/opportunities/${opportunityId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * @function
 * @name getDueTodayOpportunitiesByInterest
 * @description get a list of due today opportunities based on interest
 * @param interestId {String} - id of the interest
 * @param page {Number} - page number
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDueTodayOpportunitiesByInterest = (
  { interestId, page = 1 },
  accessToken
) =>
  Axios.get(`/opportunities/${interestId}/interests/due`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * get user feedback
 *
 * @function
 * @name collectFeedback
 * @param feedback {String} - feedback value
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const collectFeedback = (feedback, accessToken) => {
  return Axios.post(`/help-guide/response`, feedback, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);
};

/**
 * creates a new opportunity
 *
 * @function
 * @name createOpportunity
 * @param opportunity {String} - new opportunity
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const createOpportunity = (opportunity, accessToken) => {
  return Axios.post(`/app/opportunities/girl-child`, opportunity, {
    headers: { ...headers, Authorization: `Bearer ${accessToken}` },
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

/**
 * gets other interest opportunities
 *
 * @function
 * @name getOtherInterestsOpportunities
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getOtherInterestsOpportunities = (accessToken) =>
  Axios.get(`/opportunities/interests/others`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * gets a user's notifications
 *
 * @function
 * @name getUserNotifications
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getUserNotifications = async (page, accessToken) =>
  Axios.get(`/notifications/girl-child?p=${page}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * sets a notification's read status
 *
 * @function
 * @name setNotificationReadStatus
 * @param notificationId {String} - id of the notification
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const setNotificationReadStatus = async (notificationId, accessToken) =>
  Axios.post(`/notifications/girl-child/${notificationId}/mark-status`, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
* sets a notification's read status
*
* @function
* @name setAllNotificationReadStatus
* @param accessToken {String} - access token
* @version 0.1.0
* @since 0.1.0
*/
export const setAllNotificationReadStatus = async (accessToken) =>
  Axios.put(`notifications/girl-child/all-read`, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * gets a user's forums
 *
 * @function
 * @name getUserForums
 * @param page {int} - page being fetched
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getUserForums = async (page, accessToken) =>
  Axios.get(`/app/forums/girl/?p=${page}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then((res) => res.data)
    .catch((err) => console.log(err));

/**
 * gets other forums
 *
 * @function
 * @name getUserForums
 * @param page {int} - page being fetched
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getOtherForums = async (page, accessToken) =>
  Axios.get(`/app/forums/?p=${page}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * subscribes a user to a forum
 *
 * @function
 * @name subscribeToForum
 * @param forum {Object} - forum being subscribed to
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const subscribeToForum = async (forum, accessToken) =>
  Axios.post("/app/forums/subscribe", forum, {
    headers: { ...headers, Authorization: `Bearer ${accessToken}` },
  });

/**
 * unsubscribes a user from a forum
 *
 * @function
 * @name unsubscribeFromForum
 * @param subscription {Object} - subscription object for forum being unsubscribed from
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const unsubscribeFromForum = async (subscription, accessToken) =>
  Axios.post("/app/forums/un-subscribe", subscription, {
    headers: { ...headers, Authorization: `Bearer ${accessToken}` },
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });

/**
 * gets a forum's messages
 *
 * @function
 * @name getForumMessages
 * @param forumId {int} - the forum id
 * @param page {int} - page being fetched
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getForumMessages = async (forumId, page, accessToken) =>
  Axios.get(`/app/forums/${forumId}/chats?p=${page}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * deletes a forum messages
 *
 * @function
 * @name deleteForumMessage
 * @param payload {Object} - forum id and message id for message
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteForumMessage = async (payload, accessToken) =>
  Axios.delete("/forum/message/", {
    data: payload,
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * deletes a forum messages
 *
 * @function
 * @name sendForumMessage
 * @param payload {Object} - details of message being sent
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const sendForumMessage = async (payload, accessToken) =>
  Axios.post("/forum/message/", payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * save learning lab interest
 *
 * @function
 * @name saveLearningLabInterests
 * @param payload {Object} - the learning lab interests
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const saveLearningLabInterests = (payload, accessToken) =>
  Axios.post("/app/profile/learningLab/", payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
// Axios.post('/app/profile/learningLab/add', payload, {"headers" : { 'Authorization': `Bearer ${accessToken}`}})

/**
 * get a list of learning lab interests
 *
 * @function
 * @name getLearningLabInterests
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getLearningLabInterests = (accessToken) =>
  Axios.get(`/learningLab`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * get a summary of of learning lab contents
 *
 * @function
 * @name getLearningLabSummary
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getLearningLabSummary = (accessToken, categoryId) =>
  Axios.get(`/app/learningLab/${categoryId}/home`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data.data);

/**
 * get a summary of of trending learning lab contents
 *
 * @function
 * @name getLearningLabTrendingSummary
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getLearningLabTrendingSummary = (accessToken) =>
  Axios.get("/app/learningLab/trending", {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data.data);

/**
 * get a list of learning lab contents by category
 *
 * @function
 * @name getLearningLabContents
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getLearningLabContents = (accessToken, categoryId, type, page) =>
  Axios.get(`/app/learningLab/${categoryId}/all?type=${type}&page=${page}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data.data);

/**
 * search learning lab contents
 *
 * @function
 * @name searchLearningLabContents
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const searchLearningLabContents = (accessToken, query) =>
  Axios.get(`/app/learningLab/contents/search?query=${query}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data);

/**
 * record learning lab item view count
 *
 * @function
 * @name recordLearningLabViewCount
 * @param accessToken {String} - access token
 * @param itemId {String} - id of viewed item
 * @version 0.1.0
 * @since 0.1.0
 */
export const recordLearningLabViewCount = (accessToken, itemId) =>
  Axios.get(`/app/learningLab/contents/${itemId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data.data);

/**
 * toggle like for learning lab item
 *
 * @function
 * @name likeLearningLabItem
 * @param accessToken {String} - access token
 * @param itemId {String} - id of liked item
 * @version 0.1.0
 * @since 0.1.0
 */
export const likeLearningLabItem = (accessToken, itemId) =>
  Axios.post(`/app/learningLab/contents/${itemId}/like`, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data.data);

/**
 * toggle share for learning lab item
 *
 * @function
 * @name shareLearningLabItem
 * @param accessToken {String} - access token
 * @param itemId {String} - id of shared item
 * @version 0.1.0
 * @since 0.1.0
 */
export const shareLearningLabItem = (accessToken, itemId) =>
  Axios.post(`/app/learningLab/contents/${itemId}/share`, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then((res) => res.data.data);

/**
 * gets a auth user's based on category
 *
 * @function
 * @name getArticles
 * @param categoryId {int} - the forum id
 * @param page {int} - page being fetched
 * @param accessToken {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getArticles = async (categoryId, page, accessToken) => ({
  then: (resolve) => setTimeout(resolve, 2000),
});

// export const getArticles = async (categoryId, page, accessToken) =>
//     Axios.get(`/app/articles/${categoryId}?p=${page}`, {"headers" : { 'Authorization': `Bearer ${accessToken}`}})
//         .then(res => res.data);
