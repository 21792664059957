import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Proptypes from "prop-types";
import Auth from "./components/Auth";
import ToolBar from "../components/ToolBar";
import Loader from "../components/Loader";
import "./styles.css";

function Login({ isAuthenticated, history, ...props }) {
  useEffect(() => {
    if (isAuthenticated) history.replace("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const facebookResponse = (res) => {
    const email = res.email ? res.email : null;
    const { accessToken } = res;

    const data = { provider: "facebook", email, token: accessToken };
    props.signin(data, history);
  };

  const googleResponse = (res) => {
    const email = res.profileObj.email;
    const { tokenId } = res;
    const data = { provider: "google", email, token: tokenId };
    props.signin(data, history);
  };

  return (
    <div className="Login login-background-image">
      <div className="container overlay">
        <ToolBar isAuthenticated={false} />

        <div className="welcome-text">
          <div className="large-page-title greetings brand-color-pinkish-text">
            <span>Hello</span> <span>Beautiful,</span>
          </div>
          <div className="large-page-subtitle">Login to continue</div>
        </div>

        <Auth
          facebookResponse={facebookResponse}
          googleResponse={googleResponse}
        />

        {props.authenticating && <Loader fill size={100} />}
      </div>
    </div>
  );
}

export default withRouter(Login);

Login.propTypes = {
  history: Proptypes.object,
  isAuthenticated: Proptypes.bool,
  authenticating: Proptypes.bool,
  signin: Proptypes.func.isRequired,
};
