import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';

import ToolBar from "../../../components/ToolBar";
import CustomTextArea from "../../../components/TextArea";
import CustomButton from "../../../components/Button";
import WriteDream from './WriteDream';

import './styles.css';

const Dream = (props) => {
    const [dream, setDream] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        props.addUserDream({dream}, props.history);
    };

    const handleOnChange = (e) => {
        setDream(e.target.value);
    };

    const handleInputClicked = () => {
        const { match:{ url }, history } = props;
        history.push(`${url}/write`);
    }

    const { match:{ url }, location } = props;
    const transitions = useTransition(location, location => location.key, {
        from: {transform: 'translateY(60%)'},
        enter: {transform: 'translateY(0)'},
        leave: () => ({opacity: 0}),
        config: {duration: 200}
    });
        
    return (
        <React.Fragment>
            <div className="Dream">
                <div className="flower-deco"/>
                <ToolBar isAuthenticated={true}/>
                <div className="contents">
                    <div className="welcome-text">
                        <h2 style={{fontSize: '1.4em'}}>Woohooo <span>{props.nickName || 'Ashura'}</span>!</h2>
                        <small>
                            One last thing and you're done
                        </small>
                        <div>Tell us, what's your dream?</div>
                    </div>
                    <form className="dream-form"
                        onSubmit={handleSubmit}>
                        <div onClick={handleInputClicked}>
                            <CustomTextArea 
                                placeholder="Write your dream here"
                                handleOnChange={handleOnChange}
                            />
                        </div>

                        <div className="dream-text">
                            A dream is the biggest <strong>ambition</strong> and <strong>desire </strong>
                            that you have for <strong>yourself</strong>.
                        </div>
                        <div className="dream-example">
                            Here is an example from another girl.
                            <div className="d-flex">
                                <div className="dp">

                                </div>
                                <div className="flex">
                                    My dream is to open an NGO and get children off the streets.
                                    <strong>- Sandra Jumanne</strong>
                                </div>
                            </div>
                        </div>
                        <div>
                            <CustomButton
                                loading={props.savingDream}
                                type="submit"
                                width="100%"
                                color="#e0493f" >
                                continue
                            </CustomButton>
                        </div>
                    </form>
                </div>
            </div>

            { transitions.map(({item, transitionStyles, key}) => (
                <animated.div className="position-fixed pin-all z10" key={key} style={{...transitionStyles, pointerEvents: 'none'}}>
                    <Switch location={location}>
                        <Route exact path={`${url}/`} render={() => <div />} />
                        <Route path={`${url}/write`} render={() => <WriteDream submit={(dream) => props.addUserDream({dream}, props.history)} saving={props.savingDream}  />} />
                    </Switch>
                </animated.div>
            )) }
        </React.Fragment>
    );
}
 
export default withRouter(Dream);

Dream.propTypes = {
    history: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    addUserDream: PropTypes.func,
    nickName: PropTypes.string,
};