import React from "react";
import * as actions from "./actions";
import * as API from "../../../../../API";
import { appSelectors } from "../../../../duck";
import { socket, forumSelectors } from ".";
import { message } from "antd";

export const setActiveTab = actions.setActiveTab;
export const setSocketConnectionStatus = actions.setSocketConnectionStatus;
export const setPreviewedForumId = actions.setPreviewedForumId;
export const setCurrentForumId = actions.setCurrentForumId;
export const addMessageToForum = actions.addMessageToForum;
export const deleteMessageFromForum = actions.deleteMessageFromForum;

/**
 * an operation that gets forums that a user has joined
 * @function
 * @name getUserForums
 * @version 0.1.0
 * @since 0.1.0
 */
export const getUserForums = (page = 1) => async (dispatch, getState) => {
  const state = getState();
  const token = appSelectors.getToken(state);

  dispatch(actions.getUserForumsStart());

  try {
    const response = await API.getUserForums(page, token);
    const res = response.data;
    console.log(res);
    dispatch(actions.getUserForumsSuccess(res));
  } catch (error) {
    console.log(error);
    dispatch(actions.getUserForumsError(error));
  }
};

/**
 * an operation that gets forums that a user hasn't joined
 * @function
 * @name getOtherForums
 * @version 0.1.0
 * @since 0.1.0
 */
export const getOtherForums = (page = 1) => (dispatch, getState) => {
  const state = getState();
  const token = appSelectors.getToken(state);

  dispatch(actions.getOtherForumsStart());

  API.getOtherForums(page, token)
    .then((res) => {
      let responseData = res.data;
      console.log(responseData);
      dispatch(actions.getOtherForumsSuccess(responseData));
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.getOtherForumsError(err));
    });
};

/**
 * an operation that subscribes a user to a forum given forumId
 * @function
 * @name joinForum
 * @version 0.1.0
 * @since 0.1.0
 */
export const joinForum = (forumId, history) => async (dispatch, getState) => {
  const state = getState();
  const token = appSelectors.getToken(state);

  dispatch(actions.setOtherForumDetails(forumId, { joining: true }));

  API.subscribeToForum({ forum: forumId }, token)
    .then((response) => {
      const data = response.data.data;
      const subscriptionId = data._id;
      dispatch(
        actions.setOtherForumDetails(forumId, {
          joining: false,
          subscriptionId,
        })
      );
      dispatch(actions.subscribeToForum(forumId));
      const socketConnectionIsSet = forumSelectors.getSocketConnectionStatus(
        state
      );

      if (socketConnectionIsSet) {
        socket.emit("join-forum", { forum: forumId });
      }
      history.replace(`/main/forums/detail/${forumId}`);
    })
    .catch((err) => {
      dispatch(actions.setOtherForumDetails(forumId, { joining: false }));
      const response = err.response;
      if (
        response &&
        response.data &&
        response.data.message.includes("Limit Exceeded")
      )
        history.replace("/main/forums/maxLimitReached");
      else {
        const alert = {
          title: "Joining forum failed",
          message: "Make sure you have an internet connection and try again.",
        };
        history.push("/alertDialog", { alert, modal: true });
      }
    });
};

/**
 * an operation that unsubscribes a user from a forum given forumId
 * @function
 * @name leaveForum
 * @version 0.1.0
 * @since 0.1.0
 */
export const leaveForum = (forumId, history) => async (dispatch, getState) => {
  const state = getState();
  const token = appSelectors.getToken(state);
  const forum = forumSelectors.getUserForumById(state, forumId);

  dispatch(actions.setUserForumDetails(forumId, { leaving: true }));

  API.unsubscribeFromForum({ subscriptionId: forum.subscriptionId }, token)
    .then((_) => {
      dispatch(
        actions.setUserForumDetails(forumId, {
          leaving: false,
          subscriptionId: undefined,
        })
      );
      dispatch(actions.unsubscribeFromForum(forumId));
      history.replace("/main/forums");

      if (forum && forum.title)
        message.success(
          <span>
            Unsubscribed from <strong>{forum.title}</strong>
          </span>
        );
      else message.success("Unsubscribed successfully");
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.setUserForumDetails(forumId, { leaving: false }));
      const alert = {
        title: "Leaving forum failed",
        message: "Make sure you have an internet connection and try again.",
      };
      history.push("/alertDialog", { alert, modal: true });
    });
};

/**
 * an operation that gets messages for a given forum
 * @function
 * @name getForumMessages
 * @version 0.1.0
 * @since 0.1.0
 */
export const getForumMessages = (forumId, page = 1) => (dispatch, getState) => {
  const state = getState();
  const token = appSelectors.getToken(state);

  dispatch(actions.setUserForumDetails(forumId, { fetchingMessages: true }));

  API.getForumMessages(forumId, page, token)
    .then((res) => {
      let messages = res.data;
      dispatch(
        actions.setUserForumDetails(forumId, {
          messagesFetched: true,
          fetchingMessages: false,
          messages,
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.setUserForumDetails(forumId, { fetchingMessages: false })
      );
    });
};

/**
 * an operation that sends messages to a given forum
 * @function
 * @name fetchForumMessages
 * @version 0.1.0
 * @since 0.1.0
 */
export const fetchForumMessages = (forumId, page = 1) => (
  dispatch,
  getState
) => {
  const state = getState();
  const token = appSelectors.getToken(state);

  dispatch(actions.setUserForumDetails(forumId, { fetchingMessages: true }));

  API.getForumMessages(forumId, page, token)
    .then((response) => {
      dispatch(
        actions.setUserForumDetails(forumId, {
          fetchingMessages: false,
          messagesFetched: true,
        })
      );
      dispatch(actions.forumMessagesFetched(forumId, response.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        actions.setUserForumDetails(forumId, { fetchingMessages: false })
      );
    });
};

/**
 * an operation that sends messages to a given forum
 * @function
 * @name sendForumMessage
 * @version 0.1.0
 * @since 0.1.0
 */
export const sendForumMessage = (messagePayload) => (dispatch, getState) => {
  const state = getState();
  const token = appSelectors.getToken(state);
  const { forum, messageKey } = messagePayload;

  dispatch(
    actions.addMessageToForum(forum, { ...messagePayload, pending: true })
  );
  API.sendForumMessage(messagePayload, token)
    .then((response) => {
      console.log("Message response: ", response);
      dispatch(
        actions.updateMessageDetails(forum, messagePayload.messageKey, {
          ...messagePayload,
          _id: response.data._id,
          pending: false,
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(actions.deleteMessageFromForum(forum, messageKey));
      message.error("Failed to send message");
    });
};

/**
 * an operation that deletes a messages from a forum
 * @function
 * @name deleteForumMessage
 * @version 0.1.0
 * @since 0.1.0
 */
export const deleteForumMessage = (messagePayload) => (dispatch, getState) => {
    const state = getState();
    const token = appSelectors.getToken(state);
    const {forum, messageId} = messagePayload;
    
    dispatch(actions.updateMessageDetails(forum, messageId, {deleting: true}));
    API.deleteForumMessage(messagePayload, token)
        .then(response => {
            dispatch(actions.deleteMessageFromForum(forum, messageId));
            message.success('Message deleted');
        })
        .catch(err => {
            console.log(err);
            dispatch(actions.updateMessageDetails(forum, messageId, {deleting: false}));
            message.error('Message couldn\'t be deleted');
        });
};

/**
 * an operation that forwards a message payload to one or forums
 * @function
 * @name forwardForumMessage
 * @version 0.1.0
 * @since 0.1.0
 */
export const forwardForumMessage = (
  messagePayload,
  recepientForums,
  history
) => (dispatch, getState) => {
  const state = getState();
  const socketConnectionIsSet = forumSelectors.getSocketConnectionStatus(state);

  if (socketConnectionIsSet) {
    recepientForums.forEach((forum) => {
      const editedMessagePayload = { ...messagePayload, forum: forum._id };
      dispatch(
        actions.addMessageToForum(forum, {
          ...editedMessagePayload,
          pending: true,
        })
      );
      socket.emit("new_message", editedMessagePayload);
    });

    if (recepientForums.length === 1) {
      const { _id, type } = recepientForums[0];

      if (type === "main-forum") {
        dispatch(actions.setActiveTab("Main Forum"));
        history.replace("/main/forums");
      } else history.replace(`/main/forums/detail/${_id}`);
    } else {
      message.success("Message forwarded");
      history.goBack();
    }
  } else {
    history.goBack();
    message.error("Message wasn't forwarded");
    console.log("Can't forward message, not connected to socket");
  }
};
