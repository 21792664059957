import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NewOpportunity from './NewOpportunity';
import NewOpportunityDraftsInterceptor from './NewOpportunityDraftsInterceptor';
import { appSelectors, appOperations } from '../../../../../duck'

const NewOpportunityContainer = (props) => (
    <Switch>
        <Route path={props.match.url + "/drafts"}
            render={(routeProps) => (
                <NewOpportunityDraftsInterceptor {...props} {...routeProps} />
            )}
        />
        
        <Route exact path={props.match.url + "/edit"}
            render={(routeProps) => (
                <NewOpportunity {...props} {...routeProps} />
            )}
        />

        <Redirect to={props.match.url + "/drafts"} />
    </Switch>
);

const mapDispatchToProps = {
    createOpportunity: appOperations.createOpportunityOpearation,
    getInterests: appOperations.getAllInterests,
};

const mapStateToProps = state => ({
    isAuthenticated: appSelectors.getIsAuthenticatedValue(state),
    isLogin: appSelectors.getIsLoginValue(state),
    interests: appSelectors.getInterestsSelector(state),
    isOpportunityLoading: appSelectors.loadingOpportunitySelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOpportunityContainer);