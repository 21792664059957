
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {Pagination} from "antd";
import Opportunity from "../Opportunity";
import Loader from "../../../../../../../components/Loader";
import './styles.css';


/**
 * Component that renders all opportunities
 * @class
 * @name AllInterestOpportunities
 *
 * @version 0.1.0
 * @since 0.1.0
 */
class AllInterestOpportunities extends Component {

    renderOpportunities = (opportunities, url) =>
        opportunities.map(({resourceImg, title, deadlineDate, _id}) =>
            <Opportunity
                key={_id}
                url={`${url.replace('view/', '')}/${_id}`}
                imageUrl={resourceImg}
                opportunityTitle={title}
                date={deadlineDate}
                id={_id}
            />);

    componentDidMount() {
        const { match: {params}, getOpportunities } = this.props;
        getOpportunities(params.interestId);
    }

    render() {
        const {
            match: {
                url,
                params
            },
            allOpportunities,
            getOpportunities,
            totalOpportunities,
            activeInterest,
            currentPage,
            loading
        } = this.props;
        const renderLoader = () => (<div style={{padding: '2em 0'}} className="d-flex center-center">
            <Loader size={80} thickness={4} color={activeInterest.color} />
        </div>);
        return loading ?
            renderLoader():
            (
            <div className="AllInterestOpportunities">
                { activeInterest.currentOtherChildInterest &&
                    <div className="bread-crumb">
                        <Link to="/opportunities/view">More</Link> 
                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                        <span style={{color:  activeInterest.currentOtherChildInterest.color}}>
                            { activeInterest.currentOtherChildInterest.title }
                        </span>
                    </div>
                }
                <section>
                    <div className="section-contents">
                        {this.renderOpportunities(allOpportunities, url)}
                    </div>
                </section>

                <section className="opportunities-pagination">
                    <Pagination
                        size="small"
                        total={totalOpportunities}
                        current={currentPage}
                        hideOnSinglePage={true}
                        onChange={(page) => getOpportunities(params.interestId, page)}
                    />
                </section>

            </div>
        );

    }

}
export default AllInterestOpportunities;

AllInterestOpportunities.propTypes = {
    match: PropTypes.object,
    activeInterest: PropTypes.object,
    theme: PropTypes.string,
    loading: PropTypes.bool,
    currentPage: PropTypes.number,
    totalOpportunities: PropTypes.number,
    allOpportunities: PropTypes.array
};
