import * as types from './types';

/**
 * initiate fetching of all opportunities
 * @function
 * @name getAllOpportunitiesStart
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getAllOpportunitiesStart = () => ({
    type: types.GET_ALL_OPPORTUNITIES_START
});

/**
 * report success of fetching of all opportunities
 * @function
 * @name getAllOpportunitiesSuccess
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getAllOpportunitiesSuccess = opportunities => ({
    type: types.GET_ALL_OPPORTUNITIES_SUCCESS,
    payload: opportunities
});

/**
 * report error of fetching of all opportunities
 * @function
 * @name getAllOpportunitiesError
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getAllOpportunitiesError = error => ({
    type: types.GET_ALL_OPPORTUNITIES_ERROR,
    payload: error
});




/**
 * @function
 * @name getAllDueTodayOpportunitiesStart
 * @description initiate fetching of all due today opportunities
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getAllDueTodayOpportunitiesStart = () => ({
    type: types.GET_ALL_DUE_TODAY_OPPORTUNITIES_START
});

/**
 * @function
 * @name getAllDueTodayOpportunitiesSuccess
 * @description report success of fetching of all due today opportunities
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getAllDueTodayOpportunitiesSuccess = opportunities => ({
    type: types.GET_ALL_DUE_TODAY_OPPORTUNITIES_SUCCESS,
    payload: opportunities
});

/**
 * @function
 * @name getAllDueTodayOpportunitiesError
 * @description report error of fetching of all due today opportunities
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getAllDueTodayOpportunitiesError = error => ({
    type: types.GET_ALL_DUE_TODAY_OPPORTUNITIES_ERROR,
    payload: error
});




/**
 * initiate fetching of other opportunities
 * @function
 * @name getOtherOpportunitiesStart
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getOtherOpportunitiesStart = () => ({
    type: types.GET_OTHER_OPPORTUNITIES_START
});

/**
 * report success of fetching of other opportunities
 * @function
 * @name getOtherOpportunitiesSuccess
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getOtherOpportunitiesSuccess = opportunities => ({
    type: types.GET_OTHER_OPPORTUNITIES_SUCCESS,
    payload: opportunities
});

/**
 * report error of fetching of other opportunities
 * @function
 * @name getOtherOpportunitiesError
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getOtherOpportunitiesError = error => ({
    type: types.GET_ALL_OPPORTUNITIES_ERROR,
    payload: error
});

/**
 * set selected opportunity
 * @function
 * @name setSelectedOpportunity
 * @param {string} listName - Opportunity parent list name
 * @param {string} opportunityId - Opportunity Id
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const setSelectedOpportunity = (listName, opportunityId) => ({
    type: types.SET_OPPORTUNITY_DETAIL,
    payload: {listName, opportunityId}
});

/**
 * unset selected opportunity
 * @function
 * @name unsetSelectedOpportunity
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const unsetSelectedOpportunity = () => ({
    type: types.UNSET_OPPORTUNITY_DETAIL
});
