import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CustomButton from "../../../../../components/Button";
import CustomInput from "../../../../../components/Input";

import './styles.css';

const generateValidationMsg = (emailIsValid, emailExists) => {
    if (emailExists)
        return 'Email already exists!';
    
    return emailIsValid ? '' : 'Invalid email';
};

function EmailForm({emailIsValid, emailExists, onEmailChanged, onSubmit}){
    const [submittingEmail, setSubmittingEmail] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmittingEmail(true);
        setTimeout(() => {
            setSubmittingEmail(false);
            onSubmit(e);
        }, 400);
    }

    const validationMessage = generateValidationMsg(emailIsValid, emailExists);

    return (
        <div className="contents">
            <div className="welcome-text">
                <div className="large-page-title brand-color-pinkish-text">Hello <span>Mrembo,</span></div>
                <div className="large-page-subtitle">
                    Add your email to continue.
                </div>
            </div>

            <form className="PickYourUsernameForm"
                onSubmit={handleSubmit}>
                    
                <CustomInput
                    placeholder="Your email address"
                    success={!emailExists && emailIsValid}
                    validationMessage={validationMessage}
                    onCustomInputChange={onEmailChanged}
                />

                <CustomButton
                    type="submit"
                    width="100%"
                    loading={submittingEmail}
                    disabled={emailExists || !emailIsValid}
                    color="#e0493f"
                >
                    Continue
                </CustomButton>

            </form>
        </div>
    );
}

export default EmailForm;

EmailForm.propTypes = {
    checkNickNameAvailability: PropTypes.func.isRequired,
    onEmailChanged: PropTypes.func,
    handleSubmit: PropTypes.func
};