import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import ToolBar from "../../../../../components/ToolBar";
import CustomTabs from "../../../../../components/Tabs";

import LearningLabSummary from './Screens/Summary';
import LearningLabList from './Screens/All';
// import LearningLabDetail from './Screens/Detail';
import LearningLabSearch from './Screens/Search';

import './styles.css';

function LearningLabMain(props){
    const { match: { url }, authUser, authToken, activeTab, setActiveTab } = props;
    const color = activeTab ? activeTab.color : "#AC8CD6";

    function articleTabs(){
        if(!authUser || !authUser.learningLabs || !authUser.learningLabs.length)
            return [];
            //     'Trending', 'Your LearningLab', 'Other LearningLab'
            // ];
        else
            return [
                {
                    _id: 'trending',
                    color: "#AC8CD6",
                    name: 'Trending'
                },
                ...authUser.learningLabs
            ];
    }

    function handleSetActiveTab(tabId){
        if(authUser.learningLabs){
            let tab = articleTabs().find(({_id}) => _id === tabId);
            setActiveTab(tab);   
        }
    }

    // useEffect(() => {
    //     if(authUser && authUser.learningLabs && activeTab._id === "trending"){
    //         setActiveTab(authUser.learningLabs[0]);
    //     }
    // }, []);
    
    return (
        <div id="LearningLabMain">
            <div className="flower-deco"/>

            <ToolBar 
                isAuthenticated={true} 
                isLogin={true} 
                theme={color} />

            <CustomTabs
                activeTab={activeTab ? activeTab : {}}
                tabOptions={articleTabs()}
                handleOnClick={handleSetActiveTab}
                theme={color}
                url={url}
            >
                <Switch>
                    <Route path={`${url}/summary`} component={LearningLabSummary} />
                    <Route path={`${url}/all`} component={LearningLabList} />
                    {/* <Route path={`${url}/detail`} component={LearningLabDetail} /> */}
                    <Route path={`${url}/search`} render={(routeProps) => (
                        <LearningLabSearch {...routeProps} 
                            authUser={authUser} authToken={authToken} 
                        />
                    )} />
                    <Redirect to={`${url}/summary`} />
                </Switch>
            </CustomTabs>
        </div>
    );
}

export default LearningLabMain;

LearningLabMain.propTypes = {
    activeTab: PropTypes.object,
    setActiveTab: PropTypes.func,
    authUser: PropTypes.object,
    authToken: PropTypes.string
};