import { get, take, filter, findIndex } from 'lodash';
import { learningLabColorCodes } from '../../Util';

/**
 * gets access token from redux state object
 * @function
 * @name getToken
 * @params {Object} - redux state
 * @returns  {String} - access token
 * @version 0.1.0
 * @since 0.1.0
 */
export const getToken = state => get(state, "user.data.token", null);

/**
 * gets status for hideBottomNav from redux state object
 * @function
 * @name getHideBottomNavStatus
 * @params {Object} - redux state
 * @returns  {String} - hideBottomNav status
 * @version 0.1.0
 * @since 0.1.0
 */
export const getHideBottomNavStatus = state => get(state, "hideBottomNav");

/**
 * gets access token from redux state object
 * @function
 * @name getAuthUser
 * @params {Object} - redux state
 * @returns {Object} - authenticated user
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAuthUser = state => {
    let user = get(state, "user.data.userProfile", null);
    if(!user)
        return null;

    user.learningLabs = user.learningLabs.filter(item => item);

    return user;
};

/**
 * gets value that tells whether user is authenticated
 * @function
 * @name getIsAuthenticatedValue
 * @params {Object} - redux state
 * @returns {Boolean} - is authenticated value
 * @version 0.1.0
 * @since 0.1.0
 */
export const getIsAuthenticatedValue = state => get(state, "isAuthenticated");

/**
 * gets value that tells whether user is login
 * @function
 * @name getIsLoginValue
 * @params {Object} - redux state
 * @returns {Boolean} - is login value
 * @version 0.1.0
 * @since 0.1.0
 */
export const getIsLoginValue = state => get(state, "isAuthenticated");

/**
 * gets interests that belong to current user
 * @function
 * @name getUserInterests
 * @params {Object} - redux state
 * @returns {Array} - interests
 * @version 0.1.0
 * @since 0.1.0
 */
export const getUserInterests = state => get(state, "interests.selected");


/**
 * gets active interest
 * @function
 * @name getActiveInterest
 * @params {Object} - redux state
 * @returns {Object} - interest
 * @version 0.1.0
 * @since 0.1.0
 */
export const getActiveInterest = state => get(state, "interests.active");

/**
 * @function
 * @name getAuthToken
 * @description gets auth token object
 * @params {Object} - redux state
 * @returns {Object} - interest
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAuthToken = state => get(state, "authToken.token", null);

export const recentOpportunities = state => {
    const allOpportunities = get(state, "opportunities.all.opportunities", []);

    return take(allOpportunities, 4);
};

export const allOpportunitiesSelector = state => get(state, "opportunities.all.opportunities", []);
export const loadingOpportunitySelector = state => get(state, "opportunity.loading", false);
export const getInterestsSelector = state => get(state, "schema.interests", []);
export const summarizedDueTodayOpportunities = state => {
    const dueToday = get(state, "opportunities.dueToday.opportunities", []);
    return take(dueToday, 4);
};

export const dueTodayOpportunities = state => get(state, "opportunities.dueToday.opportunities", []);

export const selectedOpportunity = state => get(state, "opportunities.details", { prev: null, current: null, next: null });

export const notifications = state => {
    const allNotifications = get(state, "notifications.notifications", null);
    const currentFilter = state.notifications.filter.toLowerCase();

    if(!allNotifications)
        return null;

    switch (currentFilter) {
        case "unread":
            return filter(allNotifications, ['readStatus', false]);
        case "mentions":
            return filter(allNotifications, ({type}) => type.toLowerCase() === "mention");
        default:
            return allNotifications
    }
};

export const latestNotifications = state => {
    const allNotifications = get(state, "notifications.notifications", []);
    return take(allNotifications, 4);
};

export const notificationsFilter = state => get(state, "notifications.filter", "All");

export const notificationsLoading = state => get(state, "notifications.loading", false);

export const unreadNotificationsCount = state => {
    const allNotifications = get(state, "notifications.notifications", []);
    return filter(allNotifications, ['readStatus', false]).length;
}

export const allOpportunitiesLoading = state => get(state, "opportunities.all.loading", false);
export const dueTodayOpportunitiesLoading = state => get(state, "opportunities.dueToday.loading", false);

const _getCurrentOpportunityList = (state) => {
    const {id, listName} = state.opportunities.current;
    const list = state.opportunities[listName];
    if(!list)
        return {
            opportunities: [],
            totalCount: null,
            page: 0,
            loading: false,
            currentOpportunityIndex: -1
        };

    const currentOpportunityIndex = findIndex(list.opportunities, ['_id', id]);    
    return {...list, currentOpportunityIndex};
}

export const previousOpportunityId = (state) => {
    const {opportunities, currentOpportunityIndex} = _getCurrentOpportunityList(state);

    return (currentOpportunityIndex !== -1)
        && (currentOpportunityIndex !== 0)
        ? opportunities[currentOpportunityIndex - 1]._id 
        : null;
}

export const currentOpportunity = (state) => {
    const {opportunities, currentOpportunityIndex} = _getCurrentOpportunityList(state);
    
    return (currentOpportunityIndex !== -1) 
        ? opportunities[currentOpportunityIndex]
        : null;
}

export const nextOpportunityId = (state) => {
    const {opportunities, currentOpportunityIndex} = _getCurrentOpportunityList(state);

    return (currentOpportunityIndex !== -1)
        && (currentOpportunityIndex !== opportunities.length - 1)
        ? opportunities[currentOpportunityIndex + 1]._id 
        : null;
}

export const backNavigationCount = (state) => {
    const {navigationCount} = _getCurrentOpportunityList(state);    
    return navigationCount;
}

export const shouldFetchNextOpportunities = (state) => {
    const {
        opportunities, 
        currentOpportunityIndex, 
        totalCount, 
        loading
    } = _getCurrentOpportunityList(state);

    if(!opportunities)
        return false;

    const isAlmostLastItem = (opportunities.length - currentOpportunityIndex) <= 2;
    const noMoreOpportunities = opportunities.length >= totalCount;
    
    return isAlmostLastItem && !noMoreOpportunities && !loading;
}