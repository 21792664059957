import { get, take, findIndex } from 'lodash';

/** 
 * TODO: Rename state name from opp to opportunities
*/

/**
 * @function
 * @name getActiveInterest
 * @description get active interest from redux state object
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - active interest
 */
export const getActiveInterest = state => get(state, "interests.active", {});

/**
 * gets all opportunities from redux state object
 * @function
 * @name getAllOpportunities
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Array} - all opportunities
 */
export const getAllOpportunities = state => get(state, "opp.all.opportunities", []);

/**
 * @function
 * @name getRecentOpportunities
 * @description gets recent opportunities from redux state object
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Array} - recent opportunities
 */
export const getRecentOpportunities = state => {
    const allOpportunities = get(state, "opp.all.opportunities", []);
    return take(allOpportunities, 4);
};

/**
 * @function
 * @name getAllOpportunitiesLoading
 * @description gets loading state for all opportunities from redux state object
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Boolean} - loading state for all opportunities
 */
export const getAllOpportunitiesLoading = state => get(state, "opp.all.loading", );


/**
 * @function
 * @name getOtherOpportunities
 * @description gets other opportunities from redux state object
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Array} - other opportunities
 */
export const getOtherOpportunities = state => get(state, "opp.other.opportunities", []);

/**
 * @function
 * @name getOtherOpportunitiesLoading
 * @description gets loading state for other opportunities from redux state object
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Boolean} - loading state for other opportunities
 */
export const getOtherOpportunitiesLoading = state => get(state, "opp.other.loading", []);

/**
 * @function
 * @name getOpportunitiesCurrentPage
 * @description gets current pages from list of paginated opportunities
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {number} - current page
 */
 export const getOpportunitiesCurrentPage = state =>{
     const pageNUmberString =  get(state, "opp.all.page", "1");
     return parseInt(pageNUmberString);
};


/**
 * @function
 * @name getTotalOpportunitiesCount
 * @description total number of opportunities
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {number} - total number of opportunities
 */
export const getTotalOpportunitiesCount = state => get(state, "opp.all.totalCount", 0);


/**
 * @function
 * @name getsOpportunitiesTotalPageCount
 * @description total number of pages from paginated opportunities
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {number} - page count
 */
export const getsOpportunitiesTotalPageCount = state => {
    const total = getTotalOpportunitiesCount(state);
    return Math.ceil(total/10);
};




/**
 * @function
 * @name getAllDueTodayOpportunities
 * @description gets all due today opportunities from redux state object
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Array} - all opportunities
 */
export const getAllDueTodayOpportunities = state => get(state, "opp.dueToday.opportunities", []);

/**
 * @function
 * @name getSummaryOfDueTodayOpportunities
 * @description gets summary of due today opportunities from redux state object
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Array} - due today opportunities opportunities
 */
export const getSummaryOfDueTodayOpportunities = state => {
    const allOpportunities = getAllDueTodayOpportunities(state);
    return take(allOpportunities, 4);
};

/**
 * @function
 * @name getAllDueTodayOpportunitiesLoading
 * @description gets loading state for all due today opportunities from redux state object
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Boolean} - loading state for all opportunities
 */
export const getAllDueTodayOpportunitiesLoading = state => get(state, "opp.dueToday.loading");


/**
 * @function
 * @name getDueTodayOpportunitiesCurrentPage
 * @description gets current pages from list of paginated due today opportunities
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {number} - current page
 */
export const getDueTodayOpportunitiesCurrentPage = state =>{
    const pageNUmberString =  get(state, "opp.dueToday.page", "1");
    return parseInt(pageNUmberString);
};


/**
 * @function
 * @name getTotalDueTodayOpportunitiesCount
 * @description total number of due today opportunities
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {number} - total number of opportunities
 */
export const getTotalDueTodayOpportunitiesCount = state => get(state, "opp.dueToday.totalCount", 0);


/**
 * @function
 * @name getsDueTodayOpportunitiesTotalPageCount
 * @description total number of pages from paginated due today opportunities
 * @param state {Object} - redux state object
 * @version 0.1.0
 * @since 0.1.0
 * @returns {number} - page count
 */
export const getsDueTodayOpportunitiesTotalPageCount = state => {
    const total = getTotalDueTodayOpportunitiesCount(state);
    return Math.ceil(total/10);
};


const _getCurrentOpportunityList = (state) => {
    const {id, listName} = state.opp.current;
    const list = state.opp[listName];
    if(!list)
        return {
            opportunities: [],
            totalCount: null,
            page: 0,
            loading: false,
            currentOpportunityIndex: -1
        };

    const currentOpportunityIndex = findIndex(list.opportunities, ['_id', id]);    
    return {...list, currentOpportunityIndex};
}

export const previousOpportunityId = (state) => {
    const {opportunities, currentOpportunityIndex} = _getCurrentOpportunityList(state);

    return (currentOpportunityIndex !== -1)
        && (currentOpportunityIndex !== 0)
        ? opportunities[currentOpportunityIndex - 1]._id 
        : null;
}

export const currentOpportunity = (state) => {
    const {opportunities, currentOpportunityIndex} = _getCurrentOpportunityList(state);
    
    return (currentOpportunityIndex !== -1) 
        ? opportunities[currentOpportunityIndex]
        : null;
}

export const nextOpportunityId = (state) => {
    const {opportunities, currentOpportunityIndex} = _getCurrentOpportunityList(state);

    return (currentOpportunityIndex !== -1)
        && (currentOpportunityIndex !== opportunities.length - 1)
        ? opportunities[currentOpportunityIndex + 1]._id 
        : null;
}

export const backNavigationCount = (state) => {
    const {navigationCount} = _getCurrentOpportunityList(state);    
    return navigationCount;
}

export const shouldFetchNextOpportunities = (state) => {
    const {
        opportunities, 
        currentOpportunityIndex, 
        totalCount, 
        loading
    } = _getCurrentOpportunityList(state);

    if(!opportunities)
        return false;

    const isAlmostLastItem = (opportunities.length - currentOpportunityIndex) <= 2;
    const noMoreOpportunities = opportunities.length >= totalCount;
    
    return isAlmostLastItem && !noMoreOpportunities && !loading;
}
