import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import './styles.css';
function CustomButton (props){
    const defaultColor = props.primary ? "#e0493f" : "";
    const defaultWidth = props.block ? "100%" : "";

    const buttonStyles = {
        width: props.width ? props.width : defaultWidth,
        border: `1px solid ${props.color}`,
        backgroundColor: props.color ? props.color : defaultColor,
        ...props.style
    };

    let buttonClasses = props.className || "";
    buttonClasses += props.loading ? " loading" : "";
    let buttonProps = {
        className: buttonClasses,
        style: buttonStyles
    };

    if(props.href){
        return (
            <div className={'CustomButton ' + props.className}>
                <a {...buttonProps} 
                    href={props.href} 
                    target={props.internaLink ? "" : "_blank"}
                    rel="noopener noreferrer">
                    {props.children}
                </a>
            </div>
        );
    }
    else{
        buttonProps['type'] = props.type;
        buttonProps['onClick'] = props.handleOnClick;
        buttonProps['disabled'] = props.disabled;
    }
    
    return (
        <div className={'CustomButton ' + props.className}>
            <button {...buttonProps}>
                {props.children}
                { props.loading && 
                    <Loader fill size={40} /> 
                }
            </button>
        </div>
    );
}

export default CustomButton;

CustomButton.propTypes = {
    value: PropTypes.string.isRequired,
    handleOnClick: PropTypes.func,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    type: PropTypes.string,
    block: PropTypes.bool,
    primary: PropTypes.bool,
    href: PropTypes.string,
    className: PropTypes.string,
    internaLink: PropTypes.bool
};

CustomButton.defaultProps = {
    value: "",
    handleClick: () => {},
    style: {},
    disabled: false,
    loading: false,
    block: false,
    primary: false,
    type: "button",
    internaLink: false
};