import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import ToolBar from "../../../components/ToolBar";
import CustomSelect from "../../../components/Select";
import CustomButton from "../../../components/Button";
import RaisedSurface from "../../../components/RaisedSurface";
import FlatButton from '../../../components/FlatButton';

import './styles.css';

const getYears = (min=13, max=35) => {
    let currentYear = new Date().getFullYear(), years = [];
    let startYear = currentYear - max;
    let options = max - min;
    for (let i = 0; i <= options; i++) {
        years.unshift(startYear++);
    }

    return years;
};
const employmentStatusChoices = ['Not Employed', 'Self Employed', 'Employed', 'Student'];
const educationLevelChoices = ['Primary', 'Secondary (O-level)', 'High School (A-level)', 'College', 'University', 'None of the above'];
const dayChoices = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
const monthChoices = [
    {_id: '01', name: 'January'},
    {_id: '02', name: 'February'},
    {_id: '03', name: 'March'},
    {_id: '04', name: 'April'},
    {_id: '05', name: 'May'},
    {_id: '06', name: 'June'},
    {_id: '07', name: 'July'},
    {_id: '08', name: 'August'},
    {_id: '09', name: 'September'},
    {_id: '10', name: 'October'},
    {_id: '11', name: 'November'},
    {_id: '12', name: 'December'}
];
const yearChoices = getYears();
// check if all selects are filled
const areAllFieldsSelected = obj => {
    const keys = Object.keys(obj);
    for (let key of keys) {
        if (obj[key] === null || obj[key] === "") {
            return false;
        }
    }
    return true;
};

class UserDetails extends Component {
    state = {
        day: '',
        month: '',
        year: '',
        country: '',
        region: '',
        district: '',
        employmentStatus: '',
        educationLevel: ''
    };

    componentDidMount() {
        this.props.getAllCountries();
        if(this.props.inEditMode && this.props.authUser){
            this.setExistingUserData(this.props.authUser);
        }
    }
    
    componentDidUpdate() {
        const { 
            authUser, countries, 
            regions, regionsFetchState,
            districts, districtsFetchState,
        } = this.props;

        if(!authUser || !authUser.country || !countries || !countries.length)
            return;

        if(!regions && regionsFetchState !== "fetching" && !this.state.regionsFetched){
            this.setState({regionsFetched: true});
            const country = countries.find(({name}) => name === authUser.country);
            if(country)
                this.props.getRegions(country._id);

            return;
        }

        if(!authUser.region || !regions || !regions.length)
            return;

        if(!districts && districtsFetchState !== "fetching" && !this.state.districtsFetched){
            this.setState({districtsFetched: true});
            const region = regions.find(({name}) => name === authUser.region);
            if(region)
                this.props.getDistricts(region._id);
        }
    }

    setExistingUserData(user){
        let {
            dob,
            employmentStatus,
            educationLevel,
            country,
            region,
            district
        } = user;
        const dateOfBirth = new Date(dob);
        this.setState({
            day: dateOfBirth.getDay().toString().padStart(2, 0),
            month: (dateOfBirth.getMonth() + 1).toString().padStart(2, 0),
            year: dateOfBirth.getFullYear(),
            employmentStatus, 
            educationLevel,
            country,
            region,
            district
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {day, month, year, country, region, district, employmentStatus, educationLevel} = this.state;
        const dob = `${year}-${month}-${day}`;
        let payload = {dob, country, region, district, employmentStatus, educationLevel};
        
        if(this.props.inEditMode)
            this.props.onUpdate(payload);
        else
            this.props.addUserDemographics(payload, this.props.history);
    };

    handleCountryOnChange = countryName => {
        const country = this.props.countries.find(({name}) => name === countryName);
        if(country)
            this.props.getRegions(country._id);
        this.setState({country: countryName, region: '', district: ''})
    };

    handleRegionOnChange = regionName => {
        const region = this.props.regions.find(({name}) => name === regionName);
        if(region)
            this.props.getDistricts(region._id);

        this.setState({region: regionName, district: ''});
    };

    handleDistrictOnChange = district => {
        this.setState({district});
    };

    handleEmploymentStatusOnChange = employmentStatus => {
        this.setState({employmentStatus})
    };

    handleEducationLevelOnChange = educationLevel => {
        this.setState({educationLevel})
    };

    handleDayOnChange = day => {
        this.setState({day});
    };

    handleMonthOnChange = month => {
        this.setState({month});
    };

    handleYearOnChange = year => {
        this.setState({year});
    };

    render() {
        const disabled = !areAllFieldsSelected(this.state);
        const dateSelectWidth = 'auto';
        const {
            countries, 
            countriesFetchState, 
            regions, 
            regionsFetchState, 
            districts, 
            districtsFetchState,
            inEditMode,
            savingDetails,
            updatingDetails
        } = this.props;

        const countryChoices = !countries ? null : countries.map(({name}) => name);
        const regionChoices = !regions ? null : regions.map(({name}) => name);
        const districtChoices = !districts ? null : districts.map(({name}) => name);
        
        const {
            day, month, year,
            educationLevel,
            employmentStatus,
            country,
            region,
            district
        } = this.state;

        return (
            <div className="UserDetails d-flex flex-col">
                { !inEditMode &&
                    <ToolBar isAuthenticated={true} />
                }

                <div className="contents flex">
                    { !inEditMode &&
                        <div className="welcome-text">Tell us about you</div>
                    }
                    <form className="details-form"
                            onSubmit={this.handleSubmit}>
                            <div style={{display: inEditMode ? "none" : ""}}>
                                <span className="date-picker-label">Select date of birth</span>
                                <div className="date-of-birth">
                                    <CustomSelect
                                        placeholder="Day"
                                        value={day}
                                        data={dayChoices}
                                        width={dateSelectWidth}
                                        handleOnChange={this.handleDayOnChange}
                                    />
                                    <CustomSelect
                                        placeholder="Month"
                                        value={month}
                                        data={monthChoices}
                                        width={dateSelectWidth}
                                        handleOnChange={this.handleMonthOnChange}
                                    />
                                    <CustomSelect
                                        placeholder="Year"
                                        value={year}
                                        data={yearChoices}
                                        width={dateSelectWidth}
                                        handleOnChange={this.handleYearOnChange}
                                    />
                                </div>
                                <span className="make-required"/>
                            </div>

                            <div>
                                <CustomSelect
                                    label={inEditMode ? "Choose Country" : ""}
                                    placeholder="Choose country"
                                    data={countryChoices}
                                    value={country}
                                    disabled={!countries || !countries.length}
                                    loading={countriesFetchState === "fetching"}
                                    handleOnChange={this.handleCountryOnChange}
                                />
                                <span className="make-required"/>
                            </div>

                            { countriesFetchState === "error" && (
                                <p className="select-error-message">
                                    Failed to fetch countries.
                                    <FlatButton className="brand-color-pinkish-text"
                                        handleOnClick={() => this.props.getAllCountries()}> 
                                        Retry 
                                    </FlatButton>
                                </p>
                            )}

                            <div>
                                <CustomSelect
                                    label={inEditMode ? "Choose Region" : ""}
                                    placeholder="Choose region"
                                    data={regionChoices}
                                    value={region}
                                    loading={regionsFetchState === "fetching"}
                                    disabled={!regions || !regions.length}
                                    handleOnChange={this.handleRegionOnChange}
                                />
                                <span className="make-required"/>
                            </div>

                            { regionsFetchState === "error" && (
                                <p className="select-error-message">
                                    Failed to fetch regions.
                                    <FlatButton className="brand-color-pinkish-text"
                                        handleOnClick={() => this.props.getRegions(this.state.country)}> 
                                        Retry 
                                    </FlatButton>
                                </p>
                            )}

                            <div>
                                <CustomSelect
                                    label={inEditMode ? "Choose District" : ""}
                                    placeholder="Choose district"
                                    data={districtChoices}
                                    value={district}
                                    loading={districtsFetchState === "fetching"}
                                    disabled={!districts || !districts.length}
                                    handleOnChange={this.handleDistrictOnChange}
                                />
                                <span className="make-required"/>
                            </div>

                            { districtsFetchState === "error" && (
                                <p className="select-error-message">
                                    Failed to fetch districts.
                                    <FlatButton className="brand-color-pinkish-text"
                                        handleOnClick={() => this.props.getDistricts(this.state.region)}> 
                                        Retry 
                                    </FlatButton>
                                </p>
                            )}

                            <div>
                                <CustomSelect
                                    label={inEditMode ? "Choose Education Level" : ""}
                                    placeholder="Choose education level"
                                    data={educationLevelChoices}
                                    value={educationLevel}
                                    handleOnChange={this.handleEducationLevelOnChange}
                                />
                                <span className="make-required"/>
                            </div>

                            <div>
                                <CustomSelect
                                    label={inEditMode ? "Choose Employment Status" : ""}
                                    placeholder="Choose employment status"
                                    data={employmentStatusChoices}
                                    value={employmentStatus}
                                    handleOnChange={this.handleEmploymentStatusOnChange}
                                />
                                <span className="make-required"/>
                            </div>


                            { !inEditMode &&
                                <div className="info-text">
                                    <span style={{color: "#db715c"}}>RiseUp</span> is only available in Tanzania for now
                                </div>
                            }
                            
                            <RaisedSurface>
                                <CustomButton
                                    loading={savingDetails || updatingDetails}
                                    type="submit"
                                    width="100%"
                                    color="#e0493f"
                                    disabled={disabled}>
                                    Continue
                                </CustomButton>
                            </RaisedSurface>
                        </form>
                </div>
            </div>
        );
    }
}

export default withRouter(UserDetails);

UserDetails.propTypes = {
    history: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    setUserDetails: PropTypes.func,
    savingDetails: PropTypes.bool,
    addUserDemographics: PropTypes.func,
    getAllCountries: PropTypes.func,
    getRegions: PropTypes.func,
    getDistricts: PropTypes.func,
    countries: PropTypes.array,
    regions: PropTypes.array,
    districts: PropTypes.array
};