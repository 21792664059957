import React, { useState, useEffect } from 'react';
import { getLearningLabInterests } from '../../../../../../API';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';

import { AuthenticatedUserToolBar } from '../../../../../components/ToolBar';
import InterestItem from '../../../../../components/InterestItem';
import CustomButton from '../../../../../components/Button';
import AlertDialog from '../../../../../components/FlNotifications/AlertDialog';
import RaisedSurface from '../../../../../components/RaisedSurface';
import Loader from '../../../../../components/Loader';

import "./styles.css";

function EditLearningLabs({authUser, authToken, history, savingLearningLabs, saveLearningLabs}){
    const [interests, setInterests] = useState([]);
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [interestFetchState, setInterestFetchState] = useState("idle");

    useEffect(() => {
        fetchInterests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if(authUser && authUser.learningLabs)
            setSelectedInterests(authUser.learningLabs);
    }, [authUser]);

    const fetchInterests = async () => {
        setInterestFetchState("fetching");
        try {
            const res = await getLearningLabInterests(authToken);
            const interests = uniqBy(res.data, 'name');
            setInterestFetchState("idle");
            setInterests(interests);
        } catch (error) {
            setInterestFetchState("error");
        }
    }

    const handleSubmit = () => {
        saveLearningLabs(selectedInterests, history, true);
    };

    const selectInterest = (interest) => {
        setSelectedInterests([...selectedInterests, interest]);
    };

    const deselectInterest = (interest) => {
        setSelectedInterests(selectedInterests.filter(({_id}) => interest._id !== _id));
    };

    const renderInterests = () => interests.map(interest => {
        const colorCodes = {
            games: "#26ABC5",
            health: "#D78D7C",
            innovation: "#ED8089",
            enterprenuership: "#FA4C5B",
            skills: "#62D2C4",
        }

        const interestColor = interest.name && interest.name.length > 0 && colorCodes[interest.name.toLowerCase()];
        
        if(interestColor)
            interest.color = interestColor;

        const isChecked = selectedInterests.find(({_id}) => interest._id === _id) != null;

        return (
            <InterestItem
                key={interest._id}
                interest={interest}
                checked={isChecked}
                disabled={!isChecked && selectedInterests.length === 3}
                onClick={!isChecked ? selectInterest : deselectInterest}
            />
        );
    });

    return (
        <div id="EditLearningLabs" className="position-fixed pin-all z10">
            <AuthenticatedUserToolBar
                theme="#e65f58"
                title="Edit Learning Labs"
                isAuthenticated={true}
                onBack={() => history.goBack()} 
                noActions
            />

            <div id="EditLearningLabsContent">
                { interestFetchState === "fetching" && <Loader className="FetchingInterestsLoader" fill size={100} /> }

                { interestFetchState === "error" && ( 
                    <AlertDialog
                        message="Failed to fetch learning labs"
                        onOkay={fetchInterests}
                        okText="Retry"
                    /> 
                )}

                { interests.length > 0 && (
                    <div className="contents">
                        <div className="instructions-text">Pick Three(3)</div>
                        <div className="interests-form">
                            
                            { renderInterests(interests) }

                            <RaisedSurface>
                                <CustomButton
                                    width="100%"
                                    color="#e0493f"
                                    loading={savingLearningLabs}
                                    disabled={selectedInterests.length < 3}
                                    handleOnClick={handleSubmit}
                                >
                                    Save Preferences
                                </CustomButton>
                            </RaisedSurface>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EditLearningLabs;

EditLearningLabs.propTypes = {
    history: PropTypes.object,
    savingLearningLabs: PropTypes.bool,
    saveLearningLabs: PropTypes.func
};