import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

function CustomTextArea(props) {
    return (
        <div className="CustomTextArea">
            <textarea rows={props.rows} cols={props.cols}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.handleOnChange} />
        </div>
    );
}

export default CustomTextArea;

CustomTextArea.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    handleOnChange: PropTypes.func,
    rows: PropTypes.number,
    cols: PropTypes.number
};

CustomTextArea.defaultProps = {
    value: "",
    placeholder: "",
    rows: 10,
    cols: 40,
    handleOnChange: () => {}
};