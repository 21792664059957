import { connect } from 'react-redux';
import SummaryOfInterestOpportunities from "./SummaryOfInterestOpportunities";
import { appSelectors } from '../../../../../../../duck';

const mapStateToProps = state => ({
    dueTodayOpportunitiesLoading: appSelectors.dueTodayOpportunitiesLoading(state),
    allOpportunitiesLoading: appSelectors.allOpportunitiesLoading(state),
    recentOpportunities: appSelectors.recentOpportunities(state),
    dueTodayOpportunities: appSelectors.summarizedDueTodayOpportunities(state),
    otherOpportunities: state.opportunities && state.opportunities.other,
    activeInterest: state.interests && state.interests.active,
});

export default connect(mapStateToProps)(SummaryOfInterestOpportunities);
