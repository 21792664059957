import * as types from './types';

export const setBottomNavVisibility = status => ({
    type: types.SET_BOTTOM_NAV_VISIBILITY,
    payload: status
});

export const pageLoading = status => ({
    type: types.PAGE_LOADING,
    payload: status
});

export const addProfileEditField = field => ({
    type: types.ADD_PROFILE_EDIT_FIELD,
    payload: field
});

export const removeProfileEditField = field => ({
    type: types.REMOVE_PROFILE_EDIT_FIELD,
    payload: field
});

export const authStart = () => ({
    type: types.AUTH_START
});

export const authSuccess = () => ({
    type: types.AUTH_SUCCESS
});

export const loginUser = () => ({
    type: types.LOGIN_USER
});

export const signOutUser = () => ({
    type: types.LOGOUT_USER
});

export const setAuthUser = user => ({
    type: types.SET_AUTH_USER,
    payload: user
});

export const setAuthToken = token => ({
    type: types.SET_AUTH_TOKEN,
    payload: token
});

export const setDream = dream => ({
    type: types.SET_DREAM,
    payload: dream
});

export const setAnonymity = anonymity => ({
    type: types.SET_ANONYMITY,
    payload: anonymity
});

export const setCountries = countries => ({
    type: types.SET_COUNTRIES,
    payload: countries
});

export const setRegions = regions => ({
    type: types.SET_REGIONS,
    payload: regions
});

export const setOpportunities = opportunities => ({
    type: types.SET_OPPORTUNITIES,
    payload: opportunities
});

export const setSelectedInterests = interests => ({
    type: types.SET_SELECTED_INTERESTS,
    payload: interests
});

export const setActiveInterest = interest => ({
    type: types.SET_ACTIVE_INTEREST,
    payload: interest
});

export const initInterests = interests => ({
    type: types.INIT_INTERESTS,
    payload: interests
});

export const setAllOpportunities = opportunities => ({
    type: types.SET_ALL_OPPORTUNITIES,
    payload: opportunities
});

export const emptyAllOpportunities = () => ({
    type: types.EMPTY_ALL_OPPORTUNITIES
});

export const setAllOpportunitiesLoading = status => ({
    type: types.SET_ALL_OPPORTUNITIES_LOADING,
    payload: status
});

export const setDueTodayOpportunities = opportunities => ({
    type: types.SET_DUE_TODAY_OPPORTUNITIES,
    payload: opportunities
});

export const emptyDueTodayOpportunities = () => ({
    type: types.EMPTY_DUE_TODAY_OPPORTUNITIES
});

export const setDueTodayOpportunitiesLoading = status => ({
    type: types.SET_DUE_TODAY_OPPORTUNITIES_LOADING,
    payload: status
});

export const setOtherOpportunities = opportunities => ({
    type: types.SET_OTHER_OPPORTUNITIES,
    payload: opportunities
});

export const setSelectedOpportunity = (listName, opportunityId) => ({
    type: types.SET_OPPORTUNITY_DETAIL,
    payload: {listName, opportunityId}
});

export const unsetSelectedOpportunity = (listName, opportunityId) => ({
    type: types.UNSET_OPPORTUNITY_DETAIL,
    payload: {listName, opportunityId}
});


export const createOpportunityStart = () => ({ type: types.CREATE_OPPORTUNITY_START});
export const createOpportunitySuccess = () => ({ type: types.CREATE_OPPORTUNITY_SUCCESS});
export const createOpportunityError = () => ({ type: types.CREATE_OPPORTUNITY_ERROR});


export const setNotificationsLoading = (status = true) => ({
    type: types.SET_NOTIFICATIONS_LOADING,
    payload: status
});

export const setNotifications = notifications => ({
    type: types.SET_NOTIFICATIONS,
    payload: notifications
});

export const emptyNotifications = () => ({
    type: types.EMPTY_NOTIFICATIONS
});

export const setNotificationReadStatus = (notificationId, status) => ({
    type: types.SET_NOTIFICATION_READ_STATUS,
    payload: {notificationId, status}
});

export const setAllNotificationReadStatus = (status) => ({
    type: types.SET_ALL_NOTIFICATION_READ_STATUS,
    payload: {status}
});


export const setNotificationsFilter = (filter) => ({
    type: types.SET_NOTIFICATIONS_FILTER,
    payload: filter
});