import React from 'react';

import './styles.css';

const ProfileMenu = ({history, logout}) => {
    return (
        <div className="ProfileMenu z-10 position-fixed pin-all">
            <div className="position-fixed pin-all"
                onClick={() => history.goBack()} 
            />

            <div className="ProfileMenuContent position-fixed z10">
                <button className="ProfileMenuItem"
                    onClick={() => history.replace('/main/profile')}>
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    Profile
                </button>
                {/* <button className="ProfileMenuItem"
                    onClick={() => {}}>
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                    How to use
                </button>
                <button className="ProfileMenuItem"
                    onClick={() => {}}>
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
                    Update App
                </button> */}
                <button className="ProfileMenuItem"
                    onClick={() => logout(history)}>
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>
                    Logout
                </button>
            </div>
        </div>
    )
}
 
export default ProfileMenu;