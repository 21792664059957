import * as types from './types';

/**
 * Sets the status of forum socket connection
 * @function
 * @name setSocketConnectionStatus
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const setSocketConnectionStatus = (status) => ({
    type: types.FORUM_SOCKET_CONNECTION_CHANGED,
    payload: status
});

/**
 * Sets the active tab for the forums screen
 * @function
 * @name setActiveTab
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const setActiveTab = (activeTab) => ({
    type: types.SET_FORUMS_ACTIVE_TAB,
    payload: activeTab
});

/**
 * Sets other forum that is being previewed
 * @function
 * @name setPreviewedForumId
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const setPreviewedForumId = (forumId) => ({
    type: types.SET_PREVIEWED_FORUM_ID,
    payload: forumId
});

/**
 * initiate fetching of user forums
 * @function
 * @name getUserForumsStart
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getUserForumsStart = () => ({
    type: types.GET_USER_FORUMS_START
});

/**
 * report success of fetching user forums
 * @function
 * @name getUserForumsSuccess
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getUserForumsSuccess = forums => ({
    type: types.GET_USER_FORUMS_SUCCESS,
    payload: forums
});

/**
 * report error of fetching user forums
 * @function
 * @name getUserForumsError
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getUserForumsError = error => ({
    type: types.GET_USER_FORUMS_ERROR,
    payload: error
});

/**
 * initiate fetching of other forums
 * @function
 * @name getOtherForumsStart
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getOtherForumsStart = () => ({
    type: types.GET_OTHER_FORUMS_START
});

/**
 * report success of fetching other forums
 * @function
 * @name getOtherForumsSuccess
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getOtherForumsSuccess = forums => ({
    type: types.GET_OTHER_FORUMS_SUCCESS,
    payload: forums
});

/**
 * report error of fetching other forums
 * @function
 * @name getOtherForumsError
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const getOtherForumsError = error => ({
    type: types.GET_OTHER_FORUMS_ERROR,
    payload: error
});

/**
 * set details of an unsubscribed forum
 * @function
 * @name setOtherForumDetails
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const setOtherForumDetails = (forumId, data) => ({
    type: types.SET_OTHER_FORUM_DETAILS,
    payload: {forumId, data}
});

/**
 * set details for a subscribed forum
 * @function
 * @name setUserForumDetails
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const setUserForumDetails = (forumId, data) => ({
    type: types.SET_USER_FORUM_DETAILS,
    payload: {forumId, data}
});

/**
 * remove a forum from other forums and add it to user forums
 * @function
 * @name subscribeToForum
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const subscribeToForum = (forumId) => ({
    type: types.SUBSCRIBE_TO_FORUM,
    payload: forumId
});

/**
 * remove a forum from a user's forum and add it to other forums
 * @function
 * @name unsubscribeFromForum
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const unsubscribeFromForum = (forumId) => ({
    type: types.UNSUBSCRIBE_FROM_FORUM,
    payload: forumId
});


/**
 * Sets the current forum id
 * @function
 * @name setCurrentForumId
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const setCurrentForumId = (forumId) => ({
    type: types.SET_CURRENT_FORUM_ID,
    payload: forumId
});

/**
 * report success of fetching forum messages
 * @function
 * @name forumMessagesFetched
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const forumMessagesFetched = (forumId, data) => ({
    type: types.FORUM_MESSAGES_FETCHED,
    payload: {forumId, data},
});

/**
 * Adds a message to a forum
 * @function
 * @name addMessageToForum
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const addMessageToForum = (forumId, message) => ({
    type: types.ADD_MESSAGE_TO_FORUM,
    payload: {forumId, message},
});

/**
 * Updates details of a message
 * @function
 * @name updateMessageDetails
 * @param forumId {int} - the forum id
 * @param messageId {int} - id of message being updated
 * @param updatedDetails {Object} - updated message details
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const updateMessageDetails = (forumId, messageId, updatedDetails) => ({
    type: types.UPDATE_MESSAGE_DETAILS,
    payload: {forumId, messageId, updatedDetails},
});

/**
 * Deletes a message from a forum
 * @function
 * @name deleteMessageFromForum
 * @param forumId {int} - the forum id
 * @param messageId {int} - id of message being deleted
 * @version 0.1.0
 * @since 0.1.0
 * @returns {Object} - redux action
 */
export const deleteMessageFromForum = (forumId, messageId) => ({
    type: types.DELETE_MESSAGE_FROM_FORUM,
    payload: {forumId, messageId},
});