import * as actions from './actions';
import * as API from "../../API";
import * as selectors from "./selectors";
import { message } from 'antd';


export const setBottomNavVisibility = actions.setBottomNavVisibility;
export const setActiveInterest = actions.setActiveInterest;
export const setSelectedOpportunity = actions.setSelectedOpportunity;
export const unsetSelectedOpportunity = actions.unsetSelectedOpportunity;
export const setNotificationsLoading = actions.setNotificationsLoading;
export const setNotificationsFilter = actions.setNotificationsFilter;


/**
 * an operation that gets all countries
 * @function
 * @name getAllCountries
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAllCountries = () => (dispatch, getState) => {
    const state = getState();
    const token = selectors.getToken(state);
    return API.getCountries(token)
        .then(res => {
            dispatch(actions.setCountries(res.data));
        })
        .catch(err => console.log(err));
};

/**
 * an operation that gets all regions by country
 * @function
 * @name getRegions
 * @param countryId {String} - Id of the country
 * @version 0.1.0
 * @since 0.1.0
 */
export const getRegions = (countryId) => (dispatch, getState) => {
    const { user } = getState();
    const { data: { token } } = user;
    return API.getRegionsByCountry(countryId, token)
        .then(res => {
            dispatch(actions.setRegions(res.data));
        })
        .catch(err => console.log(err));
};


/**
 * an operation that gets all districts by region
 * @function
 * @name getDistricts
 * @param regionId {String} - Id of the region
 * @version 0.1.0
 * @since 0.1.0
 */
export const getDistricts = (regionId) => (dispatch, getState) => {
    const { user } = getState();
    const { data: { token } } = user;
    return API.getDistrictsByRegion(regionId, token)
        .then(res => {
            dispatch(actions.setRegions(res.data));
        })
        .catch(err => console.log(err));
};

export const initApp = () => (dispatch) => {
    const authUser = JSON.parse(localStorage.getItem('authUser'));

    if (authUser && authUser.data) {
        const { interests } = authUser.data.userProfile;
        dispatch(actions.setAuthUser(authUser));
        dispatch(actions.setSelectedInterests(interests));
        dispatch(actions.setActiveInterest(interests[0]));
        dispatch(actions.authSuccess());
        dispatch(actions.loginUser());
    }
    else
        dispatch(actions.setAuthUser(null));
};

export const pushScreen = (history, screenUrl) => (dispatch) => {
    history.push(screenUrl);
};

export const replaceScreen = (history, screenUrl) => (dispatch) => {
    history.replace(screenUrl);
};

export const navigateBack = (history, pageCount = 1) => (dispatch) => {
    history.go(-pageCount);
};

export const logout = (history) => (dispatch) => {
    history.replace('/');
    localStorage.clear();
    dispatch(actions.setAuthUser({}));
    dispatch(actions.setSelectedInterests([]));
    dispatch(actions.setActiveInterest({}));
    dispatch(actions.signOutUser());
};

export const deactivateAccount = (reason, history) => async (dispatch, getState) => {
    const { user } = getState();
    const { data: { token } } = user;
    dispatch(actions.pageLoading(true));

    try {
        await API.deactivateUser({ reason }, token);
        dispatch(actions.pageLoading(false));
        dispatch(logout(history));
        message.success('Account deactivated');
    } catch (error) {
        dispatch(actions.pageLoading(false));
        message.error('Your account couldn\'t be deactivated, please try again');
    }
};

export const reactivateUser = (payload, history) => async (dispatch) => {
    dispatch(actions.pageLoading(true));

    try {
        await API.reactivateUser(payload.data.token, payload.data.userProfile._id);
        dispatch(actions.pageLoading(false));

        const { interests } = payload.data.userProfile;
        dispatch(actions.setSelectedInterests(interests));
        dispatch(actions.setActiveInterest(interests[0]));
        dispatch(actions.loginUser());
        localStorage.setItem('authUser', JSON.stringify(payload));

        history.replace('/main');
    } catch (error) {
        dispatch(actions.pageLoading(false));
        message.error('Your account couldn\'t be reactivated, please try again');
        console.log(error);
    }
}

export const signin = (payload, history) => (dispatch) => {
    dispatch(actions.authStart());
    dispatch(actions.pageLoading(true));
    API.sendToken(payload)
        .then((res) => {
            const { statusCode } = res;
            dispatch(actions.pageLoading(false));

            // register user if doesnot exist
            if (statusCode === 6100) {
                dispatch(actions.setAuthToken(payload));
                localStorage.setItem("attempts", "1"); // set default signup attempts
                history.push("/onboarding/nickname");
            }

            // sigin in user if the user exists
            else if (statusCode === 6101 || statusCode === 6509) {
                dispatch(actions.setAuthToken(payload));
                dispatch(actions.authSuccess());
                dispatch(actions.setAuthUser(res));

                const {
                    country,
                    interests,
                    dream,
                    anonymity,
                    disabled,
                    revokeReason,
                    deleted,
                    deleteReason,
                } = res.data.userProfile;

                if (disabled && revokeReason && revokeReason.length) {
                    dispatch(actions.loginUser());
                    history.push("/onboarding/account-revoked");
                    return;
                } else if (deleted && deleteReason && deleteReason.length) {
                    history.push(
                        `/onboarding/welcome-back?userName=${res.data.userProfile.firstName}`,
                        { user: res },
                    );
                    return;
                } else if (
                    !country ||
                    !country.length ||
                    country === "field not set"
                ) {
                    history.push("/onboarding/details");
                    return;
                } else if (!interests || !interests.length) {
                    dispatch(actions.setAuthToken(payload));
                    history.push("/onboarding/interests");
                    return;
                } else if (
                    !dream ||
                    !dream.length ||
                    dream === "field not set"
                ) {
                    dispatch(actions.setAuthToken(payload));
                    console.log("No dream, redirecting: ");
                    history.push("/onboarding/dream");
                    return;
                } else if (anonymity !== 0 && anonymity !== 1) {
                    dispatch(actions.setAuthToken(payload));
                    history.push("/onboarding/anonymity");
                    return;
                }

                dispatch(actions.setAuthUser(res));
                dispatch(actions.setSelectedInterests(interests));
                dispatch(actions.setActiveInterest(interests[0]));
                dispatch(actions.authSuccess());
                dispatch(actions.loginUser());
                localStorage.setItem("authUser", JSON.stringify(res));

                let redirectTo = localStorage.getItem("REDIRECT_FROM");
                if (redirectTo) {
                    redirectTo = JSON.parse(redirectTo);
                    history.push({ ...redirectTo });
                    localStorage.removeItem("REDIRECT_FROM");
                } else history.push("/main");
            }
        })
        .catch((err) => {
            console.log(err);
            dispatch(actions.pageLoading(false));
        });
};

export const addUserDemographics =
    (payload, history) => (dispatch, getState) => {
        const { user } = getState();
        const {
            data: { token },
        } = user;
        dispatch(actions.pageLoading(true));
        API.createDemographics(payload, token)
            .then((res) => {
                dispatch(actions.pageLoading(false));
                history.push("/onboarding/interests");

                if (user) {
                    user.data.userProfile = {
                        ...user.data.userProfile,
                        ...payload,
                    };
                    dispatch(actions.setAuthUser(user));
                }
            })
            .catch((err) => {
                dispatch(actions.pageLoading(false));
                console.log(err);
            });
    };

export const addUserInterests = (payload, history) => (dispatch, getState) => {
    const { user } = getState();
    const {
        data: { token },
    } = user;
    dispatch(actions.pageLoading(true));
    API.createUserInterests({ interests: payload }, token)
        .then((res) => {
            dispatch(actions.pageLoading(false));
            const { interests } = res.data;
            dispatch(actions.setSelectedInterests(interests));
            dispatch(actions.setActiveInterest(interests[0]));
            // history.push("/onboarding/dream");

            if (user) {
                user.data.userProfile = {
                    ...user.data.userProfile,
                    interests,
                };
                dispatch(actions.setAuthUser(user));
            }
            // moved from what used to be the final reg screen
            dispatch(actions.authSuccess());
            dispatch(actions.loginUser());
            localStorage.setItem("authUser", JSON.stringify(user));

            let redirectTo = localStorage.getItem("REDIRECT_FROM");
            if (redirectTo) {
                redirectTo = JSON.parse(redirectTo);
                history.push({ ...redirectTo });
                localStorage.removeItem("REDIRECT_FROM");
            } else history.push("/main");
        })
        .catch((err) => {
            dispatch(actions.pageLoading(false));
            console.log(err);
        });
};

export const addUserDream = (payload, history) => (dispatch, getState) => {
    const { user } = getState();
    const { data: { token } } = user;
    dispatch(actions.pageLoading(true));
    API.createUserDream(payload, token)
        .then(res => {
            dispatch(actions.pageLoading(false));
            history.push('/onboarding/anonymity');

            if (user) {
                user.data.userProfile = {
                    ...user.data.userProfile,
                    dream: payload.dream
                };
                dispatch(actions.setAuthUser(user));
            }
        })
        .catch(err => {
            dispatch(actions.pageLoading(false));
            console.log(err)
        });
};

export const addUserAnonymity = (payload, history) => (dispatch, getState) => {
    const { user } = getState();
    const { data: { token } } = user;
    dispatch(actions.pageLoading(true));
    API.createUserAnonymity(payload, token)
        .then(res => {
            dispatch(actions.pageLoading(false));

            if (user) {
                user.data.userProfile = {
                    ...user.data.userProfile,
                    anonymity: payload.anonymity
                };
                dispatch(actions.setAuthUser(user));
            }

            dispatch(actions.authSuccess());
            dispatch(actions.loginUser());
            localStorage.setItem('authUser', JSON.stringify(user));

            let redirectTo = localStorage.getItem("REDIRECT_FROM");
            if (redirectTo) {
                redirectTo = JSON.parse(redirectTo);
                history.push({ ...redirectTo });
                localStorage.removeItem("REDIRECT_FROM");
            }
            else
                history.push('/main');
        })
        .catch(err => {
            dispatch(actions.pageLoading(false));
            console.log(err)
        });
};

export const registerUser = (history) => (dispatch, getState) => {
    const { setNickName, authToken } = getState();
    const payload = {
        token: authToken.token,
        provider: authToken.provider,
        nickName: setNickName.nickName
    };
    dispatch(actions.pageLoading(true));
    API.createUser(payload)
        .then(res => {
            dispatch(actions.pageLoading(false));
            dispatch(actions.authSuccess());
            history.push('/opportunities');
        })
        .catch(err => {
            dispatch(actions.pageLoading(false));
            console.log('error occured');
            console.log(err);
        });
};

export const signup = (payload, history) => (dispatch) => {
    dispatch(actions.pageLoading(true));
    API.createUser(payload)
        .then(
            res => {
                dispatch(actions.pageLoading(false));
                dispatch(actions.authSuccess());
                dispatch(actions.setAuthUser(res));
                history.push('/onboarding/details');
            }
        )
        .catch(err => {
            dispatch(actions.pageLoading(false));
            console.log(err);
        });
};

export const collectFeedbackOperation = (payload) => (dispatch, getState) => {
    const { authToken } = getState();

    API.collectFeedback(payload, authToken)
        .then(res => {
            console.log('feedback was collected');
        })
        .catch(err => {
            console.log('error in collecting feedback');
        });
};


export const getAllInterests = () => (dispatch, getState) => {
    const { user } = getState();
    const { data: { token } } = user || {};
    API.getInterests(token)
        .then(res => {
            dispatch(actions.initInterests(res.data));
        })
        .catch(err => console.log(err));
};

export const updateUser = (data, history) => (dispatch, getState) => {
    let { editField, ...payload } = data;
    const { user } = getState();
    const { token } = user.data;

    dispatch(actions.addProfileEditField(editField));
    const updateFunction = editField !== 'Demographic Details' ? API.updateUser : API.updateDemographics;
    updateFunction(payload, token)
        .then(res => {
            dispatch(actions.removeProfileEditField(editField));

            const authUser = JSON.parse(localStorage.getItem('authUser'));

            authUser.data.userProfile = { ...authUser.data.userProfile, ...payload };
            localStorage.setItem('authUser', JSON.stringify(authUser));
            dispatch(actions.setAuthUser(authUser));

            history.replace('/main/profile');

            if (editField !== "anonymity")
                message.success(`${editField} updated`);
        })
        .catch(error => {
            dispatch(actions.removeProfileEditField(editField));
            console.log('update user failed', error);
            message.error(`Failed to update ${editField.toLowerCase()}`);
        });
};

export const updateUserDream = (dream, history) => (dispatch, getState) => {
    const { user } = getState();

    dispatch(actions.addProfileEditField("Dream"));

    API.updateUserDream({ dream }, user.data.token)
        .then(res => {
            dispatch(actions.removeProfileEditField("Dream"));

            const authUser = JSON.parse(localStorage.getItem('authUser'));
            authUser.data.userProfile = { ...authUser.data.userProfile, dream };
            localStorage.setItem('authUser', JSON.stringify(authUser));
            dispatch(actions.setAuthUser(authUser));

            history.replace('/main/profile');
            message.success("Dream updated");
        })
        .catch(error => {
            dispatch(actions.removeProfileEditField("Dream"));
            console.log('update user dream failed', error);
            message.error("Failed to update dream");
        });
};

export const updateUserInterests = (interests, history) => (dispatch, getState) => {
    const { user } = getState();
    const { token } = user.data;
    const payload = interests.map(({ _id }) => _id);

    dispatch(actions.addProfileEditField("interests"));
    API.createUserInterests({ interests: payload }, token)
        .then(res => {
            dispatch(actions.removeProfileEditField("interests"));

            const authUser = JSON.parse(localStorage.getItem('authUser'));

            authUser.data.userProfile = { ...authUser.data.userProfile, interests };
            localStorage.setItem('authUser', JSON.stringify(authUser));
            dispatch(actions.setAuthUser(authUser));
            dispatch(actions.setSelectedInterests(interests));
            dispatch(actions.setActiveInterest(interests[0]));

            history.replace('/main/profile');
            message.success("Interests updated");
        })
        .catch(error => {
            dispatch(actions.removeProfileEditField("interests"));
            console.log('update user failed', error);
            message.error("Failed to update interests");
        });
};

export const getAllOpportunities = (interestId) => (dispatch, getState) => {
    const { user, opportunities } = getState();
    const { token } = user.data;
    const isSameInterest = interestId === opportunities.all.interestId;
    const nextPage = isSameInterest ? parseInt(opportunities.all.page) + 1 : 1;
    const payload = { interestId, page: nextPage };

    if (!isSameInterest)
        dispatch(actions.emptyAllOpportunities());

    dispatch(actions.setAllOpportunitiesLoading(true));

    API.getAllOpportunitiesByInterest(payload, token)
        .then(res => {
            let responseData = res.data;
            responseData.interestId = interestId;
            responseData.loading = false;
            if (isSameInterest)
                responseData.opportunities = opportunities.all.opportunities.concat(responseData.opportunities);
            dispatch(actions.setAllOpportunities(responseData));
        })
        .catch(err => {
            console.log(err);
            dispatch(actions.setAllOpportunitiesLoading(false));
        });
};

export const getDueTodayOpportunities = (interestId) => (dispatch, getState) => {
    const { user, opportunities } = getState();
    const { token } = user.data;

    const isSameInterest = interestId === opportunities.dueToday.interestId;
    const nextPage = isSameInterest ? parseInt(opportunities.dueToday.page) + 1 : 1;
    const payload = { interestId, page: nextPage };

    if (!isSameInterest)
        dispatch(actions.emptyDueTodayOpportunities());

    dispatch(actions.setDueTodayOpportunitiesLoading(true));

    API.getDueTodayOpportunitiesByInterest(payload, token)
        .then(res => {
            let responseData = res.data;
            responseData.interestId = interestId;
            responseData.loading = false;
            if (isSameInterest)
                responseData.opportunities = opportunities.dueToday.opportunities.concat(responseData.opportunities);
            dispatch(actions.setDueTodayOpportunities(responseData))
        })
        .catch(err => {
            console.log(err);
            dispatch(actions.setDueTodayOpportunitiesLoading(false));
        });
};

export const getOpportunitiesSummaryOperation = (payload) => (dispatch) => {
    dispatch(getAllOpportunities(payload));
    dispatch(getDueTodayOpportunities(payload));
};


export const getOtherInterestsOpportunitiesOperation = () => (dispatch, getState) => {
    const { user } = getState();
    const { token } = user.data;
    API.getOtherInterestsOpportunities(token)
        .then((res) => {
            dispatch(actions.setOtherOpportunities(res.data));
        })
        .catch(err => console.log(err));
};


export const createOpportunityOpearation = ({ _tempId, ...payload }, history) => (dispatch, getState) => {
    const state = getState();
    const token = selectors.getToken(state);
    dispatch(actions.createOpportunityStart());
    API.createOpportunity(payload, token)
        .then(res => {
            dispatch(actions.createOpportunitySuccess());
            setTimeout(history.push('/main'), 5000);

            let opportunityDrafts = window.localStorage.getItem('RISEUP_OPPORTUNITY_DRAFTS');
            if (opportunityDrafts) {
                opportunityDrafts = JSON.parse(opportunityDrafts);
                const updatedOpportunityDrafts = opportunityDrafts.filter((draft) => draft._tempId !== _tempId);

                window.localStorage.setItem('RISEUP_OPPORTUNITY_DRAFTS', JSON.stringify(updatedOpportunityDrafts));
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(actions.createOpportunityError())
        })
};

export const getNotifications = () => (dispatch, getState) => {
    dispatch(actions.emptyNotifications());
    dispatch(setNotificationsLoading());
    const { user, notifications } = getState();
    const { token } = user.data;
    const nextPage = parseInt(notifications.page) + 1;

    API.getUserNotifications(nextPage, token)
        .then(res => {
            let responseData = res.data;
            if (!notifications.notifications)
                notifications.notifications = [];

            responseData.notifications = notifications.notifications.concat(responseData.notifications);
            dispatch(actions.setNotifications(responseData));
        })
        .catch(err => {
            console.log(err);
            dispatch(setNotificationsLoading(false));
        });
};

export const markNotififactionAsRead = (payload) => (dispatch, getState) => {
    const { user } = getState();


    API.setNotificationReadStatus(payload, user.data.token)
        .then(res => {
            dispatch(actions.setNotificationReadStatus(payload, true));
        })
        .catch(err => console.log(err));
};

export const markAllNotificationsAsRead = () => (dispatch, getState) => {
    const { user } = getState();


    API.setAllNotificationReadStatus(user.data.token)
        .then(res => {
            dispatch(actions.setAllNotificationReadStatus(true));
            window.location.reload();
        })
        .catch(err => console.log(err));
};