import React, { useState, useEffect } from 'react';
import ashuraFull from '../../../../../images/ashura-full.png';
import { getLearningLabInterests } from '../../../../../API';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';

import { lightenDarkenColor, learningLabColorCodes } from '../../../../../Util';

import ToolBar from '../../../../components/ToolBar';
import InterestItem from '../../../../components/InterestItem';
import CustomButton from '../../../../components/Button';
import AlertDialog from '../../../../components/FlNotifications/AlertDialog';
import RaisedSurface from '../../../../components/RaisedSurface';
import Loader from '../../../../components/Loader';

import "../styles.css";

function LearningLabOnboarding({authToken, history, savingInterests, saveInterests}){
    const [interests, setInterests] = useState([]);
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [interestFetchState, setInterestFetchState] = useState("idle");

    useEffect(() => {
        fetchInterests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchInterests = async () => {
        setInterestFetchState("fetching");
        try {
            const res = await getLearningLabInterests(authToken);
            const interests = uniqBy(res.data, 'name');
            setInterestFetchState("idle");
            setInterests(interests);
        } catch (error) {
            setInterestFetchState("error");
        }
    }

    const handleSubmit = () => {
        saveInterests(selectedInterests, history);
    };

    const selectInterest = (interest) => {
        setSelectedInterests([...selectedInterests, interest]);
    };

    const deselectInterest = (interest) => {
        setSelectedInterests(selectedInterests.filter(({_id}) => interest._id !== _id));
    };

    const renderInterests = () => interests.map(interest => {
        const isChecked = selectedInterests.find(({_id}) => interest._id === _id) != null;
        // interest.color = "orange";
        const colorCodes = {
            games: "#26ABC5",
            health: "#D78D7C",
            innovation: "#ED8089",
            enterprenuership: "#FA4C5B",
            skills: "#62D2C4",
        }
        const interestColor = interest.name && interest.name.length > 0 && colorCodes[interest.name.toLowerCase()];
        
        if(interestColor)
            interest.color = interestColor;

        return (
            <InterestItem
                key={interest._id}
                interest={interest}
                checked={isChecked}
                disabled={!isChecked && selectedInterests.length === 3}
                onClick={!isChecked ? selectInterest : deselectInterest}
            />
        )
    });

    return (
        <div id="LearningLabOnboarding" className="position-fixed pin-all z10">
            <div id="LearningLabNavBar" className="position-relative">
                <ToolBar
                    theme="#AC86D6"
                    isAuthenticated={true}
                    isLogin={true}
                />
                <div style={{background: lightenDarkenColor("#AC86D6")}}>
                    Welcome to the Learning Lab
                </div>

                <div className="flower-deco" />
            </div>

            <div id="LearningLabOnboardingContent">
                <div id="onboardingIllustration">
                    <div className="d-flex">
                        <div id="illustration">
                            <img src={ashuraFull} alt=""/>
                        </div>

                        <div id="message">
                            <h1>
                                Hello Mrembo, <br/>
                                We believe in you.
                            </h1>
                            <h2>Believe in yourself?</h2>
                            <small>
                                #Learn #Grow #Inspire
                            </small>
                        </div>
                    </div>
                </div>
                
                <div className="position-relative">
                    { interestFetchState === "fetching" && <Loader className="FetchingLearningLabsLoader" fill size={100} /> }

                    { interestFetchState === "error" && ( 
                        <AlertDialog
                            message="Failed to fetch interests"
                            onOkay={fetchInterests}
                            okText="Retry"
                        /> 
                    )}

                    { interests.length > 0 && (
                        <div className="interests-form">
                            { renderInterests(interests) }
                        </div>
                    )}
                </div>
            </div>

            <RaisedSurface>
                <div className="instructions-text">
                    *Pick your top 3
                </div>
                <p className="text-center">
                    You can change your preferences on your profile settings
                </p>

                { selectedInterests.length === 3 &&
                    <CustomButton
                        width="100%"
                        color="#AC86D6"
                        loading={savingInterests}
                        disabled={selectedInterests.length < 3}
                        handleOnClick={handleSubmit}
                    >
                        Karibu
                    </CustomButton>
                }
            </RaisedSurface>
        </div>
    );
}

export default LearningLabOnboarding;

LearningLabOnboarding.propTypes = {
    authToken: PropTypes.string,
    history: PropTypes.object,
    savingInterests: PropTypes.bool
};