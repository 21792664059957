import React from 'react';

import ashura from "../../../../../images/ashura.png";
import Button from '../../../../components/Button';

import "./styles.css";

function AccountRevoked({history, authUser}){
    return (
        <div id="AccountRevoked" className="position-fixed pin-all z10">
            <div id="AccountRevokedContent">
                <img src={ashura} alt="" />

                <h1>
                    Hey {authUser.userName}, your RiseUp account has been revoked.
                </h1>

                <h2>
                    {authUser.revokeReason}
                    {/* This is because you've continuously been using inappropriate language which is not allowed on RiseUp. */}
                </h2>

                <Button flat
                    handleOnClick={() => history.replace('/login')}>
                    Oh, I see
                </Button>
            </div>
        </div>
    );
}

export default AccountRevoked;