import React from 'react';
import PropTypes from 'prop-types';

import CustomButton from '../../../../../components/Button';

import './styles.css';

const FilterItem = ({filter, onClick, text, isActive, theme}) => {
    const color = isActive ? "white" : theme;
    const backgroundColor = isActive ? theme : "transparent";

    return (
        <CustomButton className="d-flex center-center" 
            style={{backgroundColor, color}}
            handleOnClick={onClick}>
            {text ? text : filter}
        </CustomButton>
    )
}

const NotificationsFilter = ({theme, currentFilter, unreadCount, setFilter}) => {
    const unreadCountText = unreadCount !== 0 ? `(${unreadCount}) Unread` : "";

    return ( 
        <div className="NotificationsFilter">
            <div className="d-flex items-center justify-stretch"
                style={{color: theme}}>

                <FilterItem 
                    isActive={currentFilter === "All"} 
                    filter="All" 
                    onClick={() => setFilter("All")} 
                    theme={theme}  
                />
                <FilterItem 
                    isActive={currentFilter === "Unread"} 
                    filter="Unread" 
                    text={unreadCountText}
                    onClick={() => setFilter("Unread")} 
                    theme={theme}  
                />
                <FilterItem 
                    isActive={currentFilter === "Mentions"} 
                    filter="Mentions" 
                    onClick={() => setFilter("Mentions")} 
                    theme={theme}  
                />
            </div>
        </div>
    );
}
 
export default NotificationsFilter;

NotificationsFilter.propTypes = {
    theme: PropTypes.string,
    url: PropTypes.string,
    currentFilter: PropTypes.string,
    unreadCount: PropTypes.number
};