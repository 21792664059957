import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appSelectors, appOperations } from "../../../../../duck";

import ViewProfile from './ViewProfile';

const mapStateToProps = state => ({
    authUser: appSelectors.getAuthUser(state),
    activeProfileEditFields: state.activeProfileEditFields
});

const mapDispatchToProps = {
    updateDetails: appOperations.updateUser,
    logout: appOperations.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewProfile));