import React from 'react';
import PropTypes from 'prop-types';
import Onboarding from './Screens/Onboarding';
import Main from './Screens/Main';

function LearningLab(props){
    if(!props.authUser.learningLabs || !props.authUser.learningLabs.length)
        return <Onboarding {...props} />
    else
        return <Main {...props} />
}

export default LearningLab;

LearningLab.propTypes = {
    activeTab: PropTypes.object,
    setActiveTab: PropTypes.func,
    authUser: PropTypes.object,
    authtoken: PropTypes.string
};