import { connect } from 'react-redux';
import WelcomeBack from './WelcomeBack';
import { appOperations } from '../../../../duck';

const mapStateToProps = state => ({
    loading: state.pageLoading
});

const mapDispatchToProps = {
    reactivateUser: appOperations.reactivateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeBack);
