import React from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import SocialLoginButton from "../SocialLoginButton";

function GoogleLoginWrapper(props) {
    const onFailure = res => {
       console.log('inside GoogleLoginWrapper');
       console.log(res);
    };

    return (
        <div className="GoogleLoginWrapper">
            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                render={({onClick}) => <SocialLoginButton
                    width={props.width}
                    small={props.small}
                    value={props.value}
                    style={props.style}
                    color='#e0493f'
                    handleOnClick={onClick}>
                    <i className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480" height="480" width="480"><defs><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 48 48 48 48 0 0 0 0 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 48 48 48 48 0 0 0 0 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 48 48 48 48 0 0 0 0 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 48 48 48 48 0 0 0 0 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"/><clipPath clipPathUnits="userSpaceOnUse"/><clipPath clipPathUnits="userSpaceOnUse"><path d="M48 48 0 48 0 0 48 0 48 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 48 48 48 48 0 0 0 0 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"/><clipPath clipPathUnits="userSpaceOnUse"/><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 0 48 0 48 48 0 48 0 0Z"/></clipPath></defs><g transform="matrix(1.25 0 0 -1.25 0 480)"><path d="m196 214.4 0-55.2 92 0C283.2 136 260.8 90.4 196 90.4 140.8 90.4 97.6 136 97.6 191.2 97.6 246.4 140.8 292 196 292c32 0 52.8-13.6 64-24.8l43.2 41.6c-27.2 27.2-64 43.2-108 43.2-89.6 0-160-71.2-160-160 0-88.8 71.2-160 160-160 92 0 153.6 64 153.6 156 0 11.2 0 18.4-2.4 27.2l-150.4 0z"/></g></svg>
                    </i>
                    <span>{props.value}</span>
                </SocialLoginButton>}
                buttonText="Login"
                onSuccess={props.googleResponse}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
            />
        </div>
    );
}

export default GoogleLoginWrapper;

GoogleLoginWrapper.propTypes = {
    googleResponse: PropTypes.func,
    small: PropTypes.bool,
    value: PropTypes.string,
    width: PropTypes.string,
    style: PropTypes.object
};