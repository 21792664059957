import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import OpportunityDetail from './OpportunityDetail';
import { appOperations } from '../../../../../duck';

function StaticOpportunityDetail(props){
    function handleGoBack(){
        const { history, navigateBack } = props;
        navigateBack(history);
    }

    const opportunity = props.location.state.opportunity;
    if(opportunity && opportunity.interests && props.interests ){
        const interest = opportunity.interests.length ? opportunity.interests[0] : null;

        if(interest && props.interests.length)
            opportunity.interest = props.interests.find(({_id}) => _id === interest);
    }

    return (
        <OpportunityDetail
            {...props}
            opportunity={opportunity}
            onGoBack={handleGoBack}
        />
    )
}

const mapDispatchToProps = {
    navigateBack: appOperations.navigateBack,
    setSelectedOpportunity: appOperations.setSelectedOpportunity,
    unsetSelectedOpportunity: appOperations.unsetSelectedOpportunity,
    getAllOpportunities: appOperations.getAllOpportunities,
    getDueTodayOpportunities: appOperations.getDueTodayOpportunities
}

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    isLogin: state.isLogin,
    activeInterest: state.interests && state.interests.active,
    interests: state.interests && state.interests.selected
})

export default connect(mapStateToProps,mapDispatchToProps)(StaticOpportunityDetail);


StaticOpportunityDetail.propTypes = {
    activeInterest: PropTypes.object,
    match: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    isLogin: PropTypes.bool,
    theme: PropTypes.string
}
