import React from "react";
import {Route, Switch, Redirect} from 'react-router-dom';

import Opportunities from './Screens/Opportunities';
import Forums from './Screens/Forums';
import Notifications from './Screens/Notifications';
import ComingSoon from "../components/ComingSoon";
import Profile from "./Screens/Profile";
import LearningLab from "./Screens/LearningLab";

function MainAppRouter(){
    return (
        <Switch>
            <Route path="/main/profile" component={Profile} />
            <Route path="/main/opportunities" component={Opportunities} />
            <Route path="/main/forums" component={Forums} />
            <Route path="/main/learningLab" component={LearningLab} />
            <Route path="/main/counseling" component={ComingSoon} />
            <Route path="/main/notifications" component={(routerProps) =>
                <Notifications {...routerProps} notModal />} 
            />
            <Redirect to="/main/opportunities/view"/>
        </Switch>
    );
}

export default MainAppRouter;