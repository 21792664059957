
import React, { Component } from "react";
import PropTypes from 'prop-types';
import './styles.css';
import {Pagination} from "antd";
import Opportunity from "../Opportunity";
import Loader from "../../../../../../../components/Loader";


/**
 * Component that renders all opportunities
 * @class
 * @name DueTodayOpportunities
 *
 * @version 0.1.0
 * @since 0.1.0
 */
class DueTodayOpportunities extends Component {

    renderOpportunities = (opportunities, url) => {
        const dueTodayUrl =  `/opportunities/view/dueToday/${this.props.activeInterest._id}`;
        return opportunities.map(({resourceImg, title, deadlineDate, _id}) => {

            return <Opportunity
                url={`${dueTodayUrl}/${_id}`}
                imageUrl={resourceImg}
                opportunityTitle={title}
                date={deadlineDate}
                id={_id}
            />
        })
    };



    componentDidMount() {
        const { match: {params}, getOpportunities } = this.props;
        getOpportunities(params.interestId);
    }

    render() {
        const {
            match: {
                url,
                params
            },
            allOpportunities,
            getOpportunities,
            totalOpportunities,
            activeInterest,
            currentPage,
            loading
        } = this.props;
        const renderLoader = () => (<div style={{padding: '2em 0'}} className="d-flex center-center">
            <Loader size={80} thickness={4} color={activeInterest.color} />
        </div>);
        return loading ?
            renderLoader():
            (
                <div className="DueTodayOpportunities">
                    <section>
                        <div className="section-contents">
                            {this.renderOpportunities(allOpportunities, url)}
                        </div>
                    </section>

                    <section className="opportunities-pagination">
                        <Pagination
                            size="small"
                            total={totalOpportunities}
                            current={currentPage}
                            hideOnSinglePage={true}
                            onChange={(page) => getOpportunities(params.interestId, page)}
                        />
                    </section>

                </div>
            );

    }

}
export default DueTodayOpportunities;

DueTodayOpportunities.propTypes = {
    match: PropTypes.object,
    activeInterest: PropTypes.object,
    theme: PropTypes.string,
    loading: PropTypes.bool,
    currentPage: PropTypes.number,
    totalOpportunities: PropTypes.number,
    allOpportunities: PropTypes.array
};
