import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appSelectors, appOperations } from "../../../../../duck";

import EditInterests from './EditInterests';

const mapStateToProps = state => ({
    authUser: appSelectors.getAuthUser(state),
    activeProfileEditFields: state.activeProfileEditFields,
});

const mapDispatchToProps = {
    updateDetails: appOperations.updateUser,
    saveInterests: appOperations.updateUserInterests,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditInterests));