import React from 'react';

import Loader from '../../../../../components/Loader';

import NotificationItem from './NotificationItem';

import './styles.css';

const QuickPreview = (props) => {
    const {notifications, loading, emptyMessage, onViewOpportunity } = props;
    // const notificationIcon = document.querySelector('.toolbar-notification-icon');
    // const notificationIconBox = notificationIcon.getBoundingClientRect(); 
    // const scale = window.innerWidth / 360;
    // const diff = window.innerWidth - notificationIconBox.left;
    // const placement = `${diff / scale}px`;
    // console.log("Notification icon: ", scale, placement);
    const placement = 0;

    let classNames = "QuickPreview position-fixed pin-all";
    classNames += props.locked ? "locked" : "";

    return (
        <div className={classNames}>
            <div className="previewContent position-fixed" style={{right: placement}}>
                    { loading && 
                        <div className="d-flex center-center">
                            <Loader color="#333" 
                                size={60} 
                                thickness={3} 
                            />
                        </div>
                    }

                    { !loading && notifications.length < 1 &&
                        <div className="emptyMessage d-flex center-center text-center">
                            {emptyMessage}
                        </div>
                    }
                    
                    { notifications.map((notification, index) => 
                        <NotificationItem key={index} notification={notification} 
                            onClick={onViewOpportunity} />
                    )}
                    
                    { !loading && notifications.length > 0 &&
                        <button type="button" onClick={props.onPreviewLocked} className="viewAllButton d-flex flex-col center-center text-center brand-color-pinkish-text">
                            <span>View All</span>
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
                        </button>
                    }
                </div>
        </div>
    )
}
 
export default QuickPreview;