import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from "react-router-dom";
import {Provider, connect} from 'react-redux';

import GA from '../Util/google-analytics';

import configureStore from './configureStore';

import { appOperations } from './duck';

import RoutesContainer from './Routes';
import ScrollToTop from "./hooks/ScrollToTop";

import Loader from './components/Loader';

import { message } from "antd";

import 'antd/dist/antd.css';
import './override.css';

export const store = configureStore();

message.config({
    duration: 6
})

let App = ({authUser, initApp}) => {
    const appInitialized = authUser === null || authUser.data;

    useEffect(() => {
        if (!appInitialized) 
            initApp();
    }, [appInitialized, initApp]);

    if(!appInitialized)
        return (
            <div className="position-fixed pin-all d-flex center-center">
                <Loader size={120} color="#888" />
            </div>
        );
    else
        return (
            <BrowserRouter>
                { GA.init() && <GA.RouteTracker /> }
                <ScrollToTop />
                <RoutesContainer/>
            </BrowserRouter>
        );
}

const mapDispatchToProps = {
    initApp: appOperations.initApp,
    fetchNotifications: appOperations.getNotifications,
};

const mapStateToProps = state => ({
    authUser: state.user,
    appInitialized: state.appLoaded
});

App = connect(mapStateToProps, mapDispatchToProps)(App);

App.propTypes = {
    initApp: PropTypes.func,
    fetchNotifications: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    authenticating: PropTypes.bool
};

export default function AppWrapper() {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    );
}