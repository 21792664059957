import React from 'react';

import Button from '../Button';

import './styles.css';

function FlatButton ({className = "", ...props}){
    className += " FlatButton";
    
    return <Button className={className} {...props} />
}

export default FlatButton;