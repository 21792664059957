import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import { AuthenticatedUserToolBar } from '../../../../../../components/ToolBar';
import Button from '../../../../../../components/Button';
import AlertDialog from '../../../../../../components/FlNotifications/AlertDialog';

import "./styles.css";

function draftDescription(description){
    if(!description || !description.length) 
        return 'Description was not provided...';

    return description.length > 30 ? description.substring(0, 30).trim() + "..." : description;
}

function NewOpportunityDraftsInterceptor({history, location, match}){
    const [drafts, setDrafts] = useState([]);
    const [draftToDelete, setDraftToDelete] = useState(null);
    const randomDraftId = 'opportunity-draft-' + Math.random().toString(36).substr(2, 5);

    useEffect(() => {
        const opportunityDrafts = window.localStorage.getItem('RISEUP_OPPORTUNITY_DRAFTS');
        processDrafts(opportunityDrafts);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const processDrafts = (opportunityDrafts) => {
        if(opportunityDrafts){
            opportunityDrafts = JSON.parse(opportunityDrafts);

            if(!opportunityDrafts.length){
                history.replace({
                    pathname: match.url.replace("drafts", "edit"),
                    search: '?draftId=' + randomDraftId
                });
            }
            else
                setDrafts(opportunityDrafts);
        }
        else{
            history.replace({
                pathname: match.url.replace("drafts", "edit"),
                search: '?draftId=' + randomDraftId
            });
        }
    }

    function deleteDraft(draft){
        setDraftToDelete(draft);
        history.push({
            pathname: `${match.url}/confirmDeleteDraft`
        });
    }
    
    function handleDeleteDraft(){
        const updatedDrafts = drafts.filter(({_tempId}) => _tempId !== draftToDelete._tempId);
        setDrafts(updatedDrafts);
        window.localStorage.setItem('RISEUP_OPPORTUNITY_DRAFTS', JSON.stringify(updatedDrafts));

        if(updatedDrafts.length){
            history.replace({
                pathname: match.url.replace('/confirmDeleteDraft', '')
            });
        }
        else{
            history.replace({
                pathname: match.url.replace("drafts", "edit"),
                search: '?draftId=' + randomDraftId
            });
        }
    }

    if(!drafts.length) return null;

    return (
        <div id="NewOpportunityDraftsInterceptor" className="position-fixed pin-all z10">
            <AuthenticatedUserToolBar
                theme="#e65f58"
                title=" "
                isAuthenticated={true}
                onBack={() => history.goBack()} 
                noActions
            />
            
            <div id="NewOpportunityDraftsInterceptorContent">
                <h1>
                    Submit an opportunity
                </h1>

                <div className="d-flex">
                    <Button className="NewOpportunityButton" flat
                        handleOnClick={() => history.replace({
                            pathname: match.url.replace("drafts", "edit"),
                            search: '?draftId=' + randomDraftId
                        })}>
                        New Opportunity
                    </Button>
                </div>

                <h2>
                    Or continue with a saved draft
                </h2>

                {
                    drafts.map((draft, index) => (
                        <div key={index} className="opportunity-draft-container position-relative">
                            <button className={`opportunity-draft d-flex ${!draft.description || !draft.description.length ? 'no-description' : ''}`}
                                key={draft._tempId}
                                onClick={() => history.replace({
                                    pathname: match.url.replace("drafts", "edit"),
                                    search: '?draftId=' + draft._tempId
                                })}>
                                <div className="image">
                                    { draft.imageUrl && draft.imageUrl.length &&
                                        <img src={draft.imageUrl} alt=""/>
                                    }
                                </div>

                                <div className="text flex">
                                    { draft.interest &&
                                        <h5>{draft.interest.name}</h5>
                                    }

                                    <h3>
                                        { draftDescription(draft.description) }
                                    </h3>
                                </div>
                            </button>

                            <button className="position-absolute d-flex center-center opportunity-draft-remover"
                                onClick={() => deleteDraft(draft)}>
                                <svg height="24" width="24" viewBox="0 0 24 24"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/></svg>
                            </button>
                        </div>
                    ))
                }
            </div>

            <Route exact path={`${match.url}/confirmDeleteDraft`} 
                component={_ => (
                    <AlertDialog
                        title = "Sure you want to delete draft?" 
                        cancelText="No, Cancel" 
                        okText="Yes, Delete"
                        onOkay={handleDeleteDraft} 
                        onCancel={history.goBack} /> 
                )}
            />
        </div>
    );
}

export default NewOpportunityDraftsInterceptor;