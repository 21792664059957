import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import SocialLoginButton from "../SocialLoginButton";

function FacebookLoginWrapper(props) {
    const onFailure = res => {
        console.log('inside FacebookLoginWrapper');
        console.log(res);
    };

    return (
        <div className="FacebookLoginWrapper">
            <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                autoLoad={false}
                disableMobileRedirect={true}
                fields="name,email,picture"
                callback={props.facebookResponse}
                onFailure={onFailure}
                render={({ onClick }) => <SocialLoginButton
                    value={props.value}
                    width={props.width}
                    small={props.small}
                    style={{ ...props.style, paddingLeft: '10'}}
                    color='#395b93'
                    handleOnClick={ onClick }
                >
                    <i className="icon">
                        <svg id="facebook-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480" height="480" width="480"><defs><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 48 48 48 48 0 0 0 0 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 48 48 48 48 0 0 0 0 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 48 48 48 48 0 0 0 0 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 48 48 48 48 0 0 0 0 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"/><clipPath clipPathUnits="userSpaceOnUse"/><clipPath clipPathUnits="userSpaceOnUse"><path d="M48 48 0 48 0 0 48 0 48 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 48 48 48 48 0 0 0 0 48Z"/></clipPath><clipPath clipPathUnits="userSpaceOnUse"/><clipPath clipPathUnits="userSpaceOnUse"/><clipPath clipPathUnits="userSpaceOnUse"><path d="M0 0 48 0 48 48 0 48 0 0Z"/></clipPath></defs><g transform="matrix(1.25 0 0 -1.25 0 480)"><path d="m218 32 0 146 49.5 0 7.4 56.9-56.9 0 0 36.3c0 16.5 4.6 27.7 28.5 27.7l30.5 0 0 50.9C271.6 350.5 253.6 352 232.5 352c-43.9 0-74-26.5-74-75.2l0-42-49.7 0 0-56.9 49.7 0 0-146 59.4 0z"/></g></svg>
                    </i>
                    <span>{props.value}</span>
                </SocialLoginButton>}
            />
        </div>

    );
}

export default FacebookLoginWrapper;

FacebookLoginWrapper.propTypes = {
    facebookResponse: PropTypes.func,
    small: PropTypes.bool,
    value: PropTypes.string,
    width: PropTypes.string,
    style: PropTypes.object
};