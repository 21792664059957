import React from 'react';
import formatDate from "date-fns/format";

import LearningLabMedia from './LearningLabMedia';

import './styles.css';
import { getHumanDiffDate } from '../../../../../../../../Util';

const LearningLabItem = (props) => {
    const { item, isTrending, mediaOnly, onVideoClick, onClick } = props;
    let { title, views_count, type, mainTag, createdAt } = item;
    const date = getHumanDiffDate(createdAt);

    title = title || "";

    let typeClass = type;
    if(type == "document" || (type === "article" && item.pdfUrl && item.pdfUrl.length))
        typeClass = "pdf";
    else if(type === "article" && (!item.pdfUrl || !item.pdfUrl.length))
        typeClass = "article";

    return (
        <div className={`LearningLabItem ${typeClass}`}>
            <div onClick={type === 'video' ? onVideoClick : onClick}>
                <LearningLabMedia 
                    item={item}
                    tag={mediaOnly ? null : mainTag ? mainTag[0] : null}
                    isTrending={isTrending}
                />
            </div>
            
            { !mediaOnly && 
                <React.Fragment>
                    <h2 onClick={onClick} style={{fontWeight: "bolder",textDecoration: "underline", fontSize: 20, color: 'blue'}}>
                        { `${title.toLowerCase().substring(0, 38)}${title.length > 37 ? '...' : ''}` }
                    </h2>
                    <p>
                        { date } &middot; { views_count || 'No' }{ views_count && views_count == 1 ? ' view' : ' views' }
                    </p>
                </React.Fragment>
            }
        </div>
    );
}
 
export default LearningLabItem;

LearningLabItem.defaultProps = {
    onClick: () => {},
    onVideoClick: () => {}
}