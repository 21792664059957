import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';

import { appOperations } from "../../../../../../duck";
import ashura from "../../../../../../../images/ashura.png";
import { AuthenticatedUserToolBar } from '../../../../../../components/ToolBar';
import Button from '../../../../../../components/Button';
import FullScreenLoader from '../../../../../../components/FullScreenLoader';

import TypeDeactivateReason from './TypeDeactivateReason';

import "./styles.css";

function DeactivateReason({match, history, deactivatingAccount, deactivateAccount}){
    const deactivationReasons = [
        "Too much content", "Not enough opportunities", 
        "You sent me too many emails", "Some Other Reason"
    ];

    function handleReasonClick(reason, index){
        if(index === deactivationReasons.length - 1)
            history.push(`${match.url}/type`);
        else
            deactivateAccount(reason, history);
    }

    return (
        <div id="DeactivateReason" className="position-fixed pin-all z10">
            <AuthenticatedUserToolBar
                theme="#e65f58"
                title=" "
                isAuthenticated={true}
                onBack={() => history.goBack()} 
                noActions
            />
            
            <div id="DeactivateReasonContent">
                <img src={ashura} alt="" />

                <h1>
                    Aww, we're really sorry to see you go <span role="img" aria-label="sad face">😔</span>
                </h1>

                <h2>
                    Could you help us understand what wasn't working for you?
                </h2>

                { deactivationReasons.map((reason, index) => (
                    <Button key={reason} flat width="100%"
                        handleOnClick={() => handleReasonClick(reason, index)}>
                        { reason }
                    </Button>
                ))}
            </div>

            { deactivatingAccount && <FullScreenLoader message="Deactivating..." /> }

            <Route exact path={`${match.url}/type`} render={() =>
                <TypeDeactivateReason
                    onClose={() => history.replace(match.url)}
                    onSubmit={(reason) => {history.replace(match.url); handleReasonClick(reason);}}
                /> 
            }/>
        </div>
    );
}

const mapStateToProps = state => ({
    deactivatingAccount: state.pageLoading
});

const mapDispatchToProps = {
    deactivateAccount: appOperations.deactivateAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeactivateReason));