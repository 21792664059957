import React from "react";
import PropTypes from 'prop-types';
import CustomCheckBox from "../CheckBox";
import './styles.css';

function InterestItem({interest, checked, disabled, onClick}) {
    const {_id, color, name} = interest;

    return (
        <div className={`InterestItem ${disabled ? 'disabled' : ''}`}>
            <div className="item-title"
                style={{
                    backgroundColor: color,
                    borderColor: color
                }}
                onClick={() => onClick(interest)}
            >
                {name}
            </div>
            <div className="item-checkbox">
                <CustomCheckBox
                    id={_id}
                    color={color}
                    value={name}
                    checked={checked}
                    handleOnChange={() => onClick(interest)}
                />
            </div>
        </div>
    );
}

export default  InterestItem;

InterestItem.propsTypes = {
    interest: PropTypes.object.isRequired, 
    checked: PropTypes.bool, 
    disabled: PropTypes.bool, 
    onClick: PropTypes.bool
}