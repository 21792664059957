import { connect } from 'react-redux';
import Interests from './Interests';
import { appOperations, appSelectors } from '../../../duck';

const mapDispatchToProps = {
    addUserInterests: appOperations.addUserInterests,
    getAllInterests: appOperations.getAllInterests,
};

const mapStateToProps = state => ({
    savingInterests: state.pageLoading,
    isAuthenticated: state.isAuthenticated,
    interests: state.schema.interests,
    token: appSelectors.getToken(state),
    authToken: appSelectors.getAuthToken(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Interests);