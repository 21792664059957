import React, { useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { getYouTubeVideoIdFromUrl } from "../../../../../../../../Util/index";

import Loader from "../../../../../../../components/Loader";
import LearningLabItem from "../../components/LearningLabItem";
import "./styles.css";

function LearningLabList({
  history,
  location,
  activeTab,
  authUser,
  fetchLearningLabContents,
  setLearningLabContentType,
  labContent,
  labContentLoading,
}) {
  let contentType = "Boss Up";
  const searchParams = queryString.parse(location.search);

  if (searchParams && searchParams.contentType !== -1)
    contentType = searchParams.contentType;

  useEffect(() => {
    if (activeTab._id !== "trending") {
      const contentTypeMap = {
        "Boss Up": "video",
        "Read To Lead": "article",
        "Read to Lead": "article",
        // "Cool Images & Memes": "imageMeme"
        "Cool Images & Memes": "image",
      };

      if (searchParams && searchParams.contentType !== -1)
        setLearningLabContentType(searchParams.contentType);

      fetchLearningLabContents(
        authUser.learningLabs[0]._id,
        contentTypeMap[contentType]
      );
    }
  }, []);

  function viewLearningLabItem(item) {
    const {
      _id,
      type,
      resource,
      title,
      videoUrl,
      coverUrl,
      imageUrl,
      source,
      createdAt,
      description,
    } = item;

    if (type == "document" && resource && resource.length) {
      window.open(resource);
      return;
    }

    let pageUrl = "/readArticle";
    let pageSearch = `?articleId=${_id}`;
    Object.entries(item).map(
      ([key, value]) => (pageSearch += `&${key}=${value}`)
    );

    history.push({
      pathname: pageUrl,
      search: pageSearch,
      state: { modal: true },
    });
  }

  if (labContentLoading)
    return (
      <div className="d-flex center-center">
        <Loader color={activeTab.color} />
      </div>
    );
  else if (!labContent) return null;
  else if (!labContent) {
    return <p>Failed to fetch content for {contentType}.</p>;
  } else if (!labContent.length) {
    return <p>No {contentType} items found.</p>;
  }

  return (
    <div className="LearningLabList">
      <div className="d-flex flex-wrap">
        {labContent.map((item, index) => {
          const { type, tag } = item;
          return (
            <LearningLabItem
              key={index}
              item={item}
              onClick={() => viewLearningLabItem(item)}
              onVideoClick={() =>
                history.push(
                  `/playYoutubeVideo/${getYouTubeVideoIdFromUrl(
                    item.resource
                  )}`,
                  {
                    modal: true,
                  }
                )
              }
            />
          );
        })}
      </div>
    </div>
  );
}

export default LearningLabList;

LearningLabList.propTypes = {
  authUser: PropTypes.object,
};
