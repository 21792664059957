import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import CustomRadioButton from "../../../components/RadioButton";
import CustomButton from "../../../components/Button";
import ToolBar from "../../../components/ToolBar";

import './styles.css';

function Anonymity(props){
    const [anonymity, setAnonymity] = useState(0);
    
    const handleOnChange = (e) => {
        setAnonymity(e.target.value);
    };

    const handleSubmit = () => {
        props.addUserAnonymity({ anonymity: parseInt(anonymity) }, props.history);
    };

    const publicMessage = "Public Profile: Your account username, image\n" +
        "and profile information are visible to everyone on RiseUp.\n" +
        "Anyone in the community can visit your profile.";
    const privateMessage = "Private Profile: Your account username, image and profile\n" +
        "information are not visible to everyone of RiseUp.\n" +
        "No one can visit your profile.";

    return (
        <div className="Anonymity">
            <ToolBar isAuthenticated={true} />
            <div className="contents">
                <div className="welcome-text brand-color-pinkish-text ">
                    <div>Thank you</div>
                    <div>for sharing</div>
                </div>
                <div className="instruction-text">
                    <div>One last thing!</div>
                    <div>
                        Would you like your account
                        to be public or private?
                    </div>
                </div>
                <div className="radio-button-group">
                    <CustomRadioButton
                        label="Public"
                        value={0}
                        defaultChecked
                        message={publicMessage}
                        handleOnChange={handleOnChange}
                    />
                    <CustomRadioButton
                        label="Private"
                        value={1}
                        message={privateMessage}
                        handleOnChange={handleOnChange}
                    />
                    <CustomButton
                        loading={props.savingAnonymity}
                        width="240px"
                        color="#e0493f"
                        handleOnClick={handleSubmit}
                    >
                        continue
                    </CustomButton>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Anonymity);

Anonymity.propTypes = {
    addUserAnonymity: PropTypes.func,
    history: PropTypes.object,
    savingAnonymity: PropTypes.bool,
    isAuthenticated: PropTypes.bool
};