import { get, find, filter } from 'lodash';

export const getActiveTab = state => get(state, "learningLab.activeTab", "");

export const getSummary = (state) => {
    return get(state, "learningLab.summary", null);
};

export const getSummaryLoading = (state) => {
    return get(state, "learningLab.summaryLoading", false);
};

export const getConent = (state) => {
    return get(state, "learningLab.content.items", null);
};

export const getContentLoading = (state) => {
    return get(state, "learningLab.contentLoading", false);
};

export const getItemById = (state) => {
    const selectedItemId = get(state, "learningLab.selectedItemId", null);
    const summary = getSummary(state);
    if(!summary || !selectedItemId)
        return null;

    return Object.values(summary).reduce((agg, item) => [...agg, ...item], []).find(({_id}) => _id === selectedItemId);
};