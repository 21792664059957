
import React from "react";
import {Link} from "react-router-dom";
import {Icon} from 'antd';
import PropTypes from 'prop-types';
import Opportunity from "../../../Opportunity";

/**
 * @return {null}
 */
function OpportunitiesSection (props) {
    const { sectionTitle, url, opportunities, theme, activeInterest } = props;

    const viewAllUrl = `${url}/${activeInterest._id}/all`;
    const viewAllDueTodayurl = `${url}/${activeInterest._id}/dueToday/all`;
    const viewDetailsBaseUrl = viewAllUrl.replace('view/', '');
    const dueTodayUrl =  `${url}/dueToday/${activeInterest._id}`;
    const finalUrl = sectionTitle === 'RECENT' ? viewDetailsBaseUrl : dueTodayUrl;
    const finalViewAll = sectionTitle === 'RECENT' ? viewAllUrl :  viewAllDueTodayurl;

    const renderOpportunities = opportunities =>
        opportunities.map(({resourceImg, title, deadlineDate, _id}) =>
            <Opportunity
            key={_id}
            imageUrl={resourceImg}
            url={`${finalUrl}/${_id}`}
            opportunityTitle={title}
            date={deadlineDate}
            id={_id}
        />);

    return opportunities.length > 0 ? (<section>
        <div className="section-title" style={{color: theme}}>{sectionTitle}</div>
        <div className="section-contents">
            {renderOpportunities(opportunities)}
        </div>
        { opportunities.length > 3 &&
        <Link to={finalViewAll}>
            <div className="view-all" style={{color: theme}}>
                <div>
                    View All &nbsp; <Icon type="arrow-right" />
                </div>
            </div>
        </Link>
        }
    </section>): null;
}

export default OpportunitiesSection;

OpportunitiesSection.propTypes = {
    sectionTitle: PropTypes.string,
    url: PropTypes.string,
    opportunities: PropTypes.array,
    theme: PropTypes.string,
    activeInterest: PropTypes.object
};
