import React from 'react';
import PropTypes from 'prop-types';

import bell from './bell.png';
import chat from './chat.png';

import add from './icons/add.png';
import counselling from './icons/counselling.png';
import counsellingFilled from './icons/counselling-filled.png';
import forums from './icons/forums.png';
import forumsFilled from './icons/forums-filled.png';
import opportunities from './icons/opportunities.png';
import opportunitiesFilled from './icons/opportunities-filled.png';
import learningLab from './icons/learning-lab.png';
import learningLabFilled from './icons/learning-lab-filled.png';

import './styles.css';

function RiseupIcon({size = "30px", icon, filled, black, className, ...props }){
    const icons = {
        articles: learningLab,
        article: learningLab,
        bell,
        chat,
        add,
        counselling,
        forums,
        forum: forums,
        opportunities,
        opportunity: opportunities,
        learningLab
    };
    
    const filledIcons = {
        add,
        counselling: counsellingFilled,
        forums: forumsFilled,
        opportunities: opportunitiesFilled,
        learningLab: learningLabFilled
    };

    let fullClassName = "riseup-icon " + className;
    if(black)
        fullClassName += " black";
        
    const src = filled ? filledIcons[icon] : icons[icon];

    return(
        <img className={fullClassName} {...props} src={src} alt="" width={size} />
    );
}
 
export default RiseupIcon;

RiseupIcon.propTypes = {
    size: PropTypes.string,
    icon: PropTypes.string
};