import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import NavItem from "./components/NavItem";
import RiseupIcon from '../RiseupIcon';

import './styles.css';

function NavigationBar ({hideBottomNav, isLogin, history, location}){
    const activeRoute = location.pathname;

    function navItems(){
        var items = [
            {
                icon: <RiseupIcon icon="forums" filled={activeRoute.indexOf("/main/forums") !== -1} />,
                url: "/main/forums",
                label : "Forums"
            },
            {
                icon: <RiseupIcon icon="opportunities" filled={activeRoute.indexOf("/main/opportunities/view") !== -1} />,
                url: "/main/opportunities/view",
                label : "Opportunities"
            },
            {
                icon: <RiseupIcon icon="add" />,
                url: "/main/opportunities/new",
            },
            {
                icon: <RiseupIcon icon="learningLab" filled={activeRoute.indexOf("/main/learningLab") !== -1} />,
                url: "/main/learningLab",
                label : "LearningLab"
            },
            {
                icon: <RiseupIcon icon="counselling" filled={activeRoute.indexOf("/main/counseling") !== -1} />,
                url: "/main/counseling",
                label : "Counseling"
            }
        ];

        return (
            <div className={`NavigationBar ${hideBottomNav ? 'hidden' : ''}`}>
                {
                    items.map((item) => (
                        <NavItem
                            key={item.url}
                            {...item}
                            isActive={activeRoute.indexOf(item.url) !== -1}
                            onClick={() => history.push(item.url)}
                        />
                    ))
                }
            </div>
        );
    }

    return isLogin ? navItems() : null;
}

export default withRouter(NavigationBar);

NavigationBar.propTypes = {
    isLogin: PropTypes.bool,
    location: PropTypes.object,
    history: PropTypes.object,
    hideBottomNav: PropTypes.bool
};