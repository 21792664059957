import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import ToolBar from "../../../App/components/ToolBar";

const IframePage = (
    {
        isAuthenticated,
        isLogin,
        theme,
        history
    }
) => {
    const { url } = useParams();
    const [loading, setLoading] = useState(true);
    
    const handleLoad = () => {
        setLoading(false);
    };
    
    return (
        <>
            <div className="position-relative" style={{overflow: 'hidden'}}>
                <div className="flower-deco"/>
                <ToolBar
                    isAuthenticated={isAuthenticated}
                    isLogin={isLogin}
                    theme={theme}
                    noActions
                    onBack={history.goBack}
                />
            </div>
            {loading && <p style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Loading..</p>}
            <iframe
                src={decodeURIComponent(url)}
                title={'opp-link'}
                className={"SiteIframe"}
                width={"100%"}
                onLoad={handleLoad}
            />
        </>
    );
}

export default IframePage;
