import { connect } from 'react-redux';
import LearningLabList from './LearningLabList';
import { learningLabSelectors, learningLabOperations } from '../../../../duck';
import { appSelectors } from '../../../../../../../duck';

const mapStateToProps = state => ({
    authUser: appSelectors.getAuthUser(state),
    activeTab: learningLabSelectors.getActiveTab(state),
    labContent: learningLabSelectors.getConent(state),
    labContentLoading: learningLabSelectors.getContentLoading(state),
});

const mapDispatchToProps = {
    setLearningLabContentType: learningLabOperations.setContentType,
    fetchLearningLabContents: learningLabOperations.fetchLearningLabContents
};

export default connect(mapStateToProps, mapDispatchToProps)(LearningLabList);