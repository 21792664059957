
import * as actions from './actions';
import {checkEmail, checkUsername} from '../../../../../API';

const generateNIckNameErrorMessage = count => {
    switch (count) {
        case -1:
            return 'Username should be one word without space eg. cuterose';
        case 1:
            return 'Oops! This username is already taken.\n' +
                'Please try again';
        case 2:
            return 'Oops! This username is taken too.\n' +
                'Try adding numbers or your last name\n' +
                'to your username.';
        case 3:
            return 'Don\'t worry! You can try again.\n' +
                'Try mixing up your first and last name\n' +
                'and also maybe add\n' +
                'your birth date or any other number.';
        default:
            return 'This is also taken but don\'t worry,\n' +
                'you are almost there. Try again.';
    }

};



const setAttempts = current => {
    const attempts = `${current + 1}`;
    localStorage.setItem('attempts', attempts);
};

const getAttempts = () => {
    const attempts = localStorage.getItem('attempts');
    return +attempts;
};

export const checkNickNameAvailability = (payload) => (dispatch, getState) => {
    dispatch(actions.setNickNameStart(payload));
    if (/\s/.test(payload)) {
        // It has any kind of whitespace
        const message = generateNIckNameErrorMessage(-1);
        dispatch(actions.setNickNameFailed({ attempts: -1, message }));
        return;
    }
    // if nickname is empty dont call API
    if (payload === "") {
        dispatch(actions.setNickNameEmpty());
        return;
    }
    checkUsername(payload)
        .then( res => {
            const { statusCode } = res;
            if (statusCode === 6100) {
                dispatch(actions.setNickNameSuccess(payload));
                if (payload.length === 0) {
                    dispatch(actions.setNickNameEmpty());
                }
            }

            else if (statusCode === 6504) {
                const attempts = getAttempts();
                const message = generateNIckNameErrorMessage(attempts);
                setAttempts(attempts);

                dispatch(actions.setNickNameFailed({ attempts, message }));

            }
        });
};

export const checkEmailAvailability = (payload) => (dispatch, getState) => {
    dispatch(actions.setEmailStart());
    if (/\s/.test(payload)) {
        // It has any kind of whitespace
        dispatch(actions.setEmailEmpty());
        return;
    }
    checkEmail(payload)
        .then( res => {
            const { statusCode } = res;
            if (statusCode === 6100) {
                dispatch(actions.setEmailSuccess(payload));
                if (payload.length === 0) {
                    dispatch(actions.setEmailEmpty());
                }
            }

            else if (statusCode === 6504) {
                const message = "Email exists";

                dispatch(actions.setEmailFailed({ message }));

            }
        });
};
