import React from 'react';
import { Route } from 'react-router-dom';

import AlertDialog from '../../../../../components/FlNotifications/AlertDialog';
import ToolBar from '../../../../../components/ToolBar';
import Switch from '../../../../../components/Switch';
import Button from '../../../../../components/Button';

import { lightenDarkenColor } from '../../../../../utils';

import UserDp from './components/UserDp';
import DeactivateReason from './DeactivateReason';
import "./styles.css";
import ActionButton from '../../../../../components/ActionButton';

const ViewProfile = ({authUser, match: { url }, location, history, activeProfileEditFields, updateDetails, logout}) => {
    const {
        profilePic,
        userName, 
        anonymity, 
        firstName, 
        lastName
    } = authUser;

        
    console.log("authUser =============================>");
    console.log(authUser);
    
    function handleChangeAnonymityClicked(){
        updateDetails({ anonymity: anonymity ? 0 : 1, editField: 'anonymity' }, history);
    }
    
    function handleDpChanged(profilePic){
        updateDetails({ profilePic, editField: 'Profile picture' }, history);
    }

    function getEmploymentText(value){
        const employmentStatus = ['Not Employed', 'Self Employed', 'Employed', 'Student'];
        const employmentStatusIndex = employmentStatus.indexOf(value);
        
        if(employmentStatusIndex === -1)
            return '';

        switch (employmentStatusIndex) {
            case 0:
                return 'unemployed ';
            case 2:
                return 'hard working ';
            default:
                return value.toLowerCase() + ' ';
        }
    }
    
    function getEducationLevelText(value){
        const educationLevels = ['Primary', 'Secondary (O-level)', 'High School (A-level)', 'College', 'University', 'None of the above'];
        const levelTexts = ['primary school', 'secondary school', 'high school', 'college', 'university', ''];
        const educationLevelIndex = educationLevels.indexOf(value);

        if(educationLevelIndex === -1 || educationLevelIndex === 5)
            return "";

        return `${levelTexts[educationLevelIndex]} graduate, `;
    }

    function createMoreDetailsText(){
        const {
            dob = '1995-12-23',
            country = 'Tanzania', 
            region = 'Dar es Salaam', 
            district = 'Ilala', 
            educationLevel = 'High School (A-level)',
            employmentStatus = 'Employed'
        } = authUser;
        const age = new Date().getFullYear() - new Date(dob).getFullYear();

        let moreDetailsText = "A ";
        moreDetailsText += `${age} year old, `;
        moreDetailsText += getEducationLevelText(educationLevel);
        moreDetailsText += getEmploymentText(employmentStatus);
        moreDetailsText += `girl from ${district}, ${region}, ${country}.`;

        return moreDetailsText;
    }

    function renderInterests(){
        return (
            <div className="d-flex items-center flex-wrap">
                {
                    authUser.interests.map(({name, color}) => <Button key={name} className="interest-button" style={{background: color, borderColor: lightenDarkenColor(color, -25)}}>{name}</Button>)
                }
            </div>
        )
    }
    
    function renderLearningLabs(){
        return (
            <div className="d-flex items-center flex-wrap">
                {
                    authUser.learningLabs.map(({name, color}) => <Button key={name} className="interest-button" style={{background: color, borderColor: lightenDarkenColor(color, -25)}}>{name}</Button>)
                }
            </div>
        )
    }

    const logoutButton = () => {
        return (
            <Button handleOnClick={() => logout(history)}>
                Logout
            </Button>
        );
    }
    
    return (
        <div id="Profile" className="position-fixed pin-all z10">
            <ToolBar
                isAuthenticated={true} isLogin={true}
                title="Profile"
                theme="#e65f58"
                onBack={() => history.goBack()}
                renderActions={logoutButton}
            />

            <div className="content">
                <div className="profileSection">
                    <div className="d-flex items-center justify-content-center">
                        <UserDp 
                            profilePic={profilePic}
                            savingProfilePic={activeProfileEditFields.includes("Profile picture")}
                            onChange={handleDpChanged} />

                        <div className="d-fle flex-col items-center justify-content-center">
                            <ul>
                                <li>
                                    <h5>
                                        <span style={{fontWeight: '300', color: "#505050"}}>Hey</span> Mrembo,
                                    </h5>
                                    <p>You are incredible.</p>
                                </li>
                            </ul>
                        </div> 
                    </div>
                </div>

                <div id="anonymitySection" className="profileSection">
                    <ul>
                        <li>
                            <div className="d-flex items-center justify-between">
                                <h5>Anonymity</h5>
                                <Switch 
                                    checked={anonymity} 
                                    loading={activeProfileEditFields.includes("anonymity")}
                                    onChange={handleChangeAnonymityClicked} />
                            </div>
                            { anonymity === 1 &&
                                <p>
                                    Turn off to make your profile public.
                                </p>
                            }
                            
                            { anonymity === 0 &&
                                <p>
                                    Turn on to make your profile private.
                                </p>
                            }
                        </li>
                    </ul>
                </div>

                { authUser.interests && 
                    <div className="profileSection interestsSection">
                        <h3 className="d-flex center">
                            Opportunity Interests 
                            <ActionButton flat onClick={() => history.push(`${url.replace('/view', '')}/edit/interests`)}>
                                <svg viewBox="0 0 24 24" width="10"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            </ActionButton>
                        </h3>
                        <ul>
                            <li>
                                <h5>
                                    { renderInterests() }
                                </h5>
                            </li>
                        </ul>
                        {/* <Button flat handleOnClick={() => history.push(`${url.replace('/view', '')}/edit/interests`)}>
                            <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            Change
                        </Button> */}
                    </div>
                }

                { authUser.learningLabs && authUser.learningLabs.length > 0 && (
                    <div className="profileSection interestsSection">
                        <h3 className="d-flex center">
                            Content Interests

                            <ActionButton flat onClick={() => history.push(`${url.replace('/view', '')}/edit/labs`)}>
                                <svg viewBox="0 0 24 24" width="10"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            </ActionButton>
                        </h3>

                        <ul>
                            <li>
                                <h5>
                                    { renderLearningLabs() }
                                </h5>
                            </li>
                        </ul>

                        {/* <Button flat handleOnClick={() => history.push(`${url.replace('/view', '')}/edit/labs`)}>
                            <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            Change
                        </Button> */}
                    </div>
                )}
                
                
                <div className="profileSection">
                    <h3 className="d-flex center">
                        Basic Details

                        <ActionButton flat onClick={() => history.push(`${url.replace('/view', '')}/edit/basicDetails`)}>
                            <svg viewBox="0 0 24 24" width="10"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                        </ActionButton>
                    </h3>
                    <ul>
                        <li>
                            <h5>Full Name</h5>
                            <p>{firstName} {lastName}</p>
                        </li>
                        <li>
                            <h5>Username</h5>
                            <p>{userName}</p>
                        </li>
                    </ul>

                    {/* <Button flat handleOnClick={() => history.push(`${url.replace('/view', '')}/edit/basicDetails`)}>
                        <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                        Change
                    </Button> */}
                </div>
                
                <div className="profileSection">
                    <h3 className="d-flex center">
                        More Details

                        <ActionButton flat onClick={() => history.push(`${url.replace('/view', '')}/edit/demographics`)}>
                            <svg viewBox="0 0 24 24" width="10"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                        </ActionButton>
                    </h3>
                    <ul>
                        <li>
                            <p>
                                { createMoreDetailsText() }
                            </p>
                        </li>
                    </ul>

                    {/* <Button flat handleOnClick={() => history.push(`${url.replace('/view', '')}/edit/demographics`)}>
                        <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                        Change
                    </Button> */}
                </div>

                <div className="profileSection">
                    <h3 className="d-flex center">
                        Your Dream

                        <ActionButton flat onClick={() => history.push(`${url.replace('/view', '')}/edit/dream`)}>
                            <svg viewBox="0 0 24 24" width="10"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                        </ActionButton>
                    </h3>

                    <ul>
                        <li>
                            <p>
                                { authUser.dream }
                            </p>
                        </li>
                    </ul>

                    {/* <Button flat handleOnClick={() => history.push(`${url.replace('/view', '')}/edit/dream`)}>
                        <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                        Change
                    </Button> */}
                </div>

                <div id="deactivateAccountButtonWrapper" className="profileSection">
                    <h3 className="d-flex center justify-between">
                        RiseUp Account

                        <Button flat handleOnClick={() => history.push(`${url}/confirmDeactivate`)}>
                            Deactivate
                        </Button>
                    </h3>

                    <ul>
                        <li>
                            <p>
                                { authUser.email }
                            </p>
                        </li>
                    </ul>

                    {/* <Button flat handleOnClick={() => history.push(`${url}/confirmDeactivate`)}
                        width="100%">
                        Deactivate
                    </Button> */}
                </div>

            </div>

            <Route exact path={`${url}/confirmDeactivate`}
                component={_ => (
                    <AlertDialog
                        cancelText={"No, Cancel"} okText={"Yes, Deactivate"}
                        onOkay={() => history.replace(`${url}/deactivate/reason`)} 
                        onCancel={history.goBack}
                    >
                        <h2>Are you sure you want to deactivate your RiseUp account?</h2>
                        {/* <p>When you deactivate your account you'll lose access all fun RiseUp stuff.</p> */}
                    </AlertDialog>
                )}
            />
            
            <Route path={`${url}/deactivate/reason`} component={DeactivateReason} />
        </div>
    );
}
 
export default ViewProfile;