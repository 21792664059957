import { connect } from 'react-redux';
import OpportunitiesBasedOnOtherInterest from "./OpportunitiesBasedOnOtherInterest";
import { opportunitiesOperations, opportunitiesSelectors } from '../../../../../../duck';

const mapDispatchToProps = {
    getOtherOpportunities: opportunitiesOperations.getOtherOpportunities,
};

const mapStateToProps = state => ({
    otherOpportunitiesLoading: opportunitiesSelectors.getOtherOpportunitiesLoading(state),
    otherOpportunities: opportunitiesSelectors.getOtherOpportunities(state),
    activeInterest: opportunitiesSelectors.getActiveInterest(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunitiesBasedOnOtherInterest);