import React, { useState, useRef } from 'react';
import 'emoji-matt/css/emoji-mart.css'
import { Picker } from 'emoji-matt/dist-modern/index.js'

import ActionButton from "../../../../../../../components/ActionButton";
import { getYouTubeVideoIdFromUrl } from "../../../../../../../../Util";
import "./styles.css";

const ForumMessageComposer = ({placeholder="Type message here", canSend, onSendMessage, onImageSelected, onInputFocusChanged}) => {
    const inputRef = useRef(null);
    const searchInputRef = useRef(null);
    const [message, setMessage] = useState("");
    const [searchingEmojis, setSearchingEmojis] = useState(false);
    const [showEmojis, setShowEmojis] = useState(false);

    function setShowEmojisState(status, autoFocusInput = true){
        setShowEmojis(status);
        if(!status){
            setSearchingEmojis(false);
            if(autoFocusInput)
                inputRef.current.focus();
        }
    }

    function onEmojiSelected(emoji){
        setMessage(message + emoji.native);
        if(inputRef && inputRef.current){
            const node = inputRef.current;
            node.scrollTo(node.scrollWidth + node.offsetLeft, 0);
        }

        if(searchingEmojis && searchInputRef && searchInputRef.current)
            searchInputRef.current.focus();
    }

    const handleInputFocusChanged = (status, e) => {
        // Without setTimeout input misbehaves onBlur
        setTimeout(() => {
            if(status){
                setShowEmojisState(false, e);
                onInputFocusChanged(true);
            }
            else if(!showEmojis)
                onInputFocusChanged(false);
        });
    }
    
    const handleBackspaceClicked = () => {
        if(message.length > 0)
            setMessage(message.substring(0, message.length - 2));
    }
    
    const handleSearchTriggerClicked = () => {
        if(searchInputRef && searchInputRef.current)
            searchInputRef.current.focus()
    }

    const handleSendMessage = (e) => {
        e.preventDefault();
        setShowEmojisState(false, false);

        const videoId = getYouTubeVideoIdFromUrl(message);
        if(videoId)
            onSendMessage(message.trim(), 'youtube', `https://www.youtube.com/watch?v=${videoId}`);
        else 
            onSendMessage(message.trim());

        setMessage("");
    }

    let className = showEmojis ? 'show-emojis' : '';
    className += searchingEmojis ? ' searching-emojis' : '';
    
    return (
        <div id="ForumMessageComposer" className={className}>
            <form className="d-flex items-center"
                onSubmit={handleSendMessage}>
                
                <input style={{display: 'none'}}
                    type="file" id="forumMessageImagePicker" 
                    accept="image/x-png,image/jpeg,image/jpg,image/webp,.jpeg,.jpg,.png,.webp"
                    onChange={(e) => onImageSelected(e.target.files)} />

                { onImageSelected && typeof onImageSelected === 'function' &&
                    <ActionButton type="button">
                        <label htmlFor="forumMessageImagePicker">
                            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        </label>
                    </ActionButton>
                }

                <div className="position-relative">
                    <input ref={inputRef} type="text" placeholder={placeholder}
                        value={message}
                        onBlur={(e) => handleInputFocusChanged(false, e)}
                        onFocus={(e) => handleInputFocusChanged(true, e)}
                        onChange={(e) => setMessage(e.target.value)} />

                    <ActionButton type="button" onClick={(e) => setShowEmojisState(!showEmojis, e)}>
                        <>
                            { !showEmojis && <svg id="emojiIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg> }
                            { showEmojis && <svg id="keyboardIcon" width="24" height="24" viewBox="0 0 24 24"><path d="M20 5H4c-1.1 0-1.99.9-1.99 2L2 17c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-9 3h2v2h-2V8zm0 3h2v2h-2v-2zM8 8h2v2H8V8zm0 3h2v2H8v-2zm-1 2H5v-2h2v2zm0-3H5V8h2v2zm9 7H8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2V8h2v2zm3 3h-2v-2h2v2zm0-3h-2V8h2v2z"/><path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none"/></svg> }
                        </>
                    </ActionButton>
                </div>

                <ActionButton type="submit" disabled={(!message.length && !canSend) || typeof onSendMessage !== 'function'}>
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                </ActionButton>
            </form>

            <div style={{pointerEvents: `${!showEmojis ? 'none' : ''}`}}>
                <Picker native={true} 
                    onSearchFocused={() => setSearchingEmojis(true)} 
                    onSelect={onEmojiSelected}
                    searchInputRef={ref => searchInputRef.current = ref} />

                <div id="emojiActions" className="d-flex">
                    <ActionButton onClick={handleSearchTriggerClicked}>
                        <svg fill="currentColor" viewBox="0 0 20 20"><path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" /></svg>
                    </ActionButton>
                    
                    <ActionButton onClick={handleBackspaceClicked}>
                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15"/><line x1="12" y1="9" x2="18" y2="15"/></svg>
                    </ActionButton>
                </div>
            </div>
        </div>
    );
}
 
export default ForumMessageComposer;