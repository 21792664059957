
import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import ToolBar from "../../../components/ToolBar";
import {validateEmail} from "../../../utils";
import {checkEmail} from "../../../../API";

import EmailForm from "./components/EmailForm";
import PickNickNameForm from './components/Form'

import './styles.css';

class PickNickName extends  React.Component{
    componentDidMount() {
        const { authToken } = this.props;
        this.setState({
            startedWithoutEmail: authToken.email === null,
            emailAvailable: authToken.email
        });
    }

    state = {
        emailAvailable: false,
        emailIsValid: null,
        emailExists: false,
        email: '',
    };

    setEmail = async (email) => {
        if (validateEmail(email)) {
            this.setState({email, emailIsValid: true});

            try {
                const {statusCode} = await checkEmail(email);
                this.setState({ emailExists: statusCode === 6504 });
            } catch (error) {
                console.log("Failed to check email availabitlity");
            }
        }
        else
            this.setState({ email, emailIsValid: false, emailExists: false });
    };

    handleEmailSubmitted = () => {
        this.setState({ emailAvailable: true });
    };

    handleNickNameSubmitted = (e) => {
        e.preventDefault();

        const { nickName: userName, authToken, history } = this.props;
        const { email } = this.state;
        
        let payload = { ...authToken, userName };
        if (!authToken.email)
            payload.email = email;

        this.props.signup(payload, history);
    };

     renderForm = (emailAvailable) => {
         if(emailAvailable) 
            return (
                <PickNickNameForm 
                    {...this.props}
                    validationMessage={this.props.nickNameValidationMessage}
                    comingFromEmail={this.state.startedWithoutEmail} 
                    onSubmit={this.handleNickNameSubmitted} 
                />
            );
        else
            return (
                <EmailForm
                    {...this.props}
                    emailIsValid={this.state.emailIsValid}
                    emailExists={this.state.emailExists}
                    onEmailChanged={this.setEmail}
                    onSubmit={this.handleEmailSubmitted}
                />
            );
     };

     render() {
        const { emailAvailable } = this.state;

        return (
            <div className="PickYourUsername">
                <ToolBar isAuthenticated={true}/>
                { this.renderForm(emailAvailable)}
            </div>
        );
     }
}

export default  withRouter(PickNickName);

PickNickName.propTypes = {
    signup: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    authenticatingUser: PropTypes.bool,
    checkNickNameAvailability: PropTypes.func,
    checkEmailAvailability: PropTypes.func,
    nickName: PropTypes.string,
    nickNameAvailable: PropTypes.bool,
    nickNameValidationMessage: PropTypes.string,
    authToken: PropTypes.object,
    history: PropTypes.object
};
