import { connect } from 'react-redux';
import {opportunitiesOperations, opportunitiesSelectors} from "../../../../duck";
import AllInterestOpportunities from "./AllInterestOpportunities";


const mapDispatchToProps = {
    getOpportunities: opportunitiesOperations.getAllOpportunities,
};

const mapStateToProps = state => ({
    allOpportunities: opportunitiesSelectors.getAllOpportunities(state),
    totalOpportunities: opportunitiesSelectors.getTotalOpportunitiesCount(state),
    loading: opportunitiesSelectors.getAllOpportunitiesLoading(state),
    activeInterest: opportunitiesSelectors.getActiveInterest(state),
    currentPage: opportunitiesSelectors.getOpportunitiesCurrentPage(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(AllInterestOpportunities);
