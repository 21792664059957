import {format} from "date-fns";

import lightenDarkenColor from "./lightenDarkenColor";

export { lightenDarkenColor };

export const learningLabColorCodes = {
    games: "#26ABC5",
    health: "#D78D7C",
    innovation: "#ED8089",
    enterprenuership: "#FA4C5B",
    skills: "#62D2C4",
}

/**
 * Gets an object from an array using its id
 * objects in the array must have _id property
 *
 * @function
 * @name getHumanDiffDate
 * @param {string} date - ISO date string
 * @returns {String} - formatted human readable date
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getHumanDiffDate = (date) => {
    try {
        date = new Date(date);
        const today = new Date();
        if(date.getFullYear() !== date.getFullYear())
            date = format(date, "MMM do, YYYY");
        else if(date.getMonth() === today.getMonth()){
            const dayDiff = date.getDate() === today.getDate();
            if(dayDiff === 0)
                date = "Today";
            else if(dayDiff === 1)
                date = "Yesterday";
            else
                date = format(date, "MMM do");
        }
        else
            date = format(date, "MMM do");

        return date;
    } catch (error) {
        return "Unknown date"
    }
};

/**
 * Gets an object from an array using its id
 * objects in the array must have _id property
 *
 * @function
 * @name getObjectByIdFromArray
 * @returns {Object} - object that matches id provided
 *
 * @version 0.1.0
 * @since 0.1.0
 */
export const getObjectByIdFromArray = (array, id) => array.filter(({_id }) =>  _id === id)[0];


/**
 * formats date by making it human readable
 * @function
 * @name formatDate
 * @param {string} date - ISO date string
 * @returns {string} - formatted date
 * */
export const formatDate = date => {
    try {
        return format(new Date(date), 'MMMM do yyyy');
    }
    catch (e) {
        console.log("Error formatting date: ", e);
    }
};

/**
 * checks validaity of a url
 * @function
 * @name isValidUrl
 * @param {string} url - url to be validated
 * @returns {Boolean} - validity of url
 * */
export function isValidUrl(url){
    var validUrlRegex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
    return validUrlRegex.test(url);
}

/**
 * gets video id from youtube video url
 * @function
 * @name getYouTubeVideoIdFromUrl
 * @param {string} url - video url
 * @returns {string} - youtube video id
 * */
export function getYouTubeVideoIdFromUrl(url){
    if(!url)
        return null;
        
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    const youtubeRegex = /(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/;
    if(!youtubeRegex.test(url))
        return null;

    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
}

/**
 * copies provided text to clipboard
 * @function
 * @name copyTextToClipboard
 * @param {string} text - text to be copied
 * */
export const copyTextToClipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = async function(text) {
        if(window.navigator && window.navigator.clipboard){
            try {
                const clipboardText = await navigator.clipboard.writeText(text);
            } catch (error) {
                console.error('Failed to read clipboard contents: ', error);
            }
        }
        else{
            selectText();
            copyToClipboard();
            createTextArea(text);
        }
    };

    return copy;
})(window, document, navigator);