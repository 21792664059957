import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize';
import TextareaAutosize from 'react-textarea-autosize';

import ActionButton from '../../../../components/ActionButton';
import CustomButton from "../../../../components/Button";

import "./styles.css";

const WriteDream = (props) => {
    const [dream, setDream] = useState('');
    let textArea;

    useEffect(() => {
        const authUser = props.authUser;
        if(authUser && authUser.dream)
            setDream(authUser.dream);
    }, [props.authUser]);
    
    useEffect(() => {
        textArea.focus()
        autosize(textArea);
        
        return () => autosize.destroy(textArea);
    }, [textArea]);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.submit(dream);
    };

    const handleOnChange = (e) => {
        setDream(e.target.value);
        autosize.update(textArea);
    };

    let indicatorClass = "";
    let wordCount = dream.length > 0 ? dream.trim().split(' ').length : 0;
    const remainingWords = 50 - wordCount;
    if(remainingWords <= 0){
        indicatorClass = "brand-color-pink-text"; //error
    }
    else if(remainingWords > 0 && remainingWords <= 5)
        indicatorClass = "brand-color-orangeish-text"; //warning

    
    return (
        <form className="WriteDream position-fixed pin-all d-flex flex-col"
            onSubmit={handleSubmit}>
            <div id="toolbar" className="d-flex items-center justify-between">
                <div className="d-flex items-center">
                    <ActionButton type="button" onClick={() => window.history.back()}>
                        <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" stroke="#555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="15 18 9 12 15 6"></polyline></svg>
                    </ActionButton>
                    &nbsp;
                    <span className="toolbar-title">
                        Your dream
                    </span>
                </div>
                
                <CustomButton
                    loading={props.saving}
                    disabled={wordCount < 1 || remainingWords < 0}
                    type="submit"
                    width="100%"
                    color="#e0493f" >
                    Save
                </CustomButton>
            </div>

            <div className="content d-flex justify-start flex-col">
                <TextareaAutosize 
                    placeholder="Write your dream here"
                    minRows={3}
                    inputRef={tag => (textArea = tag)}
                    value={dream}
                    onChange={handleOnChange} />

                <div className="word-counter position-absolut pin-bottom pin-right d-inline-flex items-center justify-end">
                    <span className={indicatorClass}>{wordCount}</span>&nbsp;&nbsp;/&nbsp;&nbsp;<strong>50</strong>
                </div>
            </div>
        </form>
    );
}
 
export default WriteDream;

WriteDream.propTypes = {
    saving: PropTypes.bool,
    submit: PropTypes.func
};