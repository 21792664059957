import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../../../../../../../components/Button';

import "./styles.css";

const JoinForumPreview = ({history, match, forum, setPreviewedForumId, joinForum}) => {
    useEffect(() => {
        const { params } = match;

        if(!params || !params.forumId)
            history.goBack();
        else
            setPreviewedForumId(params.forumId);
    }, [match, history, setPreviewedForumId]);

    if(!forum) return null;

    const {image, title, description, joining} = forum;

    return (
        <div className="JoinForumPreview">
            <div className="JoinForumPreviewDialog" onClick={() => history.goBack()} />
            
            <div className="content">
                <div className="image">
                    <img src={image} alt="Forum cover" />
                </div>
                <h3>{title}</h3>
                <p>{description}</p>
                
                <div className="actionButtons d-flex">
                    <CustomButton
                        disabled={joining}
                        className="cancelButton"
                        color="#e0493f" 
                        handleOnClick={() => history.goBack()}>
                        Cancel
                    </CustomButton>
                    
                    <CustomButton
                        loading={joining}
                        color="#e0493f" 
                        handleOnClick={() => joinForum(forum._id, history)}>
                        Start Chatting
                    </CustomButton>
                </div>
            </div>
        </div>
    );
}
 
export default JoinForumPreview;

JoinForumPreview.propTypes = {
    forum: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    setPreviewedForumId: PropTypes.func,
    joinForum: PropTypes.func
};