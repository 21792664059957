import React, { useState, useEffect } from 'react';
import { getInterests } from '../../../../../../API';
import PropTypes from 'prop-types';

import { AuthenticatedUserToolBar } from '../../../../../components/ToolBar';
import InterestItem from '../../../../../components/InterestItem';
import CustomButton from '../../../../../components/Button';
import AlertDialog from '../../../../../components/FlNotifications/AlertDialog';
import RaisedSurface from '../../../../../components/RaisedSurface';
import Loader from '../../../../../components/Loader';

import "./styles.css";

function EditInterests({authUser, history, activeProfileEditFields, saveInterests}){
    const [interests, setInterests] = useState([]);
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [interestFetchState, setInterestFetchState] = useState("idle");

    useEffect(() => {
        fetchInterests();
    }, []);
    
    useEffect(() => {
        setSelectedInterests(authUser.interests);
    }, [authUser]);

    const fetchInterests = async () => {
        setInterestFetchState("fetching");
        try {
            const res = await getInterests();
            setInterests(res.data.interests);
            setInterestFetchState("idle");
        } catch (error) {
            setInterestFetchState("error");
        }
    }

    const handleSubmit = () => {
        saveInterests(selectedInterests, history);
    };

    const selectInterest = (interest) => {
        setSelectedInterests([...selectedInterests, interest]);
    };

    const deselectInterest = (interest) => {
        setSelectedInterests(selectedInterests.filter(({_id}) => interest._id !== _id));
    };

    const renderInterests = () => interests.map(interest => {
        const isChecked = selectedInterests.find(({_id}) => interest._id === _id) != null;

        return (
            <InterestItem
                key={interest._id}
                interest={interest}
                checked={isChecked}
                disabled={!isChecked && selectedInterests.length === 3}
                onClick={!isChecked ? selectInterest : deselectInterest}
            />
        )
    });

    return (
        <div id="EditInterests" className="position-fixed pin-all z10">
            <AuthenticatedUserToolBar
                theme="#e65f58"
                title="Edit Interests"
                isAuthenticated={true}
                onBack={() => history.goBack()} 
                noActions
            />

            <div id="EditInterestsContent">
                { interestFetchState === "fetching" && <Loader className="FetchingInterestsLoader" fill size={100} /> }

                { interestFetchState === "error" && ( 
                    <AlertDialog
                        message="Failed to fetch interests"
                        onOkay={fetchInterests}
                        okText="Retry"
                    /> 
                )}

                { interests.length > 0 && (
                    <div className="contents">
                        <div className="instructions-text">Pick Three(3)</div>
                        <div className="interests-form">
                            { renderInterests(interests) }

                            <RaisedSurface>
                                <CustomButton
                                    width="100%"
                                    color="#e0493f"
                                    loading={activeProfileEditFields.includes("interests")}
                                    disabled={selectedInterests.length < 3}
                                    handleOnClick={handleSubmit}
                                >
                                    Save Interests
                                </CustomButton>
                            </RaisedSurface>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EditInterests;

EditInterests.propTypes = {
    history: PropTypes.object,
    savingInterests: PropTypes.bool,
    addUserInterests: PropTypes.func
};