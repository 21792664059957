import { connect } from 'react-redux';
import { appOperations } from '../../../duck';

import Dream from './Dream';

const mapDispatchToProps = {
    addUserDream: appOperations.addUserDream,
};

const mapStateToProps = state => ({
    savingDream: state.pageLoading,
    isAuthenticated: state.isAuthenticated,
    nickName: state.nickName && state.nickName.data
});

export default connect(mapStateToProps, mapDispatchToProps)(Dream);