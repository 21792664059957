import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BottomSheetOptions from "../../../../../../../components/BottomSheetOptions";
import { forumSelectors, forumOperations } from "../../../../../Forums/duck";
import avatar from "../../../../../../../../images/ashura.png";
import { getYouTubeVideoIdFromUrl } from "../../../../../../../../Util";
import { message } from "antd";
import { appSelectors } from "../../../../../../../duck";
import { sendForumMessage } from "../../../../../../../../API";

function shareMessageOptions(userForums) {
  if (!userForums || !userForums.length) return [];

  return userForums.map(({ _id, title, image, type }) => {
    return {
      _id,
      type,
      label: title,
      image: type === "main-forum" ? avatar : image,
    };
  });
}

function ShareOptions({
  authUser,
  accessToken,
  selectedItem,
  fetchingForums,
  userForums,
  fetchForums,
  onClose,
}) {
  const [savingMessage, setSavingMessage] = useState(false);

  useEffect(() => {
    if (!userForums && !fetchingForums) fetchForums();
  }, []);

  function getMessagePayload(forum, message, mediaType, media) {
    return {
      forum: forum._id,
      userId: authUser._id,
      nickName: !authUser.anonymity
        ? authUser.userName || "Mrembo Flani"
        : authUser.privateName || "Mrembo Flani",
      message,
      mediaType,
      media,
      messageKey: forum._id + "/#/" + Math.random().toString(36).slice(2), //random key
      sentAt: new Date().getTime(),
    };
  }

  async function handleShareMessage(forums) {
    if (selectedItem) {
      const {
        _id,
        type,
        title,
        description,
        videoUrl,
        imageUrl,
        coverUrl,
        pdfUrl,
        resource,
      } = selectedItem;

      let messageParams = {
        articleId: _id,
        type,
        title,
        resource,
        description: description ? description.substring(0, 20) : "",
      };

      if (type === "video") {
        messageParams.image = `https://i.ytimg.com/vi/${getYouTubeVideoIdFromUrl(
          resource
        )}/maxresdefault.jpg`;
        messageParams.isVideo = true;
      } else if (type === "image" || type === "imageMeme")
        messageParams.image = resource;
      else if (type === "article" && (!pdfUrl || !pdfUrl.length))
        messageParams.image = coverUrl;

      const messageDetails = [
        `Check out this awesome ${type} from the learning lab.`,
        "learning-lab-item-item",
        JSON.stringify(messageParams),
      ];

      setSavingMessage(true);
      try {
        await Promise.all(
          forums.map((forum) => {
            const payload = getMessagePayload(forum, ...messageDetails);
            return sendForumMessage(payload, accessToken);
          })
        );
        setSavingMessage(false);
        message.success("Content shared to RiseUp forums");
        onClose(true);
      } catch (error) {
        console.log("Failed to share to RiseUp forums", error);
        message.error("Failed to share to RiseUp forums");
        setSavingMessage(false);
      }
    }
  }

  return (
    <BottomSheetOptions
      title={`Share to...`}
      multiple
      options={shareMessageOptions(userForums)}
      loading={fetchingForums || savingMessage}
      onChoicesSelected={handleShareMessage}
      onClose={() => onClose()}
    />
  );
}

const mapStateToProps = (state) => ({
  authUser: appSelectors.getAuthUser(state),
  accessToken: appSelectors.getToken(state),
  fetchingForums: forumSelectors.getUserForumsLoading(state),
  fetchForumsError: forumSelectors.getUserForumsError(state),
  userForums: forumSelectors.getAllUserForums(state),
});

const mapDispatchToProps = {
  fetchForums: forumOperations.getUserForums,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareOptions);
