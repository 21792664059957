import React, { Component } from 'react';
import {message} from 'antd';
import PropTypes from 'prop-types';

import { getYouTubeVideoIdFromUrl } from  '../../../../../../Util';
import Toolbar from "../../../../../components/ToolBar";
import ModalLink from '../../../../../components/ModalLink';

import plusSign from '../../../../../../images/plus-sign-pinkish.svg';
import selectDropDown from '../../../../../../images/select-drop-down.svg';


import ContactAdmin from "../NewOpportunity/components/ContactAdmin";

import './styles.css';

class HelpCenter extends  Component{
    state = {
        disabled: false
    };

    onSubmitFeedback = response => {
        const payload = { response };
        this.props.collectFeedback(payload);
        this.setState({ disabled: true });
        message.config({
            top: 470,
            duration: 2,
            maxCount: 3,
        });
        message.success('Thank you for your feedback');
    };

    render() {
        const videoUrl = "https://www.youtube.com/watch?v=x5neI70gVAM";
        let videoId = "";
        let videoAvailable = videoUrl && videoUrl.length > 0;
        let videoImage = "";
        if(videoAvailable){
            videoId = getYouTubeVideoIdFromUrl(videoUrl);
            videoAvailable = videoId != null;
            videoImage = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
        }

        return (
            <div className={`HelpCenter position-fixed pin-all ${videoAvailable ? 'video-available' : ''}`}>
                <div className="flower-deco"/>
                <div className="position-fixed pin-top pin-left pin-right overflow-hidde z10">
                    <Toolbar 
                        isAuthenticated={this.props.isAuthenticated} isLogin={this.props.isLogin}
                        title="Help Center"
                        noActions
                        onBack={() => this.props.history.goBack()}
                    />
                </div>
                <div className="title-text">
                    How to submit an opportunity post on RiseUp
                </div>

                { videoAvailable &&  
                    <div id="decoBg" className="position-absolute pin-top pin-left pin-right brand-color-pinkish" />
                }

                <div className="contents">
                    {/* <h2 className="brand-color-pinkish-text">Guide/tips on How to create opportunity posts.</h2>
                    <p>
                        Hello beautiful, did the opportunity you submitted not go through? <br/>
                        The biggest reasons are normally because your submission
                        did not give us enough information about the opportunity.
                        But don't worry about it. Here are quick tips on the
                        best way to submit an opportunity. <br/> <br/>
                        Check out this video or read through the text below.
                    </p> */}

                    { videoAvailable && 
                        <ModalLink id="opportunityVideo" to={'/playYoutubeVideo/' + videoId} 
                            style={{padding: '20px 0', display: 'block'}}>
                            <div id="videoPlaceholder" className="position-relative"
                                style={{backgroundImage: `url(${videoImage})`}}>
                                <div className="position-absolute pin-all d-flex center-center">
                                    <svg id="ytPlayIcon" className="brand-color-pinkish-text" fill="currentColor"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>YouTube
                                        icon</title>
                                        <rect fill="#fff" x="8" y="8" width="8" height="8"/>
                                        <path
                                            d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z"/>
                                    </svg>
                                </div>
                            </div>
                        </ModalLink>
                    }

                    {/* <h2 className="brand-color-pinkish-text">How to submit an opportunity on RiseUp:</h2> */}
                    <ol>
                        {/* <li>Log in to your RiseUp account</li> */}
                        <li>
                            <span>Click the &nbsp;</span>
                            <img src={plusSign} alt="plus" width={12}/>
                            <span>&nbsp; button once you are logged in.</span>
                        </li>
                        <li><span>Click &nbsp;</span>
                            <img src={selectDropDown} alt="select drop down" width={120}/>
                            <span>&nbsp; and select the type of opportunity that you are submitting</span>
                        </li>
                        <li>
                            Enter or paste the information about the opportunity in the description box
                        </li>
                        <li>
                            Enter or paste the link to the opportunity source page. E.g. Website
                        </li>
                        <li>
                            Upload an image of the opportunity if you have it
                        </li>
                        <li>
                            Upload a pdf of the opportunity if you have it
                        </li>
                        <li>
                            Click Submit
                        </li>
                    </ol>
                    <div>
                        <span className="feedback-prompt">Was this information helpful? &nbsp;</span>
                        <button
                            className="feedback-button yes"
                            onClick={() => this.onSubmitFeedback('yes')}
                            disabled={this.state.disabled}
                        >
                            Yes
                        </button>
                        <button
                            className="feedback-button no"
                            onClick={() => this.onSubmitFeedback('no')}
                            disabled={this.state.disabled}
                        >
                            No
                        </button>
                    </div>
                </div>

                <ContactAdmin message="Still need some help?" />
            </div>
        );

    }
}

export default HelpCenter;

HelpCenter.propTypes = {
    isAuthenticated: PropTypes.bool,
    isLogin: PropTypes.bool,
    history: PropTypes.object
};