import { connect } from 'react-redux';
import ViewOpportunities from './ViewOpportunities';
import {appOperations, appSelectors} from "../../../../../duck";


const mapDispatchToProps = {
    setActiveInterest: appOperations.setActiveInterest,
};

const mapStateToProps = state => ({
    isAuthenticated: appSelectors.getIsAuthenticatedValue(state),
    activeInterest:appSelectors.getActiveInterest(state),
    selectedInterests: appSelectors.getUserInterests(state),
    isLogin: appSelectors.getIsLoginValue(state),
    userToken: appSelectors.getToken(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOpportunities);