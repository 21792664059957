import React from "react";

import goodbyeScreen from "../../../images/new-goodbye.png";
import "./index.css";

export default function Goodbye() {
  return (
      <div className={"goodbye-screen"}>
          <img src={goodbyeScreen} alt="Goodbye screen!" />
      </div>
  
  );
}
