import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {forumOperations, forumSelectors} from '../duck';
import ForumDetail from "./ForumDetail";

const ForumDetailScreen = (props) => {
    const { match: {params}, setCurrentForumId, forum } = props;

    useEffect(() => {
        setCurrentForumId(params.forumId);
    }, [setCurrentForumId, params]);
    
    return <ForumDetail forum={forum} />;
}

const mapStateToProps = state => ({
    forum: forumSelectors.getCurrentForum(state),
});

const mapDispatchToProps = {
    setCurrentForumId: forumOperations.setCurrentForumId,
    fetchForumMessages: forumOperations.getForumMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForumDetailScreen);