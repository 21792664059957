import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { appSelectors, appOperations } from "../../../../../duck";
import UserDetails from '../../../../../Onboarding/Screens/UserDetails';
import { AuthenticatedUserToolBar } from '../../../../../components/ToolBar';

import "./styles.css";

function EditDemographics({history, authUser, activeProfileEditFields, updateDemographics}){
    const handleUpdateDemographics = (data) => {
        updateDemographics({editField: 'Demographic Details', ...data}, history);
    }

    return (
        <div id="EditDemographics" className="position-fixed pin-all z10">
            <AuthenticatedUserToolBar
                theme="#e65f58"
                title="Edit Your Details"
                isAuthenticated={true}
                onBack={() => history.goBack()} 
                noActions
            />
            
            <div id="EditDemographicsContent">
                <UserDetails 
                    authUser={authUser}
                    inEditMode
                    updatingDetails={activeProfileEditFields.includes('Demographic Details')}
                    onUpdate={handleUpdateDemographics} 
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    authUser: appSelectors.getAuthUser(state),
    activeProfileEditFields: state.activeProfileEditFields,
});

const mapDispatchToProps = {
    updateDemographics: appOperations.updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditDemographics));