import * as actions from './actions';
import * as API from "../../../../../API";
import {appSelectors} from '../../../../duck';


/**
 * an operation that gets all opportunities
 * @function
 * @name getAllOpportunities
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAllOpportunities = (interestId, page=1) => (dispatch, getState) => {
    const state = getState();
    const token = appSelectors.getToken(state);
    const payload = {interestId, page};
    
    dispatch(actions.getAllOpportunitiesStart());

    API.getAllOpportunitiesByInterest(payload, token)
        .then(res => {
            let responseData = res.data;
            dispatch(actions.getAllOpportunitiesSuccess(responseData));
        })
        .catch(err => {
            console.log(err);
            dispatch(actions.getAllOpportunitiesError(err));
        });
};

/**
 * @function
 * @name getAllDueTodayOpportunities
 * @description an operation that gets all due today opportunities
 * @version 0.1.0
 * @since 0.1.0
 */
export const getAllDueTodayOpportunities = (interestId, page=1) => (dispatch, getState) => {
    const state = getState();
    const token = appSelectors.getToken(state);
    const payload = {interestId, page};

    dispatch(actions.getAllDueTodayOpportunitiesStart());

    API.getDueTodayOpportunitiesByInterest(payload, token)
        .then(res => {
            let responseData = res.data;
            dispatch(actions.getAllDueTodayOpportunitiesSuccess(responseData));
        })
        .catch(err => {
            console.log(err);
            dispatch(actions.getAllDueTodayOpportunitiesError(err));
        });
};

/**
 * an operation that gets opportunities for based on an interest
 * @function
 * @name getOtherOpportunities
 * @version 0.1.0
 * @since 0.1.0
 */
export const getOtherOpportunities = () => (dispatch, getState) => {
    const state = getState();
    const token = appSelectors.getToken(state);

    dispatch(actions.getOtherOpportunitiesStart());

    API.getOtherInterestsOpportunities(token)
        .then(res => {
            let responseData = res.data;
            dispatch(actions.getOtherOpportunitiesSuccess(responseData));
        })
        .catch(err => {
            console.log(err);
            dispatch(actions.getOtherOpportunitiesError(err));
        });
};
