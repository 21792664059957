import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { Avatar } from "antd";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import WhiteLogo from "../../../../../images/riseup_logo_white.png";
import WhiteLogoIcon from "../../../../../images/riseup-icon-white.png";
import ActionButton from '../../../ActionButton';
import ModalLink from '../../../ModalLink';
import petal from "../../../../../images/beatifulpetal.png";
import RiseupIcon from "../../../RiseupIcon";
import { lightenDarkenColor } from "../../../../utils";

import './styles.css';
import { appSelectors } from '../../../../duck';

function AuthenticatedUserToolBar({ children, logoIconOnly, title, renderTitle, renderActions, noActions, isLogin, theme, unreadNoticiationCount, authUser, history, location, onBack, onClose }) {
    useEffect(() => {
        var metaThemeColor = document.querySelector("meta[name=theme-color]");
        metaThemeColor.setAttribute("content", theme);
    }, [theme]);

    const onOpenChat = () => {
        // window.CHAT_API.maximize();
        // if(window.tawkChatUI)
        //     window.tawkChatUI.style.zIndex = 3000;

        history.push("/openTawk", {modal: true});
    };

    const getTitle = () => {
        if(typeof renderTitle === "function")
            return renderTitle();
        else if(title)
            return <div className="title">{ title } &nbsp;</div>;
        else
            return (
                <div className={`logo ${logoIconOnly ? 'icon-only' : ''}`}>
                    { logoIconOnly && <img src={WhiteLogoIcon} alt="RiseUp" width={35}/> }
                    { !logoIconOnly && <img src={WhiteLogo} alt="RiseUp" width={111}/> }
                </div>
            )
    }

    const renderNotificationLink = () => {
        const onNotificationsPage = location.pathname.indexOf('notifications') !== -1;
        return onNotificationsPage ? (
            <div className="toolbar-notification-icon">
                <RiseupIcon icon="bell" className="z10" />
            </div>
        )
        : (
            <ModalLink to="/notifications" className="toolbar-notification-icon">
                <RiseupIcon icon="bell" className="z10" />

                { unreadNoticiationCount !== 0 && 
                    <span className="d-flex center-center" 
                        style={{color: lightenDarkenColor(theme)}}>
                        { unreadNoticiationCount }
                    </span>
                }
            </ModalLink>
        );
    }

    const getActions = () => {
        if(noActions)
            return null;
        
        if(typeof renderActions === "function")
            return (    
                <div className="other-toolbar-components">
                    { renderActions() }
                </div> 
            );
        
        if(isLogin){
            let userDp = {icon: "user"};

            if(authUser && authUser.profilePic && authUser.profilePic.length)
                userDp = {
                    src: authUser.profilePic
                }

            return (
                <div className="other-toolbar-components">
                    <div className="text-icon" onClick={onOpenChat}>
                        <RiseupIcon icon="chat" className="z10" />
                    </div>

                    { renderNotificationLink() }
                    
                    <ModalLink to="/profile" className="toolbar-user-dp">
                        <Avatar 
                            {...userDp}
                            className="z10"
                        />
                    </ModalLink>
                </div>
            )
        }
        else
            return <img src={petal} alt="RiseUp" width={60} className="z10"/>
    }

    return (
        <div className="AuthenticatedUserToolBar" style={{backgroundColor: theme}}>
            { onBack && (
                <ActionButton onClick={onBack}>
                    <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="15 18 9 12 15 6"></polyline></svg>
                </ActionButton>
            )}
            
            { onClose && (
                <ActionButton onClick={onClose}>
                    &nbsp;&nbsp;<svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>
                </ActionButton>
            )}
            
            { getTitle() }
            
            <span className="flex">
                { children }
            </span>

            { getActions(isLogin) }
        </div>
    );
}

const mapStateToProps = state => ({
    authUser: appSelectors.getAuthUser(state)
});

export default connect(mapStateToProps)(withRouter(AuthenticatedUserToolBar));

AuthenticatedUserToolBar.propTypes = {
    title: PropTypes.string,
    renderTitle: PropTypes.func,
    renderActions: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    isLogin: PropTypes.bool,
    theme: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    noActions: PropTypes.bool,
    unreadNoticiationCount: PropTypes.number
};