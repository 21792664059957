import * as actions from './actions';
import * as API from "../../../../../API";
import { appSelectors } from '../../../../duck';
import { pageLoading, setAuthUser } from '../../../../duck/actions';

export const setActiveTab = actions.setActiveTab;
export const setSelectedItem = actions.setSelectedItem;
export const setContentType = actions.setContentType;

export const getArticles = (page = 1) => (dispatch, getState) => {
    const state = getState();
    const token = appSelectors.getToken(state);

    dispatch(actions.getArticlesStart());

    API.getArticles(page, token)
        .then(res => {
            let responseData = res.data;
            dispatch(actions.getArticlesSuccess(responseData));
        })
        .catch(err => {
            console.log(err);
            dispatch(actions.getArticlesError(err));
        });
};

export const saveLearningLabs = (interests, history, fromEditScreen) => async (dispatch, getState) => {
    const state = getState();
    const token = appSelectors.getToken(state);

    dispatch(pageLoading(true));
    const authUser = JSON.parse(localStorage.getItem('authUser'));

    await API.saveLearningLabInterests({ labIds: interests.map(({ _id }) => _id) }, token);

    dispatch(pageLoading(false));

    authUser.data.userProfile = { ...authUser.data.userProfile, learningLabs: interests };
    localStorage.setItem('authUser', JSON.stringify(authUser));
    dispatch(setAuthUser(authUser));

    if (fromEditScreen)
        history.replace('/main/profile');
};


export const fetchLearningLabSummary = (categoryId) => async (dispatch, getState) => {
    dispatch(actions.getLabSummaryStart());

    const state = getState();
    const token = appSelectors.getToken(state);
    let summary;

    if (categoryId === "trending")
        summary = await API.getLearningLabTrendingSummary(token, categoryId);
    else
        summary = await API.getLearningLabSummary(token, categoryId);

    dispatch(actions.getLabSummarySuccess(summary));
};

export const fetchLearningLabContents = (categoryId, type, page = 1) => async (dispatch, getState) => {
    dispatch(actions.getLabContentStart());
    const state = getState();
    const token = appSelectors.getToken(state);

    if (type == "article")
        type = "document,article";

    const contents = await API.getLearningLabContents(token, categoryId, type, page);
    dispatch(actions.getLabContentSuccess(contents));
};