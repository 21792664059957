import React, { useEffect } from 'react';
import {useHistory, useParams} from "react-router-dom";
import PropTypes from 'prop-types';

import { formatDate, getYouTubeVideoIdFromUrl } from '../../../../../../Util';
import ActionButton from "../../../../../components/ActionButton";
import ToolBar from "../../../../../components/ToolBar/ToolBar";
import ModalLink from "../../../../../components/ModalLink";
import CustomButton from "../../../../../components/Button";

const ViewOpportunity = (props) => {
    const { opportunity, activeInterest, onGoBack, onPrev, onNext, canGoPrevious, canGoNext } = props;
    const { resourceImg, title, deadlineDate, description, videoTitle, videoUrl, pdfUrl, websiteUrl } = opportunity;
    const videoId = videoUrl && videoUrl.length ? getYouTubeVideoIdFromUrl(videoUrl) : "";
    const videoImage = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
    
    const history = useHistory();
    const { interestId, opportunityId } = useParams();
    
    const handleClick = (url) => {
        const decodedUrl = encodeURIComponent(url);
        history.push(`/main/opportunities/${interestId}/all/${opportunityId}/${decodedUrl}`);
    }
    
    useEffect(() => {
        let themeColorCSS = document.createElement('style');
        themeColorCSS.setAttribute('id', 'themeColorCSS');
        themeColorCSS.innerHTML = `
            .brand-color-pinkish-text{
                color: red !important;
            }
            .CustomButton a{
                background-color: red !important;
            }
        `;
        document.body.appendChild(themeColorCSS);
        
        return () => {
            const themeColorCSS = document.getElementById('themeColorCSS');
            if (themeColorCSS) {
                document.body.removeChild(themeColorCSS);
            }
        };
    }, []);
    
    return (
        <div id="opportunityDetail" className={videoUrl ? 'video-available' : ''}>
            <div className="position-relative" style={{overflow: 'hidden'}}>
                <div className="flower-deco"/>
                <ToolBar
                    isAuthenticated={true}
                    isLogin={true}
                    theme={props.activeInterest.color}
                    onBack={props.onGoBack}
                    noActions
                />
            </div>
            
            <div className="contents">
                <h1 className="brand-color-pinkish-text">
                    {props.activeInterest.name}
                </h1>
                
                <img id="opportunityImage" src={resourceImg} alt=""/>
                
                <h2 id="opportunityTitle" className="d-flex items-center justify-between">
                    {props.onPrev &&
                        <ActionButton disabled={!props.canGoPrevious}
                                      onClick={props.onPrev}>
                            <svg className="brand-color-pinkish-text" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                        </ActionButton>
                    }
                    
                    <span>{title}</span>
                    
                    {props.onNext &&
                        <ActionButton disabled={!props.canGoNext}
                                      onClick={props.onNext}>
                            <svg className="brand-color-pinkish-text" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </ActionButton>
                    }
                </h2>
                
                <small id="opportunityDeadline" className="brand-color-pinkish-text">
                    {formatDate(deadlineDate)}
                </small>
                
                {/*<div id="opportunityDescription">*/}
                {/*    {description}*/}
                {/*</div>*/}
                
                <div id="opportunityDescription" dangerouslySetInnerHTML={{__html: description}}/>
                
                {videoUrl &&
                    <ModalLink id="opportunityVideo" to={'/playYoutubeVideo/' + videoId}>
                        <div id="videoPlaceholder" className="position-relative"
                             style={{backgroundImage: `url(${videoImage})`}}>
                            <div className="position-absolute pin-all d-flex center-center">
                                <svg className="brand-color-pinkish-text" fill="currentColor" width="70px"
                                     height="70px" role="img" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 24 24"><title>YouTube icon</title>
                                    <rect fill="#fff" x="8" y="8" width="8" height="8"/>
                                    <path
                                        d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z"/>
                                </svg>
                            </div>
                        </div>
                        <h5 id="videoTitle">
                            {videoTitle}
                        </h5>
                    </ModalLink>
                }
                
                {pdfUrl && pdfUrl.length > 0 &&
                    <div id="opportunityDoc">
                        <div className="d-flex center-center">
                            <svg className="brand-color-pinkish-text" fill="currentColor" width="50"
                                 viewBox="0 0 28 40">
                                <path d="M6.8 19.4H5.4v2H6.8c0.5 0 1-0.5 1-1C7.7 19.9 7.3 19.4 6.8 19.4z"/>
                                <path d="M12.1 19.4h-1.3v3.6h1.3C14.3 23 14.4 19.4 12.1 19.4z"/>
                                <path
                                    d="M23 15H1c-0.5 0-1 0.5-1 1v10c0 0.6 0.5 1 1 1h22c0.6 0 1-0.4 1-1V16C24 15.5 23.6 15 23 15zM6.8 22.4H5.4v1.1C5.4 23.8 5.2 24 4.9 24c-0.3 0-0.5-0.2-0.5-0.6v-4.5c0-0.3 0.2-0.6 0.5-0.6H6.8c1 0 2 0.7 2 2C8.7 21.7 7.8 22.4 6.8 22.4zM12.2 24h-1.9c-0.3 0-0.6-0.1-0.6-0.5v-4.5c0-0.3 0.3-0.5 0.6-0.5h1.8C15.8 18.5 15.7 24 12.2 24zM19.3 19.5h-2.3v1.3h2c0.3 0 0.6 0.3 0.6 0.6 0 0.3-0.3 0.5-0.6 0.5h-2v1.7c0 0.3-0.2 0.5-0.5 0.5 -0.4 0-0.6-0.2-0.6-0.5v-4.5c0-0.3 0.2-0.5 0.6-0.5h2.8c0.4 0 0.6 0.2 0.6 0.5C19.9 19.2 19.7 19.5 19.3 19.5z"/>
                                <path
                                    d="M27 7.6L20.4 1C19.8 0.4 18.8 0 18 0H7C5.3 0 4 1.3 4 3v10c0 0.6 0.4 1 1 1s1-0.4 1-1V3c0-0.6 0.4-1 1-1h9c1.1 0 2 0.9 2 2v3.8C18 9 19 10 20.3 10H24c1.1 0 2 0.9 2 2v17c0 0.6-0.4 1-1 1H7c-0.6 0-1-0.4-1-1 0-0.6-0.4-1-1-1s-1 0.4-1 1c0 1.7 1.3 3 3 3h18c1.7 0 3-1.3 3-3V10C28 9.2 27.6 8.2 27 7.6z"/>
                            </svg>
                            <div>
                                <h5>{pdfUrl}</h5>
                                {/*<a href={pdfUrl} className="brand-color-pinkish-text">View Document</a>*/}
                                <p
                                    className="brand-color-pinkish-text"
                                    style={{fontSize: "1.2rem"}}
                                    onClick={() => handleClick(pdfUrl)}
                                >View Document</p>
                            </div>
                        </div>
                    </div>
                }
                
                {websiteUrl && websiteUrl.length > 0 &&
                    <CustomButton
                        handleOnClick={() => handleClick(websiteUrl)}
                        primary
                        block
                    >
                        Visit Website
                    </CustomButton>
                }
            </div>
        </div>
    );
};

ViewOpportunity.propTypes = {
    opportunity: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    isLogin: PropTypes.bool,
    activeInterest: PropTypes.object,
    canGoNext: PropTypes.bool,
    canGoPrevious: PropTypes.bool,
    onGoBack: PropTypes.func,
    onPrev: PropTypes.func,
    onNext: PropTypes.func,
    canPrev: PropTypes.func,
    canNext: PropTypes.func,
};

export default ViewOpportunity;
