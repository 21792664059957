import React from "react";
import PropTypes from 'prop-types';
import OtherOpportunitiesSection from "../OtherOpportunitiesSection";
import Loader from "../../../../../../../../../components/Loader";
import NoOpportunities from "../NoOpportunities";

class OpportunitiesBasedOnOtherInterest extends React.Component{
    componentDidMount(){
        this.props.getOtherOpportunities();
    }
 
    render(){
        const {match: {url}, theme , otherOpportunities, otherOpportunitiesLoading } = this.props;
        let flattenedOtherOpportunities = [];
        if(otherOpportunities)
            flattenedOtherOpportunities = otherOpportunities.map(section => section.opportunities);

        const noOtherOpportunities = !flattenedOtherOpportunities || !flattenedOtherOpportunities.length;
        
        const renderOtherOpportunities = otherOpportunities => {
            const opportuniyItems = otherOpportunities.map(otherOpportunity => {
                const  { name, _id, color } = otherOpportunity.interests;
                const { opportunities } = otherOpportunity;

                return <OtherOpportunitiesSection key={_id} sectionTitle={name} interestId={_id} interestColor={color} url={url} opportunities={opportunities} theme={theme}/>
            });

            const spinner = (
                <div style={{padding: '2em 0'}} className="d-flex center-center">
                    <Loader size={80} thickness={4} color={theme} />
                </div> 
            );

            if(otherOpportunitiesLoading)
                return spinner;

            return noOtherOpportunities ? <NoOpportunities /> : opportuniyItems;
        };

        return  (
            <div className="TabItem">
                { renderOtherOpportunities(otherOpportunities)}
            </div>
        );
    }
}

export default OpportunitiesBasedOnOtherInterest;

OpportunitiesBasedOnOtherInterest.propTypes = {
    otherOpportunitiesLoading: PropTypes.bool,
    otherOpportunities: PropTypes.array,
    getOtherOpportunities: PropTypes.func
};