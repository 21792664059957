import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import {formatDate} from '../../../../../../../../Util';

/**
 * Component that renders an opportunity card
 * @function
 * @name Opportunity
 * @param {Object} props - react props
 * @param {String} props.url - url to opportunity details
 * @param {String} props.imageUrl - url to opportunity image
 * @param {String} props.opportunityTitle - title of the opportunity
 * @param {String} props.date - deadline date of the opportunity
 * @returns {null}
 * @version 0.1.0
 * @since 0.1.0
 * */

function Opportunity({ url, imageUrl, opportunityTitle, date }) {
    return (
        <Link to={url} className="ItemCard">
            <img src={imageUrl} style={{width: '100%', height: '120px', objectFit: 'cover'}} />
            <h3>{opportunityTitle}</h3>
            <div>Deadline : {formatDate(date)}</div>
        </Link>
    );
}

export default Opportunity;

Opportunity.propTypes = {
    url: PropTypes.string,
    imageUrl: PropTypes.string,
    opportunityTitle: PropTypes.string,
    date: PropTypes.string

};