import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { opportunitiesSelectors } from '../../../../../../duck';
import { appOperations } from '../../../../../../../../../duck';
import OtherOpportunitiesSection from './OtherOpportunitiesSection';

const mapDispatchToProps = {
    setActiveInterest: appOperations.setActiveInterest,
};

const mapStateToProps = state => ({
    activeInterest: opportunitiesSelectors.getActiveInterest(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OtherOpportunitiesSection));