import * as types from "./types";

export const setCountries = countries => ({
    type: types.SET_COUNTRIES,
    payload: countries
});

export const setCountriesFetchingState = state => ({
    type: types.SET_COUNTRIES_FETCHING_STATE,
    payload: state
});

export const setRegions = regions => ({
    type: types.SET_REGIONS,
    payload: regions
});

export const setRegionsFetchingState = state => ({
    type: types.SET_REGIONS_FETCHING_STATE,
    payload: state
});

export const setDistricts = districts => ({
    type: types.SET_DISTRICTS,
    payload: districts
});

export const setDistrictsFetchingState = state => ({
    type: types.SET_DISTRICTS_FETCHING_STATE,
    payload: state
});