
import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Styles from './styles.css';

const cx = classnames.bind(Styles);

class CustomCheckBox extends Component {
    static propTypes = {
        handleOnChange: PropTypes.func,
        id: PropTypes.string,
        color: PropTypes.string,
        value: PropTypes.string,
        disabled: PropTypes.bool,
        checked: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        checked: false
    };

    componentDidMount() {
        const { id, color } = this.props;
        let editCSS = document.createElement('style');
        editCSS.innerHTML =`
       .CustomCheckBox .container input:checked ~ .checkmark.custom-checkbox-${id} {
            background-color: ${color};
        }
        `;
        document.body.appendChild(editCSS);
    }

    render() {
        return (
            <div className="CustomCheckBox">
                <label className="container">
                    <input type="checkbox" checked={this.props.checked} onChange={this.props.handleOnChange} disabled={this.props.disabled}/>
                    <span className={cx(`checkmark custom-checkbox-${this.props.id}`)}/>
                </label>
            </div>
        );
    }
}

export default CustomCheckBox;