import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

function ActionButton ({children, className, disabled, onClick, ...props}){
    className = className || "";
    className += " ActionButton";
    className += disabled ? " disabled" : "";
    return (
        <button className={className} disabled={disabled} {...props} onClick={onClick}>
            {children}
        </button>
    );
}

export default ActionButton;

ActionButton.propTypes = {
    type: PropTypes.string,
    onClick: PropTypes.func
};