import * as types from "./types";
import get from "lodash/get";
import map from "lodash/map";

const initialForumsState = {
  connectedToSocket: false,
  activeTab: "Main Forum",
  currentForumId: null,
  forUser: {
    forums: null,
    totalCount: null,
    page: 0,
    loading: false,
    error: null,
  },
  other: {
    forums: null,
    previewedForumId: null,
    totalCount: null,
    page: 0,
    loading: false,
    error: null,
  },
};

/**
 *
 * @function
 * @name opp
 * @param {Object} state - Initial state
 * @param {Object} action - Redux action object
 * @version 0.1.0
 * @since 0.1.0
 */

export const forums = (state = initialForumsState, action) => {
  switch (action.type) {
    case types.SET_FORUMS_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case types.FORUM_SOCKET_CONNECTION_CHANGED:
      return { ...state, connectedToSocket: action.payload };
    case types.UNSUBSCRIBE_FROM_FORUM: {
      const unSubscribedForumId = action.payload;
      const unSubscribedForum = state.forUser.forums.find(
        ({ _id }) => _id === unSubscribedForumId
      );
      const forums = state.forUser.forums.filter(
        ({ _id }) => _id !== unSubscribedForumId
      );
      const forUser = { ...state.forUser, forums };

      let other = state.other;
      if (other.forums) {
        other = { ...other, forums: [...other.forums, unSubscribedForum] };
      }
      return { ...state, forUser, other };
    }
    case types.SET_USER_FORUM_DETAILS: {
      const { forumId, data } = action.payload;
      const forums = state.forUser.forums.map((forum) => {
        if (forum._id === forumId) forum = { ...forum, ...data };

        return forum;
      });
      const forUser = { ...state.forUser, forums };
      return { ...state, forUser };
    }
    case types.GET_USER_FORUMS_START: {
      const forUser = { ...state.forUser, error: null, loading: true };
      return { ...state, forUser };
    }
    case types.GET_USER_FORUMS_SUCCESS: {
      const forums = action.payload.map((subscription) => {
        const { _id, forum } = subscription;
        return { ...forum, subscriptionId: _id, messages: [] };
      });
      const forUser = { ...state.forUser, forums, loading: false };
      return { ...state, forUser };
    }
    case types.GET_USER_FORUMS_ERROR: {
      const forUser = {
        ...state.forUser,
        error: action.payload,
        loading: false,
      };
      return { ...state, forUser };
    }

    case types.SET_PREVIEWED_FORUM_ID: {
      const other = { ...state.other, previewedForumId: action.payload };
      return { ...state, other };
    }
    case types.SUBSCRIBE_TO_FORUM: {
      const subscribedForumId = action.payload;
      const subscribedForum = state.other.forums.find(
        ({ _id }) => _id === subscribedForumId
      );
      const forums = state.other.forums.filter(
        ({ _id }) => _id !== subscribedForumId
      );
      const other = { ...state.other, forums };

      let forUser = state.forUser;
      if (forUser.forums) {
        forUser = { ...forUser, forums: [...forUser.forums, subscribedForum] };
      }
      return { ...state, other, forUser };
    }
    case types.SET_OTHER_FORUM_DETAILS: {
      const { forumId, data } = action.payload;
      const forums = state.other.forums.map((forum) => {
        if (forum._id === forumId) forum = { ...forum, ...data };

        return forum;
      });
      const other = { ...state.other, forums };
      return { ...state, other };
    }
    case types.GET_OTHER_FORUMS_START: {
      const other = { ...state.other, error: null, loading: true };
      return { ...state, other };
    }
    case types.GET_OTHER_FORUMS_SUCCESS: {
      const forums = action.payload.map((forum) => ({
        ...forum,
        messages: [],
      }));
      const other = { ...state.other, forums, loading: false };
      return { ...state, other };
    }
    case types.GET_OTHER_FORUMS_ERROR: {
      const other = { ...state.other, error: action.payload, loading: false };
      return { ...state, other };
    }

    case types.SET_CURRENT_FORUM_ID:
      return { ...state, currentForumId: action.payload };

    case types.FORUM_MESSAGES_FETCHED: {
      const { forumId, data } = action.payload;
      const { page, chats, total } = data;
      const forums = state.forUser.forums.map((forum) => {
        if (forum._id === forumId) {
          if (!forum.messages) forum.messages = [];

          forum.messages = [...chats, ...forum.messages];

          forum.lastFetchedPage = page;
          forum.totalMessageCount = total;
        }

        return forum;
      });
      const forUser = { ...state.forUser, forums };
      return { ...state, forUser };
    }

    case types.ADD_MESSAGE_TO_FORUM: {
      const { forumId, message } = action.payload;

      const data = get(state, "forUser.forums", []);

      const forums = map(data, (forum) => {
        if (forum._id === forumId) {
          const messages = forum.messages || [];
          const messageKeys = messages.map((m) => m.messageKey);

          if (message.messageKey && !messageKeys.includes(message.messageKey))
            forum.messages = [...messages, message];
        }

        return forum;
      });

      // const forums = state.forUser.forums.map(forum => {
      //     if(forum._id === forumId){
      //         const messages = forum.messages || [];
      //         const messageKeys = messages.map(m => m.messageKey);

      //         if(message.messageKey && !messageKeys.includes(message.messageKey))
      //             forum.messages = [...messages, message];
      //     }

      //     return forum;
      // });

      const forUser = { ...state.forUser, forums };
      return { ...state, forUser };
    }

    case types.UPDATE_MESSAGE_DETAILS: {
      const { forumId, messageId, updatedDetails } = action.payload;
      const forums = state.forUser.forums.map((forum) => {
        if (forum._id === forumId) {
          const messages = forum.messages || [];

          forum.messages = messages.map((m) => {
            if (m._id === messageId || m.messageKey === messageId)
              m = { ...m, ...updatedDetails };

            return m;
          });
        }

        return forum;
      });

      const forUser = { ...state.forUser, forums };
      return { ...state, forUser };
    }

    case types.DELETE_MESSAGE_FROM_FORUM: {
      const { forumId, messageId } = action.payload;
      const forums = state.forUser.forums.map((forum) => {
        if (forum._id === forumId) {
          const messages = forum.messages || [];
          forum.messages = messages.filter((m) => m._id !== messageId);
        }

        return forum;
      });

      const forUser = { ...state.forUser, forums };
      return { ...state, forUser };
    }

    default:
      return state;
  }
};
