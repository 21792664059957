import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

import './styles.css';

// Returns if a value is an object
function isObject (value) {
    return value && typeof value === 'object' && value.constructor === Object;
}

class CustomSelect extends Component {
    renderOptions = options => {
        if(!options)
            return;

        return options.map((option, index) => {
            if(isObject(option))
                return <option key={index} value={option._id}>{option.name}</option>;
            
            else
                return <option key={index} value={option}>{option}</option>;
        });
    }

    render() {
        const id = this.props.placeholder.replace(/\s+/g, '');
        const {label, data, value} = this.props;
        
        return (
            <div className="custom-select" id={id} style={{width: this.props.width || '100%'}}>
                { label && label.length && 
                    <label>{label}</label>
                }
                <select defaultValue="" 
                    disabled={this.props.disabled} 
                    value={this.props.value}
                    onChange={(e) => this.props.handleOnChange(e.target.value)}>
                    <option disabled value="">{
                        (!data || !data.length) && value ? value : this.props.placeholder
                    }
                    </option>
                    {this.renderOptions(this.props.data)}
                </select>

                { this.props.loading && <Loader fill color="#aaa" size={28} style={{backgroundColor: 'transparent'}} /> }
            </div>
        );
    }
}

export default CustomSelect;

CustomSelect.propTypes = {
    getSelectedValue: PropTypes.func,
    data: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    width: PropTypes.string,
    placeholder: PropTypes.string,
    handleOnChange: PropTypes.func,
};

CustomSelect.defaultProps = {
    handleOnChange: () => {}
};