import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'; // eslint-disable-line import/no-extraneous-dependencies
import thunk from 'redux-thunk';
import * as API from '../API';
import rootReducer from './rootReducer';

/* local constants */
const initialStore = {};

/**
 * Configure Redux store
 * Enable redux dev tools
 * Setup redux observables as async library
 *
 * @function
 * @name configureStore
 *
 * @version 0.1.0
 * @since 0.1.0
 */
const configureStore = () => {
    const store = createStore(
        rootReducer,
        initialStore,
        composeWithDevTools(applyMiddleware(thunk.withExtraArgument({ API })))
    );

    return store;
};

export default configureStore;
