import { connect } from 'react-redux';
import ViewDueTodayOpportunity from './ViewDueTodayOpportunity';
import {appSelectors} from '../../../../../duck';
import {opportunitiesSelectors} from '../../duck';

const mapStateToProps = state => ({
    isAuthenticated: appSelectors.getIsAuthenticatedValue(state),
    activeInterest: appSelectors.getActiveInterest(state),
    isLogin: appSelectors.getIsLoginValue(state),
    dueTodayOpportunities: opportunitiesSelectors.getAllDueTodayOpportunities(state)
});

export default connect(mapStateToProps)(ViewDueTodayOpportunity);
