import React from 'react';

import AlertDialog from '../../../../../components/FlNotifications/AlertDialog';

const OpportunityRejected = (props) => {
    const remarks = props.location.state.remarks;

    function viewRejectedOpportunity(){
        let rejectedOpportunity = props.location.state;

        props.history.replace({
            pathname: "/main/opportunities/new/edit",
            search: '?draftId=' + rejectedOpportunity._id,
            state: { rejectedOpportunity }
        });
    }

    return ( 
        <div id="opportunityRejecteds" className="position-fixed pin-all d-flex center-center">
            <AlertDialog 
                // okText="See Tips"
                fullWidthAction
                okText="View Opportunity"
                onOkay={viewRejectedOpportunity} 
                onCancel={() => props.history.goBack()}
            >
                <h1 className="brand-color-pinkish-text">Hello Mrembo,</h1>
                {/* <h2>{ remarks }</h2> */}
                <h2>Your opportunity has been rejected!</h2>
                {/* <p>
                    No worries! Here are a few tips to help you share opportunities that are much more likely to go through.
                </p> */}
            </AlertDialog>
        </div>
    );
}
 
export default OpportunityRejected;