import React from 'react';

import avatar from "./ashura.png";

import "./styles.css";

const AlertDialog = ({location, noAvatar = false, children, title, message, fullWidthAction, onCancel, onOkay, cancelText = 'Cancel', okText = 'Okay'}) => {
    if(location && location.state && location.state.alert){
        const alert = location.state.alert;
        noAvatar = alert.noAvatar;

        onOkay = alert.onOkay || window.history.back;
        onCancel = alert.onCancel;
        okText = alert.okText || "Okay";
        cancelText = alert.cancelText || "Cancel";

        children = (
            <React.Fragment>
                <h2>{alert.title}</h2>
                <p>{ alert.message }</p>
            </React.Fragment>
        );
    }

    return ( 
        <div id="riseupAlertDialog" className={`position-fixed pin-all d-flex center-center ${fullWidthAction ? 'full-width-action' : ''}`}>
            <div id="dialog" className="position-fixed pin-all" 
                onClick={() => window.history.back()} />
            <div className="position-relative">
                { !noAvatar && <img src={avatar} alt="" /> }

                { fullWidthAction && onCancel && (
                    <button className="AlertDialogCloseButton" onClick={onCancel}>
                        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                    </button>
                )}

                { title && title.length > 0 && <h2>{ title } </h2> }
                
                { message && <p className="alert-body" dangerouslySetInnerHTML={ {__html: message} } /> }

                { children }

                { (onCancel || onOkay) && 
                    <div className="action-buttons layout center end-justified">
                        { !fullWidthAction && onCancel && 
                            <button className="action-button close"
                                onClick={onCancel}>
                                { cancelText }
                            </button>
                        }

                        { onOkay &&
                            <button className="action-button"
                                onClick={onOkay || window.history.back }>
                                { okText }
                            </button>
                        }
                    </div>
                }
            </div>
        </div>
    );
}
 
export default AlertDialog;