import React, { useEffect } from 'react';
import {Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { appSelectors } from '../duck';

import UserDetails from "./Screens/UserDetails";
import Interests from "./Screens/Interests";
import PickNickName from './Screens/PickNickName';
import Dream from './Screens/Dream';
import Anonymity from './Screens/Anonymity';
import WelcomeBack from './Screens/OnboardingAfterDelete/WelcomeBack';
import AccountRevoked from './Screens/OnboardingAfterDelete/AccountRevoked';

function Onboarding({history, authToken}){
    useEffect(() => {
        if(!authToken)
            history.replace("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="Onboarding">
            <Switch>
                <Route exact path="/onboarding/welcome-back/" component={WelcomeBack} />
                <Route exact path="/onboarding/account-revoked/" component={AccountRevoked} />

                <Route exact path="/onboarding/nickname/" component={PickNickName} />
                <Route exact path="/onboarding/details/" component={UserDetails} />
                <Route exact path="/onboarding/interests/" component={Interests} />
                <Route path="/onboarding/dream/" component={Dream} />
                <Route path="/onboarding/anonymity/" component={Anonymity} />
            </Switch>
        </div>
    );
}

const mapStateToProps = state => ({
    authToken: appSelectors.getAuthToken(state)
});

export default connect(mapStateToProps, null)(withRouter(Onboarding));