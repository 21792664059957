import * as types from "./types";


/*Initial schema state */
const initialSchema = {
    countriesFetchState: 'idle',
    countries: null,
    regionsFetchState: 'idle',
    regions: null,
    districtsFetchState: 'idle',
    districts: null,
};


/**
 * administrativeBoundaries reducer
 * Is the field in the store which holds data for the App schema .
 *
 * @function
 * @name administrativeBoundaries
 *
 * @param {Object} state - Initial state
 * @param {Object} action - Redux action object*
 * @version 0.1.0
 * @since 0.1.0
 */
export const administrativeBoundaries = (state = initialSchema, action) => {
    switch (action.type) {
        case types.SET_COUNTRIES:
            return { ...state, countries: action.payload};
            
        case types.SET_COUNTRIES_FETCHING_STATE:
            return { ...state, countriesFetchState: action.payload};

        case types.SET_REGIONS:
            return { ...state, regions: action.payload};
        
        case types.SET_REGIONS_FETCHING_STATE:
            return { ...state, regionsFetchState: action.payload};

        case types.SET_DISTRICTS:
            return { ...state, districts: action.payload};

        case types.SET_DISTRICTS_FETCHING_STATE:
            return { ...state, districtsFetchState: action.payload};

        default:
            return state;

    }
};