import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { message } from "antd";

import ToolBar from "../../../../../components/ToolBar";
import CustomSelect from "../../../../../components/Select";
import CustomTextArea from "../../../../../components/TextArea";
import CustomInput from "../../../../../components/Input";
import CustomButton from "../../../../../components/Button";
import CustomFileUpload from "../../../../../components/FileUpload";
import ContactAdmin from "./components/ContactAdmin";

import './style.css';

/**
 * @class
 * @name NewOpportunitiy
 * @description Renders form for creating new opportunity
 *
 * @returns {object} React element
 * @version 0.1.0
 * @since 0.1.0
 * */
class NewOpportunitiy extends Component {
    state = {
        typeOfOpportunity: '',
        description: '',
        link: '',
        video: '',
        imageUrl: '',
        imageFileName: '',
        imageLoading: false,
        pdfUrl: '',
        pdfFileName: '',
        pdfLoading: false,
        clipboardText: '',
    };

    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        isLogin: PropTypes.bool,
        isOpportunityLoading: PropTypes.bool,
        createOpportunity: PropTypes.func,
        interests: PropTypes.array,
        getInterests: PropTypes.func,
        history: PropTypes.object
    };

    componentDidMount() {
        const searchParams = this.props.location.search;

        if (searchParams && searchParams.length && searchParams.indexOf("?draftId=") !== -1) {
            const _tempId = searchParams.replace("?draftId=", "");
            if (this.props.location.state && this.props.location.state.rejectedOpportunity) {
                const {
                    description,
                    pdfUrl,
                    interests,
                    resourceImg,
                    title,
                    videoUrl,
                    websiteUrl
                } = this.props.location.state.rejectedOpportunity;

                this.setState({
                    _tempId,
                    title,
                    description: description || "",
                    pdfUrl,
                    link: websiteUrl || "",
                    video: videoUrl || "",
                    imageUrl: resourceImg,
                    typeOfOpportunity: interests && interests.length ? interests[0] : "",
                    rejectedOpportunity: true
                });
            }
            else {
                this.setState({ _tempId });

                let opportunityDrafts = window.localStorage.getItem('RISEUP_OPPORTUNITY_DRAFTS');
                if (opportunityDrafts) {
                    opportunityDrafts = JSON.parse(opportunityDrafts);
                    const opportunityDraft = opportunityDrafts.find((draft) => draft._tempId === _tempId);

                    if (opportunityDraft)
                        this.setState({ ...opportunityDraft });
                }
            }
        }
        else {
            const draftId = 'opportunity-draft-' + Math.random().toString(36).substr(2, 5);
            this.props.history.push({ search: `?draftId=${draftId}` });
        }

        this.props.getInterests();
        this._checkClipboard();
        window.onfocus = () => {
            this._checkClipboard();
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isOpportunityLoading } = prevProps;
        if (isOpportunityLoading !== this.props.isOpportunityLoading) {
            if (!this.props.isOpportunityLoading) this.success();
        }

        const { description, typeOfOpportunity, pdfUrl, pdfFileName, imageUrl, imageFileName, link, video, _tempId } = this.state;
        if (description.length || typeOfOpportunity.length || pdfUrl || imageUrl || link || video) {
            let opportunityDraft = {
                _tempId,
                description,
                pdfUrl,
                pdfFileName,
                imageUrl,
                imageFileName,
                link,
                video,
                typeOfOpportunity
            };

            if (pdfUrl)
                opportunityDraft[pdfFileName] = pdfFileName;
            else
                delete opportunityDraft.pdfFileName;

            if (imageUrl)
                opportunityDraft[imageFileName] = imageFileName;
            else
                delete opportunityDraft.imageFileName;

            if (typeOfOpportunity && typeOfOpportunity.length)
                opportunityDraft.interest = this.props.interests.find(({ _id }) => _id === typeOfOpportunity);
            else
                delete opportunityDraft.interest;

            let opportunityDrafts = window.localStorage.getItem('RISEUP_OPPORTUNITY_DRAFTS');
            if (!opportunityDrafts)
                opportunityDrafts = [];
            else
                opportunityDrafts = JSON.parse(opportunityDrafts);


            const indexOfDraftToReplace = opportunityDrafts.findIndex((draft) => draft._tempId === _tempId);
            if (indexOfDraftToReplace !== -1)
                opportunityDrafts.splice(indexOfDraftToReplace, 1);

            opportunityDrafts.unshift(opportunityDraft);
            window.localStorage.setItem('RISEUP_OPPORTUNITY_DRAFTS', JSON.stringify(opportunityDrafts));
        }

    }

    handleOnOpportunityTypeChange = (typeOfOpportunity) => {
        this.setState({ typeOfOpportunity });
    };

    _checkClipboard = async () => {
        if (window.navigator && window.navigator.clipboard) {
            try {
                const clipboardText = await navigator.clipboard.readText();
                this.setState({ clipboardText });
            } catch (error) {
                console.error('Failed to read clipboard contents: ', error.message);
                this.setState({ clipboardText: "" });
            }
        }
    };

    _isValidURL = url => {
        if (!url || !url.length)
            return false;

        const urlPattern = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
        return urlPattern.test(url);
    };

    handleImageUploadStatusChanged = ({ uploading, url, fileName }) => {
        if (url)
            this.setState({ imageUrl: url });

        if (fileName)
            this.setState({ imageFileName: fileName });

        this.setState({ imageLoading: uploading });
    };

    handlePdfUploadStatusChanged = ({ uploading, url, fileName }) => {
        if (url)
            this.setState({ pdfUrl: url });

        if (fileName)
            this.setState({ pdfFileName: fileName });

        this.setState({ pdfLoading: uploading });
    };

    handleOnCustomTextAreaChange = ({ target }) => {
        const description = target.value;
        this.setState({ description })
    };

    handleOnCustomInputChange = (link) => {
        this.setState({ link })
    };

    handleOnCustomInputVideoChange = (video) => {
        this.setState({ video })
    };

    handleSubmit = () => {
        const { _tempId, description, typeOfOpportunity, pdfUrl, imageUrl, link, video } = this.state;
        const payload = {
            _tempId,
            opportunity: {
                description,
                pdfUrl,
                interests: [typeOfOpportunity],
                resourceImg: imageUrl,
                title: "Opportunity",
                videoTitle: "Opportunity Video",
                websiteUrl: link,
                videoUrl: video,
            }
        };

        this.props.createOpportunity(payload, this.props.history);
    };

    success = () => {
        message.success('Opportunity created successfully, Awaiting approval');
    };

    render() {
        const opportunityTypeChoices = this.props.interests;
        const fetchingOpportunityTypeChoices = !opportunityTypeChoices || !opportunityTypeChoices.length;
        const { clipboardText, link, video, description, imageLoading, imageFileName, pdfFileName, pdfLoading, typeOfOpportunity } = this.state;
        const clipboardTextIsEmpty = !clipboardText || !clipboardText.length;
        const clipboardTextIsLink = this._isValidURL(clipboardText);
        const loadingResources = imageLoading || pdfLoading;
        const someFieldIsFilled = [typeOfOpportunity, description, link, video, imageFileName, pdfFileName].some(field => field && field.length);

        return (
            <div className="NewOpportunity">
                <div className="position-fixed pin-top pin-left pin-right z10">
                    <ToolBar
                        title="Submit Opportunity"
                        noActions
                        isAuthenticated={this.props.isAuthenticated}
                        isLogin={this.props.isLogin}
                        onBack={() => window.history.back()}
                    />
                </div>
                <div className="welcome-text">
                    <h2 className="brand-color-pinkish-text">
                        Hey girl, You are Amazing!
                    </h2>

                    <h2 className="brand-color-pinkish-tex">
                        Every opportunity you share will <br />
                        help someone out a lot.
                    </h2>

                    <p>
                        To share an opportunity, simply<br />
                        fill the short form below.
                    </p>

                    <form>
                        <div className="form-item">
                            <CustomSelect
                                placeholder="Type of Opportunity"
                                data={opportunityTypeChoices}
                                value={!fetchingOpportunityTypeChoices ? typeOfOpportunity : ""}
                                loading={fetchingOpportunityTypeChoices}
                                handleOnChange={this.handleOnOpportunityTypeChange}
                            />
                        </div>
                        <div className="form-item">
                            <label htmlFor="aboutOpportunity" className="d-flex items-center justify-between">
                                About opportunity

                                <button type="button" className="brand-color-pinkish-text"
                                    disabled={description.length || clipboardTextIsEmpty || clipboardTextIsLink}
                                    onClick={() => this.setState({ description: clipboardText })}>
                                    Paste Info
                                </button>
                            </label>
                            <CustomTextArea
                                id="aboutOpportunity"
                                placeholder="Enter information about the opportunity here..."
                                rows={5}
                                cols={38}
                                value={description}
                                handleOnChange={this.handleOnCustomTextAreaChange}
                            />
                        </div>
                        <div className="form-item" id="link">
                            <label htmlFor="opportunityLink" className="d-flex items-center justify-between">
                                Link to opportunity

                                <button type="button" className="brand-color-pinkish-text"
                                    disabled={link.length || !clipboardTextIsLink}
                                    onClick={() => this.setState({ link: clipboardText })}>
                                    Paste Link
                                </button>
                            </label>
                            <CustomInput
                                id="opportunityLink"
                                placeholder="Enter link here"
                                value={link}
                                onCustomInputChange={this.handleOnCustomInputChange}
                            />

                        </div>
                        <div className="form-item" id="link">
                            <label htmlFor="videoLink" className="d-flex items-center justify-between">
                                Video Link

                                <button type="button" className="brand-color-pinkish-text"
                                    disabled={video.length || !clipboardTextIsLink}
                                    onClick={() => this.setState({ link: clipboardText })}>
                                    Paste Link
                                </button>
                            </label>
                            <CustomInput
                                id="videoLink"
                                placeholder="Enter video link here"
                                value={video}
                                onCustomInputChange={this.handleOnCustomInputVideoChange}
                            />
                        </div>

                        <div className="form-item" id="imageAttachment">
                            <CustomFileUpload
                                type={CustomFileUpload.TYPE_IMAGE}
                                onChange={this.handleImageUploadStatusChanged}
                                placeholder={imageFileName && imageFileName.length ? imageFileName : "Attach Image"}
                            />
                        </div>

                        <div className="form-item" id="pdfAttachment">
                            <CustomFileUpload
                                type={CustomFileUpload.TYPE_PDF}
                                onChange={this.handlePdfUploadStatusChanged}
                                placeholder={pdfFileName && pdfFileName.length ? pdfFileName : "Attach PDF"}
                            />
                        </div>

                        <div className="form-item" id="submit-button">
                            <CustomButton
                                width="70%"
                                color="#e0493f"
                                handleOnClick={() => this.props.history.goBack()}
                                disabled={loadingResources || this.props.isOpportunityLoading || !someFieldIsFilled}
                            >
                                Save Draft
                            </CustomButton>

                            <CustomButton
                                width="70%"
                                color="#e0493f"
                                handleOnClick={this.handleSubmit}
                                loading={this.props.isOpportunityLoading}
                                disabled={loadingResources || !someFieldIsFilled}
                            >
                                Submit
                            </CustomButton>

                        </div>
                    </form>

                    <ContactAdmin showTips={this.state.rejectedOpportunity} />
                </div>
            </div>
        );
    }
}

export default withRouter(NewOpportunitiy);