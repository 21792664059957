import { connect } from 'react-redux';
import OpportunitiesBasedOnInterest from "./OpportunitiesBasedOnInterest";
import { opportunitiesOperations, opportunitiesSelectors } from '../../../../../../duck';

const mapDispatchToProps = {
    getAllOpportunities: opportunitiesOperations.getAllOpportunities,
    getAllDueTodayOpportunities: opportunitiesOperations.getAllDueTodayOpportunities,
};

const mapStateToProps = state => ({
    dueTodayOpportunitiesLoading: opportunitiesSelectors.getAllDueTodayOpportunitiesLoading(state),
    allOpportunitiesLoading: opportunitiesSelectors.getAllOpportunitiesLoading(state),
    recentOpportunities: opportunitiesSelectors.getRecentOpportunities(state),
    dueTodayOpportunities: opportunitiesSelectors.getSummaryOfDueTodayOpportunities(state),
    otherOpportunities: state.opportunities && state.opportunities.other,
    activeInterest: opportunitiesSelectors.getActiveInterest(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunitiesBasedOnInterest);
