import React, { useRef, useEffect, useState } from 'react';
import usePortal from 'react-useportal'
import TextareaAutosize from 'react-textarea-autosize';

import Button from "../../../../../../../components/Button";
import './styles.css';

function TypeDeactivateReason ({onClose, onSubmit}){
    const [reason, setReason] = useState("");
    const reasonInputRef = useRef(null);
    const { Portal } = usePortal();
    const maxWordCount = 8;

    useEffect(() => {
        setTimeout(() => {
            if(reasonInputRef.current)
                reasonInputRef.current.focus();
        }, 10);
    }, []);

    function handleSubmitReason(e){
        e.preventDefault();
        onSubmit(reason);
    }

    let wordCount = reason.length > 0 ? reason.trim().split(' ').length : 0;
    const remainingWords = maxWordCount - wordCount;
    let indicatorClass = "";
    if(remainingWords <= 0){
        indicatorClass = "brand-color-pink-text"; //error
    }
    else if(remainingWords > 0 && remainingWords <= 5)
        indicatorClass = "brand-color-orangeish-text"; //warning

    return (
        <Portal>
            <div className="TypeDeactivateReason d-flex center-center">
                <div id="TypeDeactivateReasonDialog" onClick={onClose} />

                <form className="TypeDeactivateReasonContent"
                    onSubmit={handleSubmitReason}>
                    <h2>Deactivation Reason</h2>
                    <TextareaAutosize 
                        placeholder="Enter reason here"
                        minRows={1}
                        maxRows={2}
                        inputRef={reasonInputRef}
                        onChange={({target}) => setReason(target.value)} 
                    />

                    <div className="d-flex items-center">
                        <span className={indicatorClass}>{wordCount}</span>&nbsp;&nbsp;/&nbsp;&nbsp;<strong>{maxWordCount}</strong>

                        <Button type="submit" disabled={wordCount > maxWordCount || wordCount < 1}>
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
        </Portal>
    )
}

export default TypeDeactivateReason;