import { connect } from 'react-redux';
import MainForum from './MainForum';
import {forumOperations, forumSelectors} from '../../duck';

const mapStateToProps = state => ({
    forum: forumSelectors.getMainForum(state),
    fetchingForums: forumSelectors.getUserForumsLoading(state),
    fetchForumsError: forumSelectors.getUserForumsError(state),
    forums: forumSelectors.getUserForums(state),
});

const mapDispatchToProps = {
    setCurrentForumId: forumOperations.setCurrentForumId,
    fetchForumMessages: forumOperations.getForumMessages,
    fetchForums: forumOperations.getUserForums,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainForum);