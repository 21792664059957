import { uploadFile } from "./S3";
import { useState } from "react";

export default function useS3Uploader(config){
    const [fileName, setFileName] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("idle");
    const [uploadError, setUploadError] = useState(null);
    const [uploadPercent, setUploadPercent] = useState(null);
    const [uploadedFileUrl, setUploadedFileUrl] = useState(null);

    const upload = async (file) => {
        if(!file) return setFileName(null);

        const dirName = config.dirName && config.dirName.length ? config.dirName : 'images';
        setUploadPercent(0);
        setFileName(file.name);

        try {
            setUploadError(null);
            setUploadStatus("uploading");
            const uploadedFile = await uploadFile(file, {dirName, 
                onProgress: setUploadPercent
            });

            setUploadPercent(null);
            setUploadStatus("idle");
            setUploadedFileUrl(uploadedFile.location);
        } catch (err) {
            console.log("Upload error", err);
            setUploadError(err);
            setUploadPercent(null);
            setUploadedFileUrl(null);
            setUploadStatus("error");
        }
    };

    return {
        fileName,
        uploadStatus,
        uploadError,
        uploadPercent,
        uploadedFileUrl,
        upload
    };
}