import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';

import AlertDialog from '../../../../../../../components/FlNotifications/AlertDialog';
import { AuthenticatedUserToolBar } from '../../../../../../../components/ToolBar';

import Forum from '../../../Forum';

import "./styles.css";

const LeaveForum = ({match, history, forums, leaveForum}) => {
    const { url } = match;

    function handleLeaveForumClicked(forumId){
        history.push(`${url}/confirmLeave/${forumId}`);
    }

    function handleLeaveForum(forumId){
        leaveForum(forumId, history);
        history.replace('/main/forums');
    }

    return (
        <React.Fragment>
            <div id="LeaveForum" className="layout vertical">
                <AuthenticatedUserToolBar 
                    theme="#e65d57"
                    isLogin={true}
                    onBack={() => history.goBack()}
                    title="Leave a forum"
                    noActions
                />

                <div id="content">
                    { forums && forums.length > 0 &&
                        <div className="d-flex justify-between flex-wrap">
                            {
                                forums.map(forum => (
                                    <Forum key={forum._id} 
                                        forum={forum} 
                                        buttonText="Leave Forum"
                                        onButtonClick={() => handleLeaveForumClicked(forum._id)} />
                                ))
                            }
                        </div>
                    }
                </div>

                <Route exact 
                    path={`${url}/confirmLeave/:forumId`}
                    component={({match}) =>
                        <AlertDialog 
                            cancelText="No, Cancel"
                            okText="Yes, Leave"
                            onOkay={() => handleLeaveForum(match.params.forumId)} 
                            onCancel={() => history.goBack()}>
                            <h2>Sure you wanna leave</h2>
                            <p>Leaving a forum means you'll lose access to it's disucussions.</p>
                        </AlertDialog>
                    }
                />
            </div>
        </React.Fragment>
    );
}
 
export default LeaveForum;

LeaveForum.propTypes = {
    forums: PropTypes.array,
    history: PropTypes.object,
    match: PropTypes.object,
    leaveForum: PropTypes.func
};