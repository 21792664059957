export const SET_LAB_ACTIVE_TAB = 'SET_LAB_ACTIVE_TAB';
export const SET_LAB_CONTENT_TYPE = 'SET_LAB_CONTENT_TYPE';
export const SET_LAB_SELECTED_ITEM = 'SET_LAB_SELECTED_ITEM';

export const GET_ARTICLES_START = 'GET_ARTICLES_START';
export const GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS';
export const GET_ARTICLES_ERROR = 'GET_ARTICLES_ERROR';

export const GET_LAB_SUMMARY_START = 'GET_LAB_SUMMARY_START';
export const GET_LAB_SUMMARY_SUCCESS = 'GET_LAB_SUMMARY_SUCCESS';
export const GET_LAB_SUMMARY_ERROR = 'GET_LAB_SUMMARY_ERROR';

export const GET_LAB_CONTENT_START = 'GET_LAB_CONTENT_START';
export const GET_LAB_CONTENT_SUCCESS = 'GET_LAB_CONTENT_SUCCESS';
export const GET_LAB_CONTENT_ERROR = 'GET_LAB_CONTENT_ERROR';