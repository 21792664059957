import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { searchLearningLabContents } from "../../../../../../../../API";
import ashuraSearchStart from "../../../../../../../../images/ashura-search-start.png";

import { AuthenticatedUserToolBar } from "../../../../../../../components/ToolBar";
import CustomInput from "../../../../../../../components/Input";
import Loader from '../../../../../../../components/Loader';

import SearchResults from './SearchResults';
import NoSearchResults from './NoSearchResults';
import './styles.css';

function linearColors(categories){
    return categories.map(({color}, index) => (
        <stop key={index} offset={`${(index + 0.3) * 100 / categories.length - 1}%`} stopColor={color} />
    ))
}

function categoryOptions(categories){
    if(!categories)
        return [];

    return [
        {
            icon: <svg width="36px" viewBox="0 0 24 24">
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                    { linearColors(categories) }
                </linearGradient>
                <circle fill="url(#linearColors)" cx="12" cy="12" r="12"/>
            </svg>,
            label: "All",
        },
        ...categories.map(category => {
            return {
                icon: <svg fill={category.color} width="36px" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12"/></svg>,
                label: category.name,
            }
        })
    ];
}

function LearningLabSearch(props){
    const { match, history, location, authUser, authToken } = props;
    const [ searchResults, setSearchResults ] = useState(null);
    const [ noSearchResults, setNoSearchResults ] = useState(false);
    const [ searchQuery, setSearchQuery ] = useState("");
    const [ searchParams, setSearchParams ] = useState("");
    const [ category, setCategory ] = useState("All");
    const [ type, setType ] = useState("All");
    const { url } = match;
    const categoryChoices = categoryOptions(authUser.learningLabs);
    const currentCategoryChoice = categoryChoices.find(({label}) => label === category) || {};

    function handleSearchSubmitted(e){
        e.preventDefault();
        pushQueryParam({q: searchQuery}, true);
    }
    
    function pushQueryParam(param, replace){
        const stringified = queryString.stringify({...searchParams, ...param});
        const pushOrReplace = replace ? history.replace : history.push;
        pushOrReplace({
            pathname: '/main/learningLab/search',
            search: `?${stringified}`
        });
    }

    useEffect(() => {
        const searchParams = queryString.parse(location.search);

        if(searchParams){
            if(searchParams.q && searchParams.q.length){
                // console.log("Location effect: ", searchParams, searchQuery);
                // if(searchQuery && searchQuery !== searchParams.q)
                doSearch(searchParams.q);

                setSearchQuery(searchParams.q);
            }
            else
                document.querySelector("#LearningLabSearch input").focus();

            setSearchParams(searchParams);
        }
        else
            document.querySelector("#LearningLabSearch input").focus();
    }, [location.search]);

    useEffect(() => {
        console.log("Initial effect: ");
        const searchParams = queryString.parse(location.search);
    
        if(searchParams){
            const searchQuerySet = searchParams && searchParams.q && searchParams.q.length;

            if(searchQuerySet)
                doSearch(searchParams.q);
        }
    }, []);

    async function doSearch(query){
        setSearchResults(null);
        setNoSearchResults(false);
        const { message, data } = await searchLearningLabContents(authToken, query);
        setNoSearchResults(message !== "Success");
        
        setSearchResults(data);
    }

    const searchQuerySet = searchParams && searchParams.q && searchParams.q.length;

    return (
        <div id="LearningLabSearch" className="position-fixed pin-all z10 d-flex flex-col">
            <div id="LearningLabSearchToolbarWrapper" className="position-relative">
                <AuthenticatedUserToolBar
                    isAuthenticated={true} 
                    isLogin={true}
                    title=" "
                    noActions
                    onBack={() => history.goBack()}>

                    <form action="#" onSubmit={handleSearchSubmitted}>
                        <CustomInput
                            type="search"
                            placeholder="Search"
                            value={searchQuery}
                            onCustomInputChange={setSearchQuery}
                            clearable
                        />
                    </form>    
                </AuthenticatedUserToolBar>

                { !noSearchResults && searchQuerySet && searchResults && searchResults.length > 0 && (
                    <div id="searchFilters" className="d-flex items-center justify-center">
                        <div className="d-flex items-center">
                            Category:
                            <button className="search-filter d-flex items-center"
                                onClick={() => history.push({pathname: `${url}/pickCategory`, search: location.search})}>
                                { currentCategoryChoice.icon }
                                <span>{ category }</span>
                                <svg className="chevron" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="6 9 12 15 18 9"/></svg>
                            </button>
                        </div>
                        
                        <div className="d-flex items-center">
                            Type:
                            <button className="search-filter d-flex items-center"
                                onClick={() => history.push({pathname: `${url}/pickType`, search: location.search})}>
                                <span>{type}</span>
                                <svg className="chevron" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="6 9 12 15 18 9"/></svg>
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div id="LearningLabSearchContent" className="flex LearningLabList">
                { !searchQuerySet && (
                    <div id="searchPromptMessage" className="text-center">
                        <h1 className="brand-color-pinkish-text">Karibu Mrembo</h1>
                        <p>
                            what would you like to know?
                        </p>

                        <img src={ashuraSearchStart} alt=""/>
                    </div>
                )}

                { searchQuerySet && !searchResults && (
                    <div className="d-flex center-center">
                        <Loader />
                    </div>
                ) }

                { searchResults && searchQuerySet && (
                    <React.Fragment>
                        { !noSearchResults && (
                                <SearchResults 
                                    { ...props }
                                    type={type}
                                    data={searchResults}
                                    categoryChoices={categoryChoices}
                                    setType={setType}
                                    setCategory={setCategory}
                                    pushQueryParam={pushQueryParam}
                                />
                            )
                        }
                        
                        { noSearchResults && (
                                <NoSearchResults 
                                    { ...props }
                                    type={type}
                                    data={searchResults}
                                    setType={setType}
                                />
                            )
                        }
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}

export default LearningLabSearch;

LearningLabSearch.propTypes = {
    authUser: PropTypes.object,
    authToken: PropTypes.string
};