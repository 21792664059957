import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import Login from "../Login";
import Onboarding from "../Onboarding";
import MainApp from "../MainApp";

import AlertDialog from "../components/FlNotifications/AlertDialog";

import StaticOpportunityDetail from "../MainApp/Screens/Opportunities/components/OpportunityDetail/StaticOpportunityDetail";
import OpportunityRejected from "../MainApp/Screens/Opportunities/components/OpportunityRejected";
import PlayYoutubeVideo from "../MainApp/Screens/PlayYoutubeVideo";
import Notifications from "../MainApp/Screens/Notifications";
import Profile from "../MainApp/Screens/Profile";
import ReadArticle from "../MainApp/Screens/LearningLab/Screens/Main/Screens/ReadArticle";
import OpenTawk from "../MainApp/Screens/OpenTawk";
import Goodbye from "../components/Goodbye";

import SecureRoute from "./componets/SecureRoute";

class Routes extends Component {
  previousLocation = this.props.location;

  componentWillUpdate(nextProps) {
    let { location } = this.props;
    const shouldUpdatePreviousLocation =
      nextProps.history.action !== "POP" &&
      (!location.state || !location.state.modal);

    if (shouldUpdatePreviousLocation) {
      this.previousLocation = this.props.location;
    }
  }

  render() {
    const { location } = this.props;
    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    ); // not initial render

    return (
      <React.Fragment>
        <Switch location={isModal ? this.previousLocation : location}>
          <Route exact path="/login" component={Login} />
          <Route path="/onboarding" component={Onboarding} />
          <SecureRoute path="/readArticle" component={ReadArticle} />
          <SecureRoute path="/main" component={MainApp} />
          <SecureRoute path="/goodbye" component={Goodbye} />

          <Redirect to="/main" />
        </Switch>

        {isModal && (
          <Switch>
            <Route path="/profile" component={Profile} />
            <Route path="/notifications" component={Notifications} />
            <Route
              path="/opportunityDetail"
              component={StaticOpportunityDetail}
            />
            
            <Route
              path="/opportunityRejected"
              component={OpportunityRejected}
            />
            <Route
              path="/playYoutubeVideo/:videoId"
              component={PlayYoutubeVideo}
            />
            <Route path="/alertDialog" component={AlertDialog} />
            <Route path="/readArticle" component={ReadArticle} />
            <Route path="/openTawk" component={OpenTawk} />
          </Switch>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Routes);
