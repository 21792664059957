import { connect } from 'react-redux';
import Notifications from './Notifications';
import {appOperations, appSelectors} from "../../../duck";

const mapDispatchToProps = {
    getNotifications: appOperations.getNotifications,
    setFilter: appOperations.setNotificationsFilter,
    markNotififactionAsRead: appOperations.markNotififactionAsRead,
    markAllNotificationsAsRead: appOperations.markAllNotificationsAsRead,
};

const mapStateToProps = state => ({
    notifications: appSelectors.notifications(state),
    latestNotifications: appSelectors.latestNotifications(state),
    loading: appSelectors.notificationsLoading(state),
    currentFilter: appSelectors.notificationsFilter(state),
    unreadCount: appSelectors.unreadNotificationsCount(state),
    isAuthenticated: state.isAuthenticated,
    activeInterest: state.interests && state.interests.active,
    isLogin: state.isLogin
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);