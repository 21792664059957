import React from "react";
import Logo from "../../../../../images/riseup-logo.png";
import petal from "../../../../../images/beautifulyourpetal.png";
import './styles.css';

function NonAuthenticatedUserToolBar() {
    return (
        <div className="NonAuthenticatedUserToolBar">
            <div className="logo">
                <img src={Logo} alt="RiseUp" width={124}/>
            </div>
            <div className="menu-icon">
                <img src={petal} alt="RiseUp" width={35}/>
            </div>
        </div>
    );
}


export default NonAuthenticatedUserToolBar