import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ViewProfile from './Screens/ViewProfile';
import ProfileMenu from './ProfileMenu';
import EditInterests from './Screens/EditInterests';
import EditBasicDetails from './Screens/EditBasicDetails';
import EditDemographics from './Screens/EditDemographics';
import EditDream from './Screens/EditDream';
import EditLearningLabs from './Screens/EditLearningLabs';

const Profile = ({match: { url }, location}) => {
    const showMenu = location && location.state && location.state.modal;
    
    if(showMenu)
        return <ProfileMenu />;

    return (
        <Switch>
            <Route path={`${url}/view`} component={ViewProfile} />

            <Route exact path={`${url}/edit/interests`} component={EditInterests} />
            <Route exact path={`${url}/edit/basicDetails`} component={EditBasicDetails} />
            <Route exact path={`${url}/edit/demographics`} component={EditDemographics} />
            <Route exact path={`${url}/edit/dream`} component={EditDream} />
            <Route exact path={`${url}/edit/labs`} component={EditLearningLabs} />

            <Redirect to={`${url}/view`} />
        </Switch>
    );
}
 
export default Profile;