import React, { useEffect } from "react";

import ashuraFull from "../../../../../../../../images/ashura-search-empty.png";

import Loader from "../../../../../../../components/Loader";
import LearningLabSummarySection from "./LearningLabSummarySection";

import "./styles.css";

function LearningLabSummary(props) {
  const {
    activeTab,
    history,
    fetchLearningLabSummary,
    authUser,
    learningLabSummary,
    learningLabSummaryLoading,
  } = props;
  const onTrendingPage = activeTab && activeTab._id === "trending";

  useEffect(() => {
    console.log("Summary: ", learningLabSummary);

    if (activeTab && activeTab._id) fetchLearningLabSummary(activeTab._id);
  }, [activeTab]);

  let bossUp =
    learningLabSummary &&
    (learningLabSummary.video || learningLabSummary.videos)
      ? learningLabSummary.video || learningLabSummary.videos
      : [];
  let readToLead =
    learningLabSummary &&
    (learningLabSummary.article || learningLabSummary.articles)
      ? learningLabSummary.article || learningLabSummary.articles
      : [];
  let coolImagesMeme =
    learningLabSummary &&
    (learningLabSummary.imageMeme || learningLabSummary.imageMemes)
      ? learningLabSummary.imageMeme || learningLabSummary.imageMemes
      : [];

  const noBossUpItems = !bossUp || !bossUp.length;
  const noArticleItems = !readToLead || !readToLead.length;
  const noMemeItems = !coolImagesMeme || !coolImagesMeme.length;

  if (learningLabSummaryLoading)
    return (
      <div className="d-flex center-center">
        <Loader color={activeTab.color} />
      </div>
    );
  else if (noBossUpItems && noArticleItems && noMemeItems) {
    let errorMessage = "No trending learning lab content found.";

    if (activeTab._id === "trending")
      errorMessage = `No learning lab content found for ${activeTab.name}`;

    // else
    //     return (
    //         <p className="text-center">
    //         </p>
    //     );

    return (
      <div id="noLearningLabsIllustration">
        <div className="d-flex">
          <div id="illustration">
            <img src={ashuraFull} alt="" />
          </div>

          <div id="message">
            <h1>Whoops, nothing here.</h1>
            <h2>Come back soooon.</h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="LearningLabSummary">
      {!noBossUpItems && (
        <LearningLabSummarySection
          history={history}
          title="Boss Up"
          items={bossUp}
          onTrendingPage={onTrendingPage}
          moreItemsAvailable
          showSearchButton
        />
      )}

      {!noArticleItems && (
        <LearningLabSummarySection
          history={history}
          title="Read to Lead"
          items={readToLead}
          onTrendingPage={onTrendingPage}
          moreItemsAvailable
          showSearchButton={noBossUpItems}
        />
      )}

      {!noMemeItems && (
        <LearningLabSummarySection
          history={history}
          title="Cool Images & Memes"
          items={coolImagesMeme}
          onTrendingPage={onTrendingPage}
          moreItemsAvailable
          showSearchButton={noBossUpItems && noArticleItems}
        />
      )}
    </div>
  );
}

export default LearningLabSummary;
