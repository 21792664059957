import React from "react";
import PropTypes from 'prop-types';
import './styles.css';

function TabButton (props) {
    const {id, activeTab } = props;
    const active = id === activeTab ? 'active' : '';
    const borderColor = id === activeTab ? '#ffffff' : 'transparent';
    return (
        <div className="TabButton z10">
            <button
                style={{
                    backgroundColor: 'transparent',
                    borderBottom: `4px solid ${borderColor}`
                }}
                className={active}
                onClick={(e) => props.handleOnClick(e, id)}
            >
                {props.value}
            </button>
        </div>
    );
}

export default TabButton;

TabButton.propTypes = {
    id: PropTypes.string,
    activeTab: PropTypes.string,
    theme: PropTypes.string
};